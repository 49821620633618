
            
          
          <app-profile-navigation></app-profile-navigation>
           <div class="card bg-dark text-light mb-3 mt-2">
            <div class="card-header"><h6>Location</h6></div>
            <div class="card-body ">
              <!-- <agm-map [latitude]='lat' [longitude]='long' [mapTypeId]='googleMapType' style="height: 500px; width:100%">
              </agm-map> -->

              <!-- google map coding start here -->
              <div class="form-group">
                <label>Location</label>
                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
              </div>
              
              <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="height: 250px; width:100%">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="false"
                ></agm-marker>
              </agm-map>
              
              <h5 class="mt-2">Address</h5>
              <p class="h5">{{address}}</p>

             <!-- google map coding end here -->
<!-- 
                <p>Please enter your real city name or postal code so the distance calculation will work.</p> -->
             <div class="row">
               <div class="col-md-12">
                    <form [formGroup]="userLocation" (ngSubmit)="onSubmit()"> 
                    <input type="text" formControlName="userID" class="form-control d-none" readonly />

 
       <div *ngIf="ifSuccess" class="alert alert-success" role="alert"><i class="fas fa-check-circle"></i>  {{message}}</div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                <button  [disabled]="loading" class="btn btn-warning"><div 
        *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
         <span class="visually-hidden"></span>
       </div> Update</button> 
             </form> 
               </div>
             </div>
            </div>
          </div>
      

