<div class="row">
    <div class="col-sm-10">
        <div class="swinger-comment">
        <img src="https://cdn.pixabay.com/photo/2021/03/09/14/33/dog-6082017_960_720.jpg" class="img-fluid rounded-circle comment-user-profile">
        <h6><a href="javascript:void(0);"  class="text-light">Rohit Kumar</a></h6>
        <p class="text-secondery comment-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.</p>
        <div class="d-flex justify-content-around">
            <a href="javascript:void(0);" class="text-light">Like <span class="badge bg-light text-dark">38</span></a>
            <a href="javascript:void(0);" class="text-light">Reply</a>
        </div>
    </div>
</div>
 </div>
