import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader} from '@agm/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/_services';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})


export class LocationComponent implements OnInit {

  title: string = 'AGM project';
  latitude = 28.704060;;
  longitude = 77.102493;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(private fb: FormBuilder,
     private userService: UserService,
     private mapsAPILoader: MapsAPILoader,
     private ngZone: NgZone) { }

  userLocation: FormGroup;
  submitted = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = false;
  ifSuccess = false;
  message: string;
  error = '';

  // lat = 28.704060;
  // long = 77.102493;
  // googleMapType = 'satellite';
 

  ngOnInit(): void {
    this.getuserLocation();
    this.userLocation = this.fb.group({
      userID: [this.userId],
      zipCode: [''],
      latitude: [''],
      longitude: [''],
    });

    // google map code start here
    this.mapsAPILoader.load().then(() => {
      this.getuserLocation();
      this.geoCoder = new google.maps.Geocoder;
  
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
  
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.userLocation.controls.latitude.setValue(this.latitude);
          this.userLocation.controls.longitude.setValue(this.longitude);
          this.getAddress(this.latitude, this.longitude);
          this.zoom = 12;
        });
      });
    });
  }


  private setCurrentLocation(lat?:any,long?:any) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(lat && long){
          this.latitude = lat;
          this.longitude = long;
          this.userLocation.controls.latitude.setValue(lat);
          this.userLocation.controls.longitude.setValue(long);
          this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
        }else{
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.userLocation.controls.latitude.setValue(this.latitude);
          this.userLocation.controls.longitude.setValue(this.longitude);
          this.zoom = 8;
         this.getAddress(this.latitude, this.longitude);
        }
        
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const postal_code =  results[0].address_components.find(x =>{
            if(x.types[0] == "postal_code"){
              return x;
            }
            });
           this.userLocation.controls.zipCode.setValue(postal_code?.long_name);
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
  
    });
  }




  get f() { return this.userLocation.controls; }

  onSubmit(){
   this.submitted = true;
   if (this.userLocation.invalid) {
    return;
   }
   let body = {
     location: this.address,
     zipCode: this.userLocation.value.zipCode,
     latitude: this.userLocation.value.latitude,
     longitude: this.userLocation.value.longitude,
   }
   console.log(body)
   this.loading = true;
   this.userService.updateuserLocation(body).subscribe( res => {
    //  console.log(res)
    this.submitted = false;
    this.loading = false;
    this.ifSuccess = true;
    this.message = res.message;
    this.getuserLocation();
   }, error =>{
    this.error = error;
    this.loading = false;
   });
  }

  getuserLocation(){
    this.submitted = true;
    this.userService.getuser().subscribe( res => {
      if(res.latitude && res.longitude){
        this.getAddress(Number(res.latitude), Number(res.longitude));
        this.setCurrentLocation(Number(res.latitude), Number(res.longitude));
        console.log(res.latitude, res.longitude);
      }else{
        this.setCurrentLocation();
      }
      
      this.userLocation.controls.zipCode.setValue(res.zipcode);
    }, error => {
      console.log(error);
    });
   }

}
