import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor(
    private Title:Title
  ) {
    this.Title.setTitle('Privacy & Policy | Swingerflings')
   }
  heading: string = 'Privacy Policy';
  backgroundImage: any = '/assets/images/login_background.jpg';

  ngOnInit(): void {
  }

}
