import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { GroupServiceService } from "@app/_services";


@Component({
  selector: 'app-group-navigation',
  templateUrl: './group-navigation.component.html',
  styleUrls: ['./group-navigation.component.css']
})
export class GroupNavigationComponent implements OnInit {

  
  defaultProfile: string = environment.defaultProfile;
  loading = true;
  groupBackground = '../assets/images/user-group.jpg';
  groupInvitations: any;

  constructor(private groupService: GroupServiceService) { }
  
  userID = JSON.parse(sessionStorage.getItem("currentUser")).id;

  ngOnInit(): void {
    this.getAllInvitations();
  }


  getAllInvitations(){
    this.groupService.getGroupInvitation().subscribe(res => {
      console.log(res,'hi join invitations');
      this.loading = false;
    });
  }

}
