import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { HotdatesComponent } from '@app/hot-dates/hotdates/hotdates.component';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from "../../_services/messages.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-right-navigation-panel',
  templateUrl: './right-navigation-panel.component.html',
  styleUrls: ['./right-navigation-panel.component.css']
})
export class RightNavigationPanelComponent implements OnInit {
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;

  message: FormGroup;
  loadings = false;
  UseName:any;

  defaultProfile = environment.defaultProfile;
  loading = true;
  constructor(
    private friends: FriendsService, 
    private router:Router,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private MessagesService:MessagesService,) { }
  friendsData:any[] =[];
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  @ViewChild(HotdatesComponent) hotdates: HotdatesComponent;

  ngOnInit(): void {
    this.getOnlineFriends();
    this.message = this.formBuilder.group({
      messages: ['', Validators.required],
    });
  }
  dataNotFound  = false;
  getOnlineFriends(){
    this.friends.getOnlineFriendslist().subscribe(data =>{
      this.loading = false;
      this.friendsData = data.data.data;
      if(this.friendsData.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    }, error => {
      this.loading = false;
    });
  }
  gotoOnline(){
    this.router.navigate(['/dashboard/user-online'])
  }

  today: number = Date.now();
  toggled: boolean = false;
  messagess: string = '';
  
  handleSelection(event) { 
    this.messagess += event.char;
    // console.log(this.messagess)
  }
     // convenience getter for easy access to form fields
     get f() { return this.message.controls; }
     senderIDs:any;
     MessagesRes:any;
     onSubmit() {
       if (this.message.invalid) {
         return;
       }
       this.loadings = true;
       let body = {
         to_user_id:  this.senderIDs,
         message:this.message.value.messages,
         dateTime:this.today
       }
       this.loadings = true;
       this.MessagesService.sendmessage(body).subscribe(res=>{
         if(res != null){
           this.loadings = false;
           this.MessagesRes = res.message
           this.message.reset();
           this.closeHotdateModal();
           this.sentMessages(this.senderIDs)
         }
       }, error => {
         this.loadings = false;
     });
     }

     

     openAddNewHotdateModal(id, name) {
      this.UseName = name;
      this.senderIDs = id
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size: 'lg'
  };
      this.modal.open(this.SentMessageModalContent,ngbModalOptions);
    }
  
  
  
    closeHotdateModal() {
      this.modal.dismissAll(this.SentMessageModalContent);
    }
  
    sentMessages(id){
     this.router.navigate(['/dashboard/message'], {queryParams:{uid:id}})
    }
}
