<div class="right_navigation_panel">
  <div class="card bg-dark text-light mb-3">
    <div class="card-header">
      <h4 class="fw-bold">Swingers Online</h4>
    </div>
    <div class="card-body over_flow_wrapper" *ngIf="!freeuser">
      <div class="card cardss mb-2 bg-dark" *ngFor="let friend of friendsData | slice:0:5; let i=index">
        <div class="row g-0" *ngIf='friend.user_status == null'>
          <div class="col-3 col-md-1 pr-1">

            <div class="user_profile_ls active">
              <img [src]="friend.profile" routerLink="/{{friend?.id}}/swinger/profile" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                alt="user-profile" class="img-fluid">
            </div>
            <i class="fas fa-circle cutom"></i>
          </div>
          <div class="col-6 col-md-8 pl-1">
            <div class="card-body">
              <h5 class="card-title"><a routerLink="/{{friend?.id}}/swinger/profile">{{friend.name}}</a></h5>
              <!-- <p class="card-text text-muted" style="margin-bottom: 2px;">{{friend.short_tag_line}}</p> -->
              <span
                [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''"
                class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
            </div>
          </div>

          <div class="col-3 col-3 pl-1">
            <div class="card-body text-right">
              <a  class="btn btn-warning" style="margin-top: 15px;" data-toggle="tooltip" data-placement="top" title="Send Message" (click)="openAddNewHotdateModal(friend.id, friend.name)"><i class="fa fa-comments"></i></a>
            </div>
          </div>
        </div>
      </div>
      <p *ngIf="dataNotFound  " class="text-center">There is no swinger/friend online.</p>
      <div class="card-text text-center" *ngIf="loading">
        <div class="spinner-border text-light" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </div>

    <div class="row mt-12" *ngIf="!freeuser && pagelarg">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="mt-4" *ngIf="freeuser">
      <app-subscribe-plan></app-subscribe-plan>
    </div>
  </div>
</div>


<ng-template #SentMessageModalContent  let-close="close">
  <div class="modal-header bg-light" >
    <h5 class="modal-title">Sent Message</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light" >
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="message">
          <div class="form-group">
            <input class="form-control" [value]="UseName" placeholder="name">
          </div>

          <div class="form-group">
            <textarea class="form-control" [(ngModel)]="messagess" class="form-control" formControlName="messagess" placeholder="message...." id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="text-left">
            <!-- <buton class="btn btn-warning" >
              <span class="input-group-text">
              <i (click)="toggled = !toggled" [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                (emojiPickerSelect)="handleSelection($event)" class="fa fa-smile-o" aria-hidden="true"
                ></i>
            </span>
          </buton> -->
            <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary" (click)="onSubmit()"> 
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
              <i class="fas fa-paper-plane"></i>Submit
            </button>
            <span style="float: right;color: green;">{{MessagesRes}}</span>
          </div>
        </form>
  </div>
</div>
  </div>
</ng-template>