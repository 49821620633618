<div *ngIf='!uiHide'>
  <div class="row g-0">
    <div class="col-4 col-md-2 pr-1">
      <div class="user_profile_ls"><img routerLink="/{{friend.id}}/swinger/profile" [src]="friend.is_pic"
          onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
          class="img-fluid user-profile"></div>
    </div>
    <div class="col-8 col-md-10 pl-1">
      <div class="row">
        <div class="col-md-7 col-12">
          <div class="card-body">
            <h5 class="card-title" style="margin-bottom: 2px;"><a routerLink="/{{friend.id}}/swinger/profile"
                (click)="setProfileViews(friend?.id)">{{friend.name}}</a></h5>
            <span *ngIf="friend?.gender != null"
              [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''"
              class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
            <!-- <p class="card-text text-muted">{{friend?.short_tag_line}}</p> -->
          </div>
        </div>
        <div class="col-md-5 col-12 m-flex-v">
          <div class="btn-group mt-2 mb-2 padin">
            <button *ngIf="friend.is_faverate==0" type="button" class="btn btn-info "
              (click)="addToFavourite(friend.id)" data-toggle="tooltip" data-placement="top" title="Faverate">
              <div *ngIf="favouriteloading" class="spinner-border text-light spinner-border-sm" role="status">
                <span class="visually-hidden"></span>
              </div> <i *ngIf="!favouriteloading" class="fa fa-heart" aria-hidden="true"></i>
            </button>
            <button type="button" data-toggle="tooltip" data-placement="top" title="Message" class="btn btn-warning"
              (click)="openAddNewHotdateModal(friend.id, friend.name)"><i class="fas fa-comments"></i></button>

              <button type="button" data-toggle="tooltip" data-placement="top" title="Unfriend" class="btn btn-success"
              (click)="UnFriends(friend.id)">
              <div *ngIf="unfriendloading" class="spinner-border text-light spinner-border-sm" role="status">
                <span class="visually-hidden"></span>
              </div> <i *ngIf="!unfriendloading" class="fas fa-user-slash" style="font-size: 14px;"></i>
            </button>
            <button type="button" data-toggle="tooltip" data-placement="top" title="Block" class="btn btn-danger"
              (click)="blockFriend(friend.id)">
              <div *ngIf="blockloading" class="spinner-border text-light spinner-border-sm" role="status">
                <span class="visually-hidden"></span>
              </div> <i *ngIf="!blockloading" class="fas fa-ban"></i>
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>



<ng-template #SentMessageModalContent let-close="close">
  <div class="modal-header bg-light">
    <h5 class="modal-title">Sent Message</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="message">
          <div class="form-group">
            <input class="form-control" [value]="UseName" placeholder="name">
          </div>

          <div class="form-group">
            <textarea class="form-control" [(ngModel)]="messagess" class="form-control" formControlName="messagess"
              placeholder="message...." id="exampleFormControlTextarea1" rows="3"></textarea>
          </div>
          <div class="text-left">
    <!-- <buton class="btn btn-warning" >
              <span class="input-group-text">
              <i (click)="toggled = !toggled" [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                (emojiPickerSelect)="handleSelection($event)" class="fa fa-smile-o" aria-hidden="true"
                ></i>
            </span>
          </buton> -->
            <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary"
              (click)="onSubmit()">
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
              <i class="fas fa-paper-plane"></i>Submit
            </button>
            <span style="float: right;color: green;">{{MessagesRes}}</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>