import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthenticationService, UserMediaService } from '@app/_services';
import { User } from '@app/_models';
import { NotificationsServices } from "../../_services/notifications.service";
import { SharedService } from '../../_services/shared.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NotificationsService } from "../../../app/notifications.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Json } from 'aws-sdk/clients/robomaker';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
    clickEventsubscription: Subscription;
    defaultProfile: any;
    siteLogoUrl = environment.siteLogolight;
    currentUser: User;
    username: any;
    isLogin: boolean;
    checkNav = 0;
    checkDiviceWidth = window.innerWidth;


    @ViewChild('mobilenavWr') elRef: ElementRef;
    userId: any;
    iconISshow = true;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private renderer: Renderer2,
        private NotificationsServices: NotificationsServices,
        private sharedService: SharedService,
        private windownotifiserveice: NotificationsService,
 
    ) {
        this.authenticationService.currentUser.subscribe(x => {
            this.currentUser = x;
            if (this.currentUser != null) {
                this.username = this.currentUser.name;

                this.isLogin = true;
                this.userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
            } else {
                this.isLogin = false;
            }
            if (x.email_status != 1) {
                this.iconISshow = false;
            } else {
                this.iconISshow = true;
            }

        });

        this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
            this.logout();
        })
    }

    ngOnInit(): void { }

    logout() {
        this.authenticationService.logout().subscribe(res => {
            this.router.navigate(['/login']);
            setTimeout(function () {
                this.router.onSameUrlNavigation = 'reload';
            }, 50);
        });
    }

    playToy() {
        this.router.navigate(["/toys"]).then(result => { window.location.href = 'https://www.playfulobsessions.com/'; });
    }

    mobileNav(event: any) {
        if (this.checkNav == 0) {
            this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
            this.renderer.addClass(this.elRef.nativeElement, "nav-show");
            this.checkNav = 1;
        } else {
            this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
            this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
            this.checkNav = 0;
        }
    }

}
