import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/_services';
import { environment, Popup } from '@environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SwingersService } from "../_services/swingers.service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  userID = JSON.parse(sessionStorage.getItem('currentUser'))?.id;
  contactForm: FormGroup;
  bannerImage = './assets/images/young-passionate.jpg';
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private SwingersService:SwingersService,
    private Title:Title
     ) {
       this.Title.setTitle('Conatct | Swingerflings')
      }

  selectedPlanDetails: any;

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      email: ['', Validators.required],
  });
  }

  get f() { return this.contactForm.controls; }

  contactUs() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
  }
  this.loading = true;
    let body = JSON.stringify(this.contactForm.value)
    this.SwingersService.getintouch(body).subscribe((res:any)=>{
      this.loading = false;
      this.contactForm.reset();
      Swal.fire("Success", `${res.message}`, "success");
    },(error) => {
      Swal.fire(
        "Server Error",
        `${Popup.error}`,
        "error"
      );
    })
    return false;


}


}
