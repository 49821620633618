
    <section class="diffirence-wrapper">
        <div class="container">
            <div class="row ">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="text-left mt_2 mb_2">
                        <h2><span class="text-color1 mr-2">What makes</span> <span class="text_color2">Swingerflings different?.</span></h2>
                    </div>
                    <div>
                        <p>
                            This is more than just your average swinging lifestyle site. 
                            Swingerfling offers a ton of great features designed to help 
                            you really get to know sexy singles and couples before you decide to meet up in person.
                        </p>
                    </div>
                    <div>
                        <ul>
                            <li>Browse detailed member profiles</li>
                            <li>Find swingers in your area</li>
                            <li>Enjoy sex chat via Instant Messenger</li>
                            <li>View thousands of nude member photos and videos</li>
                            <li>Be a part of an international, sex-positive community</li>
                        </ul>
                    </div>
                    <div class="text-center mtb_15">
                        <button class="btn btn-warning" (click)="gotodiscoverpages()"> BECOME A MEMBER </button>
                    </div>

                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="imgcontgroup">
                        <img src="../../../assets/images/1111" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="Card image cap">
                    </div>
                </div>
            </div>
       
        </div>
    </section>



<!-- How it works -->
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center mt_2 mb_2">
                    <h2 class="text_color1">How it Works</h2>
                    <p class="text_color2">Swingers Flings turning faantasies into reality!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="card text-center bg_colors card-with">
                    <div class="imgcontainer">
                        <img class="card-img-top" src="../../../assets/images/2133"
                            onerror="this.src='../../../assets/images/avtar-image.jpg';"
                            alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <p class="textName">CREATE A PROFILE</p>
                        <p class="textGender">Set up your free profile in less than five minutes!</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card text-center bg_colors card-with">
                    <div class="imgcontainer">
                        <img class="card-img-top" src="../../../assets/images/young-passionate.jpg"
                            onerror="this.src='../../../assets/images/avtar-image.jpg';"
                            alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <p class="textName">BROWSE MEMBERS</p>
                        <p class="textGender">Use our advance search tools to find people with the same sexual preferences</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card text-center bg_colors card-with">
                    <div class="imgcontainer">
                        <img class="card-img-top" src="../../../assets/images/4.jpeg"
                            onerror="this.src='../../../assets/images/avtar-image.jpg';"
                            alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <p class="textName">GET IN TOUCH</p>
                        <p class="textGender">Make the first move. If a member catches your eye, send them a message or keep it subtle and send them message or keep it
                            subtle and send a first,.Either way, you could meet them as soon as tonight
                        </p>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</section>