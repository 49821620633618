import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})

export class HotDateService {
  constructor(private http: HttpClient ) { }

  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

  addHotDates(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/add-hot-dates`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updateHotDates(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/update-hot-dates`,data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

 getUserHotDates() {
    return this.http.get<any>(`${environment.apiUrl}/get-hot-dates`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getHotDateById(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/get-hot-date/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteHotDate(id: any) {
    return this.http.post<any>(`${environment.apiUrl}/delete-hot-date/${id}`,'', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  searchHotDate(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/search-hot-dates`, data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSwingerHotDates() {
    return this.http.get<any>(`${environment.apiUrl}/get-all-hot-dates`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getSwingerHotDateById(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/get-swinger-hot-date/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  searchSwingerHotDate(data) {
    return this.http.post<any>(`${environment.apiUrl}/search-hot-dates`, data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getTotalActiveHotDate() {
    return this.http.get<any>(`${environment.apiUrl}/total-hot-dates`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

}
