<footer class="py-5">
    <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <h5> COMMUNITY</h5>
        <ul class="list-unstyled text-small">
          <li><a class="link-secondary" routerLink="/" routerLinkActive="">Only Women Swap</a></li>
          <li><a class="link-secondary" routerLink="/" routerLinkActive="">Only Men Swap</a></li>
          <li><a class="link-secondary" routerLink="/" routerLinkActive="">Only Couple Swap</a></li>
          
        </ul>
      </div>
      <div class="col-md-6 col-lg-3">
        <h5>INFORMATIONS</h5>
        <ul class="list-unstyled text-small">
          <li><a class="link-secondary" routerLink="/plan" routerLinkActive="">Pricing & Plans</a></li>
          <li><a class="link-secondary" routerLink="/login" routerLinkActive="">Hot Dates</a></li>
          <li><a class="link-secondary" routerLink="/contact" routerLinkActive="">Contact</a></li>

          <li><a class="link-secondary" routerLink="/term-conditoins" routerLinkActive="">Terms & Conditions</a></li>
          <li><a class="link-secondary" routerLink="/privacy-policy" routerLinkActive="">Privacy Policy</a></li>
 
        </ul>
      </div>
      <div class="col-md-6 col-lg-2">
        <h5 >FOLLOW US</h5>
        <ul class="list-unstyled text-small">
          <li><a class="link-secondary" href="https://www.instagram.com/swingerflings1?r=nametag" target="_blank"><i class="fab fa-instagram"></i> Instagram</a></li>
          <li><a class="link-secondary" href="https://www.facebook.com/swing.fling.7" target="_blank"><i class="fab fa-facebook-f"></i> Facebook</a></li>
          <li><a class="link-secondary" href="https://mewe.com/i/swingerflings3" target="_blank"><i class="far fa-comment-alt"></i> MeWe</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-4">
        <h5 class="mb-3">SUBSCRIBE WITH US!</h5>
        <!-- <p>SwingerFlings offers you the opportunity to simply accelerate this process by finding out which is right for you.</p> -->
        <input type="text" placeholder="enter your email" class="form-control subscribe_ft" #mailInput>
        <p class="text-right"><button  class="btn btn-warning cu_btn mr-0" (click)="subscribeMail(mailInput.value)">Subscribe</button></p>
      </div>
    </div>
    
    </div>

  </footer>
  <div class="design-by">
    <p><strong>© 2021 | Swingerflings | All Rights Reserved.</strong></p>
  </div>