import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  blockloading = false;
  uiHide = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  constructor(private friendService: FriendsService, private renderer: Renderer2, private elm: ElementRef){ }
  firends: Friends;
  @Input() friend: any;
  profilePic: any;

  ngOnInit(): void {
    this.profilePic = this.friend.profile;
  }

  removeFavourite(id: any){
    this.blockloading = true;
    const data = {
            "userId": this.userId,
            "friendId": id
    };
    console.log(data,'input data');
    this.friendService.removeFavoriteFriend(data).subscribe( res => {
      console.log(res,'re data');
      this.uiHide = true;
      this.renderer.addClass(this.elm.nativeElement.parentElement,'d-none');
      this.blockloading = false;
    });
  }


}
