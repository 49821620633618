import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comunity-section',
  templateUrl: './comunity-section.component.html'
})
export class ComunitySectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
