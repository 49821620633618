<nav class="navbar navbar-expand-lg navbar-dark bg-dark _nav_fixed">
  <div class="container">
    <a class="navbar-brand" routerLink="/dashboard"><img src="{{siteLogoUrl}}" class="img-fluid"
        id="sitelogo"></a>
    <span class="example-spacer"></span>
    
    <div class="mob-nav-notification nav-item dropdown" *ngIf="isLogin " style="padding: 0px 0px;border-bottom: none;
    ">
    <a class="nav-link" (click)="applyFilter($event)"><i class="fas fa-search icon mobile_search"></i><span
      class="badge bg-success" style="border: none;"></span></a>
    </div>
    <div class="mob-nav-notification nav-item dropdown" *ngIf="isLogin " style="padding: 0px 0px;border-bottom: none;
    ">
    
    <!-- <li class="nav-item dropdown" *ngIf="isLogin && iconISshow">
      <input  size="35" class="text" placeholder="Search" (click)="applyFilter($event)" style="text-align: center;"  >
      <i class="fa fa-search icon"></i>
    </li> -->
        


      <a class="nav-link" (click)="seeAllmessage()" data-toggle="dropdown"><i class="fas fa-envelope"></i><span
          class="badge bg-success" style="border: none;">{{countmessages}}</span></a>
    </div>
    <div class="mob-nav-notification nav-item dropdown" *ngIf="isLogin " style="padding: 0px 0px;border-bottom: none;">
      <a class="nav-link" (click)="seeAllNotifi();" data-toggle="dropdown"><i class="fas fa-bell"></i><span
          class="badge bg-danger" style="border: none;">{{countnotifinum}}</span></a>
          
    </div>

    <button class="navbar-toggler moble-nav" style="padding: 12px;" type="button" (click)='mobileNav($event);'
      id="mobile-navigation">
      <span class="nav-bar-1"></span>
      <span class="nav-bar-2"></span>
      <span class="nav-bar-3"></span>
    </button>
    <div class="collapse navbar-collapse justify-end" >
      <ul class="navbar-nav ms-auto me-0 ">
        <li class="nav-item dropdown" *ngIf="isLogin && iconISshow">
          <input class="btn"  placeholder="Search" [routerLink]="['/dashboard/swingers']" routerLinkActive="router-link-active"  (click)="applyFilter($event)"  size="10">
          <i class="fa fa-search icon" style="color: aliceblue;" disabled></i>
        </li>
      </ul>
    </div>

    <div class="collapse navbar-collapse justify-end" id="right_navbar">
      <ul class="navbar-nav ms-auto me-0 ">
        <!-- message -->
        <li class="nav-item dropdown" *ngIf="isLogin && iconISshow">
          <a class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="true" (click)="seeAllmessage()"><i class="fas fa-envelope"></i><span class="badge bg-success"
              style="border: none;">{{countmessages}}</span></a>

        </li>

        <!-- notefication -->
        <li class="nav-item dropdown" *ngIf="isLogin && iconISshow">
          <a class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="true">
            <i class="fas fa-bell"></i><span class="badge bg-danger" style="border: none;">{{countnotifinum}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg-right dropdown-secondary"
            aria-labelledby="navbarDropdownMenuLink-5">
            <span *ngFor="let notifi of notifiData | slice:0:5; let i=index">
              <a class="dropdown-item waves-effect waves-light dispblock "
                [ngClass]="{'backgcolor' : notifi.read === null}" *ngIf="notifi.userStatus == null">
                <span>
                  <div class="inl">
                    <div class="notimg">
                      <img [src]="notifi.img" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                        class="imgnotif">
                    </div>
                  </div>
                  <div class="inl">
                    <div class="testdata"
                      (click)="gotoNotification(notifi.id, notifi.requestType, notifi.friend_user_id, notifi.OrderId)">
                      <h4 [ngClass]="{'nonread' : notifi.read === null}" class="username mb-0">{{notifi?.sent_by}}</h4>
                      <p [ngClass]="{'nonread' : notifi.read === null}" class="notifisub text-muted">{{notifi?.subject}}
                      </p>
                      <div *ngIf="notifi?.requestType == 'Friend Request sent'">
                        <p *ngIf="notifi.status == 1" class="frindNotFriend">friend</p>
                        <p *ngIf="notifi.status == 2" class="frindNotFriend"> not intrested</p>
                      </div>
                    </div>
                    <div class="testdata" *ngIf="notifi?.requestType == 'Friend Request sent'">
                      <div *ngIf="notifi?.status == 0">
                        <button type="button" class="btn btn-primary buttons"
                          (click)="acceptrequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
                          Accept
                        </button>
                        <button type="button" type="button" class="btn btn-light buttons"
                          (click)="rejectRequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
                          Decline
                        </button>
                      </div>

                    </div>

                  </div>
                </span>
              </a>
            </span>

            <div *ngIf="seemoreshow" class="see_more_wrapper border-top-darken-1 border-top-1" (click)="seeAllNotifi()">
              <a class="text-light pr-3">see all <i class="fas fa-arrow-circle-right"></i></a>
            </div>
            <!-- <button  type="button" class="btn btn-light seemoredata"  style="float: right;"><a >See More</a></button> -->
          </div>
        </li>

        <!-- <li class="nav-item" *ngIf="isLogin">
            <a  class="nav-link" routerLink="/"><i class="far fa-envelope"></i><span class="badge bg-success">5</span></a>
         </li> -->

        <li class="nav-item dropdown" *ngIf="isLogin">
          <a class="nav-link dropdown-toggle profile_image" href="javascript:void(0);" id="user_profile" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src="{{defaultProfile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';" #profileImg
              class="img-fluid logoimag">
          </a>
          <ul class="dropdown-menu" aria-labelledby="user_profile">
            <li><a class="dropdown-item" routerLink="/dashboard/swingers">Dashboard </a></li>
            <li><a class="dropdown-item" routerLink="/dashboard/edit-profile">My Profile</a></li>
            <li><a class="dropdown-item" routerLink="/dashboard/change-password">Change Password</a></li>
            <li><a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>


<!-- start mobile navigation -->

<div class="justify-end navbarSmallDiveces nav-hide" #mobilenavWr id="sidebar-wrapper">
  <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation-close">
    <i class="far fa-times-circle text-light" style="font-size:28px"></i>
  </button>
  <ul class="navbar-nav ms-auto me-0 mt-2 ">
    <li class="nav-item dropdown" *ngIf="isLogin">
      <a class="nav-link profile_image" href="javascript:void(0);" id="user_profile">
        <img src="{{defaultProfile}}" class="img-fluid">
      </a>
      <span class="active-user-name text-light">{{username}}</span><br />
      <span><a class="badge bg-warning text-dark custom_badge">{{PlaneName}}</a></span>
      <span *ngIf="planId == '1'"><a routerLink="/plan" class="badge bg-primary text-dark custom_badge">Upgrade
          Plan</a></span>
    </li>
    

     
    <li class="nav-item " *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/swingers" routerLinkActive="active"
        (click)='mobileNav($event);'>Dashboard</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/user-online" routerLinkActive="active"
        (click)='mobileNav($event);'>Who's Online <i class="fas fa-circle cutom"></i></a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/message" routerLinkActive="active"
        (click)='mobileNav($event);'>Message</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/edit-profile" routerLinkActive="active" (click)='mobileNav($event);'>My
        Profile</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/profile-comments" routerLinkActive="active"
        (click)='mobileNav($event);'>Profile Comment</a>
    </li>
  </ul>

  <ul class="navbar-nav ms-auto me-0 ">

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/swingers" routerLinkActive="active"
        (click)='mobileNav($event);'>Swingers</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/friends" routerLinkActive="active"
        (click)='mobileNav($event);'>Friends</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard/create-album" routerLinkActive="active"
        (click)='mobileNav($event);'>Gallery</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/hot-dates/hot-date-list" routerLinkActive="active"
        (click)='mobileNav($event);'>Hot dates</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" routerLink="/event-dates/event-date-list" routerLinkActive="active"
        (click)='mobileNav($event);'>Events</a>
    </li>



    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/plan-invoce" routerLinkActive="active"
        (click)='mobileNav($event);'>Plan History </a>
    </li>
    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" routerLink="/dashboard/change-password" routerLinkActive="active"
        (click)='mobileNav($event);'>Change Password </a>
    </li>


    <li class="nav-item" *ngIf="isLogin">
      <a class="nav-link" href="javascript:void(0);" (click)="logout()" (click)='mobileNav($event);'>Logout</a>
    </li>

  </ul>
</div>


<ng-template #SentMessageModalContent let-close="close">
  <div class="modal-header bg-light">
    <h5 class="modal-title">Search Swingers</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="lookingForm" (ngSubmit)="lookingMembers()">
                     
          <div class="form-group mb-1">
             
              <div class="form-group">
                  <label for="username">You're</label>
                  <select formControlName="first_gender"  class="form-control"  [ngClass]="{ 'is-invalid': submitted && f.first_gender.errors }">
                      <option selected >I am</option>
                      <option *ngFor="let gender of genders" >{{gender}}</option>
                  </select>
                  <div *ngIf="submitted && f.first_gender.errors" class="invalid-feedback">
                      <div *ngIf="f.first_gender.errors.required">You're is required.</div>
                  </div>
              </div>
          </div>

          <div class="form-group">
              <div id="looking_for" class="form-text">What are you looking for?</div>
              <select formControlName="second_gender"  class="form-control " [ngClass]="{ 'is-invalid': submitted && f.second_gender.errors }" aria-describedby="looking_for">
                  <option selected>Looking for...</option>
                  <option *ngFor="let gender of genders" >{{gender}}</option>
              </select>
              <div *ngIf="submitted && f.second_gender.errors" class="invalid-feedback">
                  <div *ngIf="f.second_gender.errors.required">What are you looking for? is required.</div>
              </div>
              
          </div>

          <div class="row">
            
              <div class="col-md-6">
                  
                  <div class="form-group">
                      <label>Age</label>
                      <select formControlName="distanceFrom" [ngClass]="{ 'is-invalid': submitted && f.distanceFrom.errors }"  class="form-control ">
                          <option  selected>From</option>
                          <option *ngFor="let age of age" >{{age}}</option>
                      </select>
                      <div *ngIf="submitted && f.distanceFrom.errors" class="invalid-feedback">
                          <div *ngIf="f.distanceFrom.errors.required">Age from is required?</div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label></label>
                      <select formControlName="distanceTo"  [ngClass]="{ 'is-invalid': submitted && f.distanceTo.errors }"   class="form-control ">
                          <option  selected>To</option>
                          <option *ngFor="let age of age" >{{age}}</option>
                      </select>
                      <div *ngIf="submitted && f.distanceTo.errors" class="invalid-feedback">
                          <div *ngIf="f.distanceTo.errors.required">Age to is required?</div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="form-group">
              <label for="address">Address</label>
              <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
          </div>
        
          <div class="form-group mt-4">
              <select formControlName="distanceMiles" [ngClass]="{ 'is-invalid': submitted && f.distanceMiles.errors }"  class="form-control" >
                  <option  selected>Distance in miles</option>
                  <option *ngFor="let distance of distances" >{{distance}}</option>
              </select>
              <div *ngIf="submitted && f.distanceMiles.errors" class="invalid-feedback">
                  <div *ngIf="f.distanceMiles.errors.required">Distance in miles is required</div>
              </div>
          </div>

          <button [disabled]="loadings" class="btn btn-danger mt-2">
              <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
             <span *ngIf="!loadings"> Search</span>
             <span *ngIf="loadings"> Please Wait</span>
          </button>
          <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
      </div>
    </div>
  </div>
</ng-template>