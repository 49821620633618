
import { Injectable } from '@angular/core';  
  
@Injectable()  
export class DataService {  
  
  private data = {}; 
  private dat={};
   
  setOption(option, value) { 
  
    this.data[option] = value;  
  } 
  
  setopt(opt,val){
     this.dat[opt]=val;
  }
  
  getOption() {  
    return this.data;  
  }  

  getOpt() {  
    return this.dat; 
  }  
}