<app-friend-navigation></app-friend-navigation>
<div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp">
  <div class="card-header">
    <h6>Block Friends</h6>
  </div>
  <div class="card-body over_flow_wrapper_big" *ngIf="!freeuser">
    <div *ngFor="let friend of blockFriends">
      <app-friend-list class="card mb-2 bg-dark" [friend]="friend"></app-friend-list>
    </div>
    <div class="row mt-4" *ngIf="!freeuser && pagelarg">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="card-text text-center" *ngIf="loading">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <p *ngIf="dataNotFound" class="text-center"> There is no swinger/friends block in your account</p>
  </div>
  <div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
  </div>
</div>


=