import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MailSubscriptionService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

mailSubscribe(data: any):Observable<any> {
  return this.http.post<any>(`${environment.apiUrl}/user-subscription`, data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

}
