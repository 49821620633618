import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-favirate-friends',
  templateUrl: './favourite-friends.component.html',
  styleUrls: ['./favourite-friends.component.css']
})
export class FavouriteFriendsComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  freeuser = false;
  currentUser:any;

  constructor(private friendService: FriendsService,
    private authenticationService: AuthenticationService,
    private commonService:CommonService,
    private titleService:Title,
    ) {
      this.titleService.setTitle('Favourite Friends | Swingerflings')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;  
      if(this.currentUser.plan_id == 1){
        this.freeuser = true;
        // console.log(this.currentUser.plan_id);
      }
  });
   }
  friends: Friends;

  ngOnInit(): void {
    this.getFavoriteFriends();
  }
  pagination:any;
  pagelarg = true;
  dataNotFound = false;
  getFavoriteFriends(){
  this.friendService.getFavoriteFriends().subscribe( res => {
    if(res.data.data.length == 0 ){
      this.loading = false;
      this.dataNotFound = true;
    }
    this.loading = false;
    this.pagination = res.data;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
    this.friends = res.data.data;
    if (this.pagination.links.length == 3) {
      this.pagelarg = false;  
   }else{
     this.pagelarg = true;  
   }
  });
 }

 activePage(url:string){
  this.loading = true;
  if(url){
  this.commonService.getPagination(url).subscribe(data => {
    if(data != null){
      this.friends = null;
      this.friends = data.data.data;
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.loading = false;
    }else{
      this.loading = false;
    }
  }, error => {
    this.loading = false;
  });
}
}


}
