<app-group-navigation></app-group-navigation>
<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header"><h6><span class="hoverPointer" >Create Group</span></h6></div>
    <div class="card-body">
        <div class="row" id="wrapper">
            <div class="col-md-3">
              <div class="create-album-w  text-center"  (click)="openCreateAlbumModal()">
                <i class="fas fa-plus"></i>
              </div>
            </div>

            <div *ngIf="groupLoading">
              <div class="spinner-border text-light mt-4" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
           
            <div class="col-md-3"  *ngFor="let group of groups; let i = index;">
              <div class="top_wrapper">
                  <div  class="edit_mode_wrapper">
                      <div [ngSwitch]="group.privacy" class='edit_mode_item_r'>
                       
                        <i *ngSwitchCase="1" (click)="privacyEditMode(i);" class="fas fa-user-friends"></i>
                        
                        <i *ngSwitchCase="2" (click)="privacyEditMode(i);" class="fas fa-globe"></i>
                      </div>
                    <span (click)="deleteGroup(group.id, i)"><i class="fas fa-trash-alt"></i></span>
                   </div>
                </div>
                <div *ngIf="group.privacy_mode" class="privacy_mode_wrapper">
                  <ul>
                    <li (click)="changeGroupPrivacy(group.id, 2, i);"><i class="fas fa-globe"></i> Public</li>
                    <li (click)="changeGroupPrivacy(group.id, 1, i);"><i class="fas fa-user-friends"></i> Friends</li>
                    
                  </ul>
                </div>
                <div *ngIf="group.deleteLoadingStatus"  class="spinner-grow spinner-grow-sm staus_grow_delete" role="status">
                  <span class="visually-hidden"></span>
                </div>
                <div *ngIf="group.loadingPrivacyStatus"  class="spinner-grow spinner-grow-sm staus_grow" role="status">
                  <span class="visually-hidden"></span>
                </div>

              <div class="create-album-wrapper text-center" style="background-image:url({{groupBackground}})">
               <img class="img-fluid" src="{{group.src?group.src:groupBackground}}" alt="{{group.name}}">
                <div class="bottom-text-wrapper">
                  <p class="text-capitalize mb-0"><a routerLink="/group-panel/{{group.id}}/post">{{group.name}}</a></p>
                  <small>14 mermbers</small>
                </div>
              </div>
            </div>
      </div>

<div class="row">
<div class="col-md-12">
 <div class="modal animate__animated animate__zoomIn" *ngIf="openModel" [ngClass]="openModel==true?'show':'hide'" id="createAlbum" role="dialog">
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Create Group</h5>

          <button type="button" class="btn-close" (click)="closeCreateAlbumModal();" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
        </div>
            <div class="modal-body">
                <form [formGroup]="groupForm" (ngSubmit)="onSubmit();">
                    <input type="text" formControlName="userID" class="form-control d-none" readonly />
                <div class="form-group" *ngIf="!albumUpdateStatus">
                    <label for="sortTagline">Group Name</label>
                    <input type="text" class="form-control" formControlName="group_name">
                </div>

                <div class="form-group">
                    <label for="location"></label>
                    <div class="row">
                        <div class="col-md-6">
                          <label>Select privacy</label>
                            <select class="form-control" formControlName="privacy">
                                <option disabled value="0" [selected]="0">Select Privacy</option>
                                <option value="1">Friends</option>
                                <option value="2">Public</option>
                            </select>
                        </div>
                    </div>
                    <small class="text-secondary">What type Swinger you want to show group</small>
                
                    <div *ngIf="submitted && f.privacy.errors" class="invalid-feedback">
                        <div *ngIf="f.privacy.errors.required">Type is required.</div>
                    </div> 
                </div>
                <div class="form-group">
                <label for="friends-list"></label>
                <ng-select2 [data]="friendList" class="friendSelect" formControlName="friends" id="friends-list"  [placeholder]="'Please select a friends'" width="100%" [options]="{multiple: true,  'allowClear': true }"></ng-select2>
                </div>
            <button  [disabled]="loading" class="btn btn-warning"><div 
            *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
            </div> Submit</button> 
          </form>
        </div>
      </div>
    </div>
  </div>

       </div>
     </div>
    </div>
    <div *ngIf="checkpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>


