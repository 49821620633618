
<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header text-left color">
        <h4 class="around_space colorss">Audio </h4>
    </div>
    <div class="card-body" *ngIf="!freeuser">
        <div class="row" id="wrapper">
            <div *ngIf="albumLoading">
                <div class="spinner-border text-light mt-4" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
            <div class="col-md-12 mb-2 pl-1 pr-1">
                <div class="row">
                    <div class="col-md-4" *ngFor="let audio of audiofiles; let i=index">
                        <div class="card mb-2 bg-dark paddin">
                            <div class="text-center">
                                <div class="top_wrapper">
                                    <div class='video_views_wrapper'>
                                        <span (click)="audioLike(audio.id,i)"><i class="fas fa-thumbs-up like"></i>
                                            {{audio.totallikes}}</span>
                                            <span><i class="fas fa-eye"></i> {{ audio.totalviews}}</span>
                                    </div>
                                    <div *ngIf="audio.loadingPrivacyStatus"
                                        class="spinner-grow spinner-grow-sm staus_grow" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>

                                    <div *ngIf="audio.deleteLoadingStatus"
                                        class="spinner-grow spinner-grow-sm staus_grow_delete" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div><br>
                              

                                <audio #audio (play)="onPaly(audio, audio.id,i)" style="outline:none; width: 100%;" controls controlsList="nodownload">
                                    <source  [src]="audio?.src" type="audio/mp3">
                                </audio>
                                <!-- <p class="text-left">{{audio?.audio_url.slice(0, -4)}}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="!freeuser" class="row mt-4">
                <div class="col-md-12 text-center" *ngIf="checkpages">
                    <nav aria-label="..." class="text-center">
                        <ul class="pagination justify-content-center">
                            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
                                [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                                    [innerText]="link.label"></a></li>
                        </ul>
                    </nav>
                </div>
            </div>

            
        </div>

        <div *ngIf="nodatas" class="text-center">
            <p>There is no audio's available</p>
        </div>
        
        <div *ngIf="albumStatus" class="text-center">
            <div class="spinner-border text-light mt-4" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
    </div>

</div>