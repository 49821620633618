import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import { Title } from "@angular/platform-browser";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { EventService, SwingersService, UserMediaService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsServices } from '../../_services/notifications.service'

@Component({
  selector: 'app-swinger-events-new',
  templateUrl: './swinger-events.component.html',
  styleUrls: ['./swinger-events.component.css']
})
export class SwingerEventsComponent implements OnInit {
  IDs:any;
  buttonhides:boolean = true
  userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
  addEventdateForm: FormGroup;
  eventBackground = ['../../../assets/images/date_bg_1.jpg','../../../assets/images/date_bg_2.jpg',
'../../../assets/images/date_bg_3.jpg','../../../assets/images/date_bg_4.jpg','../../../assets/images/date_bg_5.jpg'
];
  setEventBackground: any;
  eventDateList: any;
  actionEventData: any;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    events: [
    ],
    plugins: [ interactionPlugin ],
    eventClick: this.dateEventClick.bind(this)
  };
  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private eventDateService: EventService,
    private swingerService: SwingersService,
    private route: ActivatedRoute,
    private NotificationsServices:NotificationsServices,
    private userMedia:UserMediaService,
    private Title:Title
    ) {
      this.Title.setTitle('Swingers Events | Swingerflings')
    this.route.queryParams.subscribe(params=>{
      this.IDs = params['d'];
      if(this.IDs != null && this.IDs != undefined && this.IDs !=""){
        this.geyEventByID(this.IDs);
        this.buttonhides = false;
        // console.log('working')
      }else{
        this.route.parent.params.subscribe( params => {
          this.getSwingerEvents(params.swingerId);
          this.getDetails(params.swingerId)
          this.buttonhides = true;
        });
      }
     })
  }

  options: FlatpickrDefaultsInterface;
  addEventLoader = false;

  ngOnInit(): void {
  
    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };

  

  }

  UserName:any;
  getDetails(id: any) {
    this.userMedia.getSwingerDetails(id).subscribe(res => {
      this.UserName = res.data.name;
    }, error => {
      console.log(error);
    });
  }

  @ViewChild('eventDetails', { static: false }) eventDetails: TemplateRef<any>;
  resposnse:any = [];
  geyEventByID(ids:number){
    this.NotificationsServices.getEventID(this.IDs).subscribe(res=>{
      // console.log(res)
      this.resposnse = res.data;
      let newData:any [] = [];
      newData.push(this.resposnse);
      this.eventDateList = newData;
    })

  }

  getSwingerEvents(id:number){
    this.swingerService.getAllEventsBySwingerId(id)
    .pipe(map(res =>{
      res.data.data.map(eventData => {
        eventData.start = eventData.start_date;
        eventData.id = eventData.id;
        eventData.end = eventData.end_date;
        eventData.title = eventData.event_name;
        eventData.source = eventData;
        eventData.classNames = `cl_date_${eventData.id}`;
       
    })
    return res.data.data;}
    ))
    .subscribe(eventDates => {
      // console.log(eventDates , "cgbvmfnf")
      // this.eventDateList.length = 0;
     this.eventDateList = eventDates.reverse();
     this.calendarOptions.events = this.eventDateList;
    });
  }


  handleDateClick(arg) {
   }

   dateEventClick(info,ev?:number) {
    let eventid;
     if(info==''|| ev== undefined){
      eventid = info.event.id;
      info.el.style.borderColor = '#696666';
      info.el.style.backgroundColor = '#6b796e';
     }else{
      eventid = ev;
     }
    this.setEventBackground = this.eventBackground[Math.floor(Math.random() * this.eventBackground.length)];
    this.eventDateService.getEventDateById(eventid).subscribe(res => {
      this.actionEventData = res.data;
      this.modal.open(this.eventDetails, { size: 'lg' });
    });
    
  }


}
