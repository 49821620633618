import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService, GalleryService, SwingersService } from '@app/_services';
import { environment } from '@environments/environment';
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationsServices } from '../../_services/notifications.service';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-swinger-timeline',
  templateUrl: './swinger-timeline.component.html',
  styleUrls: ['./swinger-timeline.component.css']
})
export class SwingerTimelineComponent implements OnInit {
IDs:any;
_subscription: Subscription;
submitted = false;
userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
defaultProfile = environment.defaultProfile;
loading = false;
message: string;
error = '';
timeline: FormGroup;
openModel = false;
gallerImages = [];
galleryFiles = [];
timeLineImages: any[] = [];
timeLineStoreMessage: string;
timeLineLoading = false;
editMode = false;
swingerId:number;
pagination:any;
checkpages = false;
  constructor(private fb: FormBuilder,
     private galleryService: GalleryService,
     private _lightbox: Lightbox,
     private _lightboxEvent: LightboxEvent,
     private swingerService: SwingersService,
     private route: ActivatedRoute,
     private NotificationsServices:NotificationsServices,
     private commonService: CommonService,
     private Title:Title
     ) {
       this.Title.setTitle('Swinger Timeline | Swingerflings')
      this.route.queryParams.subscribe(params=>{
        this.IDs = params['d'];
        if(this.IDs != null && this.IDs != undefined && this.IDs !=""){
         this.gettimelineByID();
        }else{
          this.route.parent.params.subscribe( params => {
            this.swingerId = params.swingerId;
            this.getSwingerTimeline(params.swingerId);
          });
        }
       })
     }
 

ngOnInit(): void {
  // this.route.parent.params.subscribe( params => {
  //   this.swingerId = params.swingerId;
  //   this.getSwingerTimeline(params.swingerId);
  // });
}

get f() { return this.timeline.controls; }

gettimelineByID(){
  // console.log(this.IDs)
  this.NotificationsServices.gettimelineID(this.IDs).subscribe((res)=>{
    this.timeLineImages.length = 0;
    this.timeLineImages.push(res.data);
    for(let image in this.timeLineImages ){
      this.timeLineImages[image].src = (this.timeLineImages[image].s3Image) ? this.timeLineImages[image].s3Image: this.defaultProfile;
     }
     if(this.timeLineImages.length == 0 ){
      this.loading = false;
      this.dataNotFound = true;
    }
  })
}
dataNotFound = false;
    getSwingerTimeline(id: any){
      this.timeLineLoading = true;
      this.swingerService.getAllTimelineBySwingerId(id).pipe(map(res=>{
        console.log(res)

        if(res.data == "data not found" ){
          this.timeLineLoading = false;
          this.dataNotFound = true;
        }else{}
        this.pagination = res.data;
        // console.log(this.pagination.links.length);
        this.checkpages = this.pagination.links.length >= 4?true: false;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        res.data.data.map(image=>{
          // console.log(image, "dafdf")
          image.src = (image.image) ? image.s3Image: this.defaultProfile;
        });
        return res.data.data;
      })).subscribe(timeLineData => {
        console.log(timeLineData)
        this.timeLineLoading = false;
        this.timeLineImages = timeLineData;
      
      });

    }

    activePage(url:string){
      if(url){
        this.timeLineLoading = true;
        this.commonService.getPagination(url).pipe(map(res=>{
        this.pagination = res.data;
        this.checkpages = this.pagination.links.length >= 4?true: false;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        res.data.data.map(image=>{
          image.src = (image.image) ? image.s3Image: this.defaultProfile;
        });
        return res.data.data;
      })).subscribe(timeLineData => {
        this.timeLineLoading = false;
        this.timeLineImages = timeLineData;
        if(this.timeLineImages.length == 0 ){
          this.loading = false;
          this.dataNotFound = true;
        }
      });
    }

    }

    open(index: number): void {
      this._lightbox.open( this.timeLineImages, index);
      this.storetimeLineView(index);
      this._subscription = this._lightboxEvent.lightboxEvent$
      .subscribe(event => this._onReceivedEvent(event));
    }

    close(): void {
      this._lightbox.close();
    }


  openCreateAlbumModal(){
    this.gallerImages = [];
    this.openModel = true;
    this.message = '';
  }

  closeCreateAlbumModal(){
    this.gallerImages = [];
    this.openModel = false;
  }

  private _onReceivedEvent(event: any): void {

    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }

    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      this.storetimeLineView(event.data);
    }
  }


  imageLike(id: any, index: number){
    this.galleryService.imageLike(id).subscribe(res => {
      // console.log(res)
    this.timeLineImages[index].Likes = res.totalLikes;
    });
  }

  storetimeLineView(index: number){
    const id = this.timeLineImages[index].id;
    this.galleryService.storeTimeLineView(id).subscribe(res => {
      this.timeLineImages[index].views = res.views;
    });
  }


}
