<div class="card" >
    <div class="card-body" #invoicedownload>
        <h3 class="text-center font-weight-bold mb-1"><img style="max-width: 272px;" [src]="siteLogoUrl" class="img-fluid"></h3>
        <hr>
        <!-- <p class="text-center font-weight-bold mb-0">GSTIN No.: 09AANFB4888NIZH</p> -->
        <!-- <p class="text-center font-weight-bold"><small class="font-weight-bold">Phone No.: {{invoceData.data?.phone}}</small></p> -->
        <div class="row pb-2 p-2">
            <div class="col-md-6">
             <!-- <p ><strong>Invoice Number</strong>: SW{{invoceData.data?.id}}</p> -->
             <p class="mb-0"><strong>Name</strong>: {{invoceData.data?.first_name}}&nbsp; {{invoceData.data?.last_name}}</p>
             <p class="mb-0"><strong>Email</strong>: {{invoceData.data?.email}}</p>
             <p class="mb-0"><strong>Phone</strong>: {{invoceData.data?.phone}}</p>
             <p ><strong>Address</strong>: {{invoceData.data?.address}}, {{invoceData.data?.city}}, {{invoceData.data?.country}}</p>
             <!-- <p><strong>Phone</strong>: {{invoceData.data?.phone}}</p>						  -->
            </div>

            <div class="col-md-6 text-right">
            <p class="mb-0"><strong>Invoice Number</strong>: SW{{invoceData.data?.id}}</p>
             <p class="mb-0"><strong>Invoice Date</strong>: {{invoceData.data?.active_date}}</p>
             <!-- <p><strong>Phone</strong>: {{invoceData.data?.phone}}</p> -->
            </div>
        </div>
        <div class="table-responsive">
        <table class="table table-bordered mb-0">
            <thead>
                <tr>
                    <th class="text-uppercase small font-weight-bold">SR No.</th>
                    <th class="text-uppercase small font-weight-bold">Plan Name</th>
                    <th class="text-uppercase small font-weight-bold">Price</th>
                    <th class="text-uppercase small font-weight-bold">Coupon (<span style="text-transform: none;">If Any</span>)</th>
                    <th class="text-uppercase small font-weight-bold">Discount (<span style="text-transform: none;">If Any</span>)</th>
                    <!-- <th class="text-uppercase small font-weight-bold">Tax Amt.</th> -->
                    <th class="text-uppercase small font-weight-bold">Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">1.</td>
                    <td>{{invoceData.data?.plan_name}}</td>
                    <td class="text-center">{{invoceData.data?.payment_amount | currency: 'USD'}}</td>
                    <td class="text-center">{{invoceData.data?.discount_amount}}</td>
                    <td class="text-center">{{invoceData.data?.discount_amount }}%</td>
                    <!-- <td>{{0 | currency: 'USD'}}</td> -->
                    <td class="text-center">{{invoceData.data?.payment_amount | currency: 'USD'}}</td>
                </tr>
            </tbody>
            <tfoot class="font-weight-bold ">
             <tr>
                <td colspan="5" class="text-right">Total</td>
                <!-- <td>{{0 | currency: 'USD'}}</td> -->
                <td class="text-center">{{invoceData.data?.payment_amount | currency: 'USD'}}</td>
             </tr>
             
             
            </tfoot>
          </table>
        </div><!--table responsive end-->
        
           <p class="mt-2 mb-1">Thank you for register on Swingerflings. We  hope you will enjoy Swingerflings services.</p>
           <p class="mb-0"><strong>Note :</strong></p>
           <p class="mb-0">
            No refund available once  payment successfully done. You can cancel your membership anytime but no money refunded. If you have any issue.</p>
           <p class="font-weight-bold small mt-0">So please read Terms & Conditions or write us to support@swingerflings.com</p>
        
   </div>
   </div>
   <div class="text-right mt-2">
    <button class="btn btn-sm btn-primary" (click)="dwnloadinvoce()"><i class="fas fa-download"></i> Download</button>
   
   </div>