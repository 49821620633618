import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { UserService } from '@app/_services';
import { commonData } from '@app/_helpers/common';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {

 

  constructor(
    private fb: FormBuilder, 
    private userService: UserService,
    private title:Title) {
      this.title.setTitle('My Profile | Swingerflings')
     }
 
  userInfo: FormGroup;
  submitted = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = false;
  ifSuccess = false;
  message: string;
  error = '';
  genderList = commonData.gender;


  ngOnInit(): void {
    this.getUserInfo();
    this.userInfo = this.fb.group({
      userID: [this.userId],
      first_name: ['',Validators.required],
      gender: ['',Validators.required],
      sortTagline: ['',Validators.required],
      lookingFor: ['',Validators.required],
      describe_yourself: ['',Validators.required],
      additional_info: ['',Validators.required],
    });
  }

  get f() { return this.userInfo.controls; }

  onSubmit(){
   this.loading = true;
   this.submitted = true;
   this.userService.updateUserInfo(this.userInfo.value).subscribe( res => {
    this.submitted = false;
    this.loading = false;
    this.ifSuccess = true;
    this.message = res.message;
   },error =>{
    this.error = error;
    this.loading = false;
   })
  
  }

  getUserInfo(){
    this.submitted = true;
    this.userService.getuser().subscribe( res => {
      console.log(res);
      this.userInfo.controls.first_name.setValue(res.name);
      this.userInfo.controls.gender.setValue(res.gender);
      this.userInfo.controls.userID.setValue(res.id);
      this.userInfo.controls.sortTagline.setValue(res.short_tag_line);
      this.userInfo.controls.lookingFor.setValue(res.lookingfor);
      this.userInfo.get('describe_yourself').setValue(res.describe_your_self);
      this.userInfo.controls.additional_info.setValue(res.additional_info);
    }, error => {
      console.log(error);
    })
   }

}
