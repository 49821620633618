import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserMediaService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Router } from "@angular/router";
import { SharedService } from "../../_services/shared.service";
import { Popup } from '@environments/environment';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-invoce-view',
  templateUrl: './invoce-view.component.html',
  styleUrls: ['./invoce-view.component.css']
})
export class InvoceViewComponent implements OnInit {
  constructor( 
    private modal: NgbModal,
    private userUserMedia: UserMediaService,
    private Router:Router,
    private SharedService:SharedService,
    private Title:Title) {
      this.Title.setTitle('Plan History | Swingerflings')
     }
  @ViewChild('checkInvoice', { static: true }) checkInvoice: TemplateRef<any>;
  userInvoces:any;
  invoceData:any;
  invoiceLoading = false;
  loading = true;

  ngOnInit(): void {
  this.getInvoiceHistory();
  }


  closeModal(){
    this.modal.dismissAll(this.checkInvoice);
  }


  getInvoiceHistory(){
    this.loading = true;
    this.userUserMedia.getUserInvoices().subscribe(res=>{
      // console.log(res)
      this.loading = false;
      this.userInvoces = res.data;
    },error=>{
      console.log(error);
    })
   
  }


  getUserInvoicebyId(id:number){
    this.invoiceLoading = true;
    this.userUserMedia.getUserInvoicebyId(id).subscribe(res=>{
      // console.log(res)
      this.invoceData = res;
      this.modal.open(this.checkInvoice, { size: 'xl',backdropClass:'largeModal'});
    },error=>{
      console.log(error);
    })
  
  }

  cancelMembership(ids:number){
    Swal.fire({  
      title: `${Popup.CancelMemberShiptitle}`, 
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: 'Yes, delete it!',  
      cancelButtonText: 'No, keep it'  
    }).then((result) => {  
      if (result.value) {
        this.userUserMedia.cancelSubpaln(ids).subscribe(res => {
          Swal.fire("Success", `${res.message}`, "success").then(ok=>{
            if(ok){
              this.SharedService.sendClickEvent();
              sessionStorage.clear();
              this.Router.navigateByUrl('/login')
            }
          });
        },error=>{
          Swal.fire(  
            'Server Error',  
            `${Popup.error}`,  
            'error'  
          )
        });
          
      } else if (result.dismiss === Swal.DismissReason.cancel) {  
        Swal.fire(  
          'Cancelled',  
          `${Popup.CancelMemberShipSafe}`,
          'error'  
        )  
      }  
    }) 
  }
  

}
