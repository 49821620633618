<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header color"><h4><span class="hoverPointer" (click)="openAlbumWrapper();">Albums</span> <span *ngIf="albumName" class="text-capitalize"> | {{albumName}}</span> </h4></div>
    <div class="card-body">
        <div class="text-center" *ngIf="albumLoading">
            <div class="spinner-border text-light mt-4" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
        <div class="row" id="wrapper">
            <ng-template [ngIf]="!openAlbumImages">
            <div class="col-md-2 col-6"  *ngFor="let album of albums; let i = index;">
              <div class="create-album-wrapper text-center " (click)="openAlbum(album.id,i)">
               <img class="img-fluid" src="{{album.image}}" alt="{{album.name}}">
                <div class="bottom-text-wrapper">
                  <p class="text-capitalize mb-0">{{album.name}}</p>
                  <small>{{album.Totalalbum}} Photo's</small>
                </div>
              </div>
            </div>
            </ng-template>
      

        <div *ngIf="albumStatus" class="text-center">
          <div class="spinner-border text-light mt-4" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>

      
          <div class="col-md-2 col-6 p-1" *ngFor="let image of albumImages; let i=index">
            <div class="top_wrapper">
              <div class='video_views_wrapper'>
                <span (click)="albumImageLike(image.id,i)" class="like"><i class="fas fa-thumbs-up"></i> {{image.likes}}</span>
                <span><i class="fas fa-eye"></i> {{image.views}}</span>
              </div>
              </div>
            <div class="image-wrapper text-center">
              <img src="{{image.s3Image}}" (click)="open(i)" class="img-fluid">
            </div>
          </div>
      </div>
      <p *ngIf="dataNotFound  " class="text-center">There is no photo album available. </p>
      <div *ngIf="alImgcheckpages" class="row mt-4">
        <div class="col-md-12 text-center">
          <nav aria-label="..." class="text-center">
            <ul class="pagination justify-content-center">
              <li *ngFor="let link of alImgPagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="albumImageActivePage(link.url)" [innerText]="link.label"></a></li>
            </ul>
          </nav>
        </div>
      </div>


      <div *ngIf="checkpages" class="row mt-4">
        <div class="col-md-12 text-center">
          <nav aria-label="..." class="text-center">
            <ul class="pagination justify-content-center">
              <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
            </ul>
          </nav>
        </div>
      </div>

    </div>
  </div>


