<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header">
        <h4 class="around_space color">Videos </h4>
    </div>
    <div class="card-body timeline_wrapper">
        <div class="row" id="lightgallery">
            <div class="col-md-3 col-6 mb-2 pl-1 pr-1" *ngFor="let video of videosGallery; let i=index">
                <div class="image-wrapper text-center">
                    <div class="top_wrapper">
                        <div class='video_views_wrapper'>
                            <span (click)="videoLike(video.id,i)"><i class="fas fa-thumbs-up like"></i> {{video.totallikes}}</span>
                            <span><i class="fas fa-eye"></i> {{video.totalviews}}</span>
                        </div>
                    </div>
                    <video src="{{video.src}}" class="singleVideo"></video>
                    
                    <div class="play-video-wrapper" (click)="getVideoById(video.id, i)">
                        <img [src]="videoPlayIcon" style="width:50px" class="img-fluid">
                    </div>
                </div>
            </div>
            <p *ngIf="dataNotFound  " class="text-center">There is no video available.</p>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- Modal -->
                <div class="modal animate__animated animate__zoomIn" *ngIf="openModel" [ngClass]="openModel==true?'show':'hide'" id="createAlbum" role="dialog">
                    <div class="modal-dialog ">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Upload Videos</h5>
                                <button type="button" class="btn-close" (click)="closeCreateAlbumModal();" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="videosList" (ngSubmit)="storeVideos()">
                                    <input type="text" formControlName="userID" class="form-control d-none" readonly />
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Select Privacy</label>
                                            <select class="form-control" formControlName="privacy">
                            <option disabled value="0" [selected]="0">Select Privacy</option>
                              <option value="1">Public</option>
                              <option value="2">Show only Friends</option>
                              <option value="3">Only Me</option>
                          </select>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input type="file" #albumImages (change)="loadVideo(albumImages.files)" accept="video/mp4,video/x-m4v,video/*" class="form-control d-none" formControlName="videos" />
                                        <div *ngIf="submitted && f.videos.errors" class="invalid-feedback">
                                            <div *ngIf="f.videos.errors.required">Videos is required</div>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="create-album-wrapper text-center mb-3" (click)="albumImages.click();">
                                                <i class="fas fa-plus"></i>
                                            </div>
                                        </div>
                                        <div class="col-md-6" *ngFor="let videos of gallaryVideos">
                                            <video class="overlay-video" width="100%" height="120" oncontextmenu="return false;">
                        <source src="{{videos}}" type="video/*">
                      </video>
                                        </div>
                                    </div>

                                    <div *ngIf="ifSuccess" class="alert alert-success" role="alert"><i class="fas fa-check-circle"></i> {{message}}</div>
                                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                                    <button [disabled]="loading" class="btn btn-warning"><div 
            *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
            </div> Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="checkpages" class="row mt-4">
        <div class="col-md-12 text-center">
          <nav aria-label="..." class="text-center">
            <ul class="pagination justify-content-center">
              <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
            </ul>
          </nav>
        </div>
      </div>


      <ng-template #showVideo let-close="close">
        <div class="video_modal_wrapper">
            <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body text-light">
            <vg-player  (onPlayerReady)="onPlayerReady($event)">
                        <vg-overlay-play (click)="onClickPlaylistItem(singleVideo.id, singleVideo.index)"></vg-overlay-play>
                        <vg-buffering></vg-buffering>

                        <vg-scrub-bar>
                            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                        </vg-scrub-bar>

                        <vg-controls>
                            <vg-play-pause (click)="onClickPlaylistItem(singleVideo.id, singleVideo.index)"></vg-play-pause>
                            <vg-playback-button></vg-playback-button>
                            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
                            <vg-mute></vg-mute>
                            <vg-volume></vg-volume>
                            <vg-fullscreen></vg-fullscreen>
                        </vg-controls>
                        <video #media [vgMedia]="media" src="{{singleVideo.src}}" 
                            type="video/*">
                        </video>
            </vg-player> 
        
            <!-- <video ref="video" class="videoplayer" id="video" controlslist="nodownload" controls="">
                <source type="video/mp4" src="{{singleVideo.src}}">
            </video> -->
        </div>
    </ng-template>
</div>