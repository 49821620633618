import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};


getUserProfileCommentsBy(id: number) {
  return this.http.get<any>(`${environment.apiUrl}/get-user-comments/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


storeUserProfileComment(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/profile-comment`, data , this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

commentUserProfileLike(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/comment-like`, data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );

}



}
