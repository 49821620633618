
           <div class="card bg-dark text-light mb-3 mt-2">
            <div class="card-header"><h6>Change Password</h6></div>
            <div class="card-body ">
             <div class="row">
               <div class="col-md-12">
                    <form [formGroup]="userChangePass" (ngSubmit)="onSubmit()"> 
                    <input type="text" formControlName="userID" class="form-control d-none" readonly />
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="sortTagline">Name</label>
                          <input type="text" formControlName="name" class="form-control" readonly/>
                      </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="sortTagline">Email</label>
                          <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required.</div>
                        </div> 
                      </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="password">Password</label>
                          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required.</div>
                        </div> 
                      </div>

                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="confirm">Confirm Password</label>
                          <input type="password" formControlName="confirm" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirm.errors }" />
                          <div *ngIf="submitted && f.confirm.errors" class="invalid-feedback">
                            <div *ngIf="f.confirm.errors.required">Confirm Password is Not Match.</div>
                        </div> 
                      </div>
                      </div>
                    </div>
               
       <div *ngIf="ifSuccess" class="alert alert-success" role="alert"><i class="fas fa-check-circle"></i>  {{message}}</div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                <button  [disabled]="loading" class="btn btn-warning"><div 
        *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
         <span class="visually-hidden"></span>
       </div> Update</button> 
             </form> 
               </div>
             </div>
            </div>
          </div>
      

