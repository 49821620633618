<app-header></app-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card text-light bg-dark set_outline">
                    <!-- <img src="{{sitelogo}}" class="img-fluid logo_cu"> -->
                    <div class="card-body">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <h1 class="text-center titles" ><i class="fas fa-lock" style="color: #ffc107;"></i> &nbsp;LOGIN</h1>
                            <!-- <h3 class="text-center">LOGIN</h3> -->
                            <div class="form-group">
                                <label for="username">Email</label>
                                <input type="text" formControlName="username" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Username is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="false"
                                    class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                   <span class="showhide"> 
                                   <a (click)="hide = !hide"> <i class="fas fa-eye-slash" *ngIf="hide"></i>
                                    <i class="fas fa-eye" *ngIf="!hide"></i></a>
                                </span>
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>

                            <div class="form-group inli">
                                <input type="checkbox" formControlName="remember" />
                                <sapn class="span">Remember me</sapn>
                            </div>
                            <div class="form-group inli" style="float: right;">
                                <a [routerLink]="['/forgot-password']"><span class="span">Forgot Password?</span></a>
                            </div>

                            <div>
                                <button [disabled]="loading" class="btn btn-danger">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    <span *ngIf="!loading">Login Now</span>
                                    <span *ngIf="loading">Please Wait</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>