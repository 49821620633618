import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, FriendsService} from '@app/_services';
import { Friends } from '@app/_models/friends';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-friend-request-sent',
  templateUrl: './friend-request-sent.component.html',
  styleUrls: ['./friend-request-sent.component.css']
})
export class FriendRequestSentComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  freeuser = false;
  currentUser:any;


  constructor(
    private reqSent: FriendsService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private titleService:Title,
    ) {
    this.titleService.setTitle('Sent Friends Request | Swingerflings')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if(this.currentUser.plan_id == 1){
        this.freeuser = true;
      
      }
  });

   }
  requestedFirend: Friends;
  dataNotFound = false;


  ngOnInit(): void {
    this.getPanddingFirendsFriends();
  }
  pagination:any;
  pagelarg = true;
  getPanddingFirendsFriends(){
    this.reqSent.getSentFriendRequests().subscribe(data => {
      if( data == null ){
        this.loading = false;
        this.dataNotFound = true;
      }
      this.loading = false;
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.requestedFirend = data.data.data;
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
    });
  }

  activePage(url:string){
    this.loading = true;
    if(url){
    this.commonService.getPagination(url).subscribe(data => {
      if(data != null){
        this.requestedFirend = null;
        this.requestedFirend = data.data.data;
        this.pagination = data.data;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        this.pagination.links[0].label = 'Previous';
       this.pagination.links[this.pagination.links.length - 1].label = 'Next';   
        this.loading = false;
      }else{
        this.loading = false;
       
      }
    }, error => {
      this.loading = false;
      this.dataNotFound = true;
    });
  }
  }

}
