import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent implements OnInit {
  @Input() swinger: any;
  videoPlayIcon = '../../../../assets/images/play-button.png';
  constructor() { }

  ngOnInit(): void {
    // console.log(this.swinger)
  }

}
