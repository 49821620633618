import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Friends } from '@app/_models/friends';

@Injectable({
  providedIn: 'root'
})
export class SentFriendRequestService {

  constructor(private http: HttpClient ) { }

  handleError: any;
  friendsUrl = 'https://jsonplaceholder.typicode.com/users';

  getSendFirendRequest() {
    return this.http.get<Friends>(this.friendsUrl).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

}
