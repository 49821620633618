import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.css']
})

export class EmailVerifyComponent implements OnInit {

  isLogin: any;
  currentUser: any;
  mainHeading = 'Your email id success fully verified.';

  constructor(
    private authenticationService: AuthenticationService,
    private Title:Title) {
      this.Title.setTitle('Email Verify | Swingerflings')
    this.authenticationService.currentUser.subscribe((x: any) => {
      this.currentUser = x;
      if ( this.currentUser != null ){
          this.isLogin = true;
      }else{
         this.isLogin = false;
      }
  });


  }

  ngOnInit(): void {
    if(this.currentUser.email_status == 1) {
      console.log(this.currentUser.email_status);
      this.mainHeading = 'Your email id already verified.';
    }else{
      this.mainHeading = 'Your email id success fully verified.';
    }
  }




}
