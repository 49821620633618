import { Component, OnInit, HostListener, Inject, ViewChild, ElementRef, AfterViewChecked} from '@angular/core';
import { environment } from '@environments/environment';
import {  } from '@angular/platform-browser';
import { WINDOW } from "../../_services/windows.service";
import { AuthenticationService, CommonService, SwingersService, GalleryService} from '@app/_services';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  commentsForm!:FormGroup;
  repcommentsForm!:FormGroup;
  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  requestLoding = false;
  data: any;
  pagination: any;
  currentUser:any;
  freeuser = false;
  togglePanel: any = {};
  togglePanelPar: any = {};
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private SwingersService:SwingersService,
    private formBuilder: FormBuilder,
    private galleryService: GalleryService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private titleService:Title
    ){ 
      this.titleService.setTitle('Videos | Swingerflings')
      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
        if(this.currentUser.plan_id === 1){
          this.freeuser = true;
        }
    });
    }
    
  swingers: any[] = [];



  ngOnInit(): void {
    this.getallSwingersViders();
    this.commentsForm = this.formBuilder.group({
      comment_content:['', Validators.required],
    });
    this.repcommentsForm = this.formBuilder.group({
      comment_content:['', Validators.required],
    });
  }
  pagelarg = true;
  getallSwingersViders(){
    this.galleryService.getAllVideos().subscribe(data => {
      console.log(data)
      this.loading = false;
      this.swingers = data.data.data;
      console.log( data.data.data)
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
        }
      }
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
    }, error => {
      this.loading = false;
    });
  }


  getPageData(url:string){
    this.loading = true;
    if(url){
    this.commonService.getPagination(url).subscribe(data => {
      this.swingers = null;
      this.loading = false;
      this.swingers = data.data.data;
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
        }
      }
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for(const swinger in this.swingers){
      this.swingers[swinger].is_pic = (this.swingers[swinger].is_pic) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].is_pic}`: this.defaultProfile;
      }
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
    }, error => {
      this.loading = false;
    });
         
  }
   
  }
  nodatainsearch = false;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name : filterValue
    }
    this.SwingersService.searchswing(body).subscribe(res=>{
      // console.warn(res)
      this.swingers = null;
      this.loading = false;
      this.swingers = res.data.data;
      for (let index = 0; index < this.swingers.length; index++) {
        if(this.swingers[index].user_status == 0){
         this.swingers.splice(index,1)
         this.nodatainsearch = true;
        }
      }
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for(const swinger in this.swingers){
      this.swingers[swinger].is_pic = (this.swingers[swinger].is_pic) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].is_pic}`: this.defaultProfile;
      }
    })
  }

@ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
public startedPlay:boolean = false;
isEnded: boolean = false;
  @HostListener("window:scroll", [])
  
  onWindowScroll() {
    var videos = document.getElementsByTagName("video");
    var fraction = 0.9; // Play when 90% of the player is visible.
    const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;

    for (var i = 0; i < videos.length; i++) {
      var video = videos[i];
      var x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
        b = y + h, //bottom
        visibleX, visibleY, visible;

      visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
      visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

      visible = visibleX * visibleY / (w * h);
      if (visible < fraction) {
        // video.play();
      } else {
        // video.pause();
      }

    }
  }
  video_id:any;
  video_user_id:any;
  comment_id:any;
  mainIndex:any;
  togglecomments(id, user_id, i){
    this.mainIndex = i;
   this.video_id = id;
   this.video_user_id = user_id;
  }
  replyscomm(id, i){
    console.log(i)
   this.comment_id = id;
  }

  commetSent(index){
    const data = {
      video_id:this.video_id,
      video_user_id:this.video_user_id,
      comment_content : this.commentsForm.value.comment_content,
      comment_id:this.comment_id
    }
    this.galleryService.commentsvideo(data).subscribe(res=>{
      this.getallSwingersViders()
    })
  }


  reycommetSent(){
    const data = {
      video_id:this.video_id,
      video_user_id:this.video_user_id,
      comment_content : this.repcommentsForm.value.comment_content,
      comment_id:this.comment_id
    }
    this.galleryService.commentsvideo(data).subscribe(res=>{
      this.getallSwingersViders()
    })
  }


  videoLike(id: any, index: number){
    const data = {
     'video_id':id
    };
    this.galleryService.videoLike(data).subscribe(res => {
      this.swingers[index].totallikes = res.totalLikes;
    });
  }

  commentLike(id: any, index: number){
    const data = {
      comment_id: id
    };
      this.galleryService.vcommentlike(data).subscribe(res => {
        this.swingers[this.mainIndex].comment[index].totalLikes = res.data;
      },(error)=>{
      });

  }

  commentRepLike(id: any, index: number, ind){
    const data = {
      comment_id: id
    };
      this.galleryService.vcommentlike(data).subscribe(res => {
        this.swingers[this.mainIndex].comment[ind].data[index].totalLikes = res.data;
      },(error)=> {
      });
  }

}
