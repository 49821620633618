<app-header></app-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center mt-4 text-light bar_bottom">Register Account</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mt-5">
                <h4 class="text-light">Account Information</h4>
                <div class="card set_outline text-light bg-dark">
                    <div class="card-body">
                        <!-- <img src="{{sitelogo}}" class="img-fluid logo_cu"> -->
                        <form [formGroup]="registerForm" (ngSubmit)="registerUser()">
                            <h1 class="text-center titles"><i class="fas fa-user-plus" style="color: #ffc107;"></i>
                                &nbsp;Register</h1>
                            <!-- <h1 class="text-center"><i class="fas fa-user-plus"></i></h1>
                            <h3 class="text-center">Register</h3> -->
                            <div class="form-group">
                                <label for="planId">Choose Plan</label>
                                <select formControlName="plan_id" placeholder="Choose a plan" id="exampleFormControlSelect1"
                                    (change)="getFeaturesById();" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.plan_id.errors }" #selectedPlan>
                                    <option *ngFor="let plan of plans" value="{{plan.id}}">{{plan.plan_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.plan_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.plan_id.errors.required">Please Choose a plan</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="username">Profile Name</label>
                                        <input type="text" placeholder="Profile Name" formControlName="name"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Display is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="text" formControlName="email" placeholder="email"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <label for="address">Address</label>
                                    <app-search-place [mapShow]="mapShow" (mapData)="getlatitudeLongitude($event)">
                                    </app-search-place>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="password">Password</label>
                                        <input [type]="hide ? 'password' : 'text'" placeholder="Password"
                                            autocomplete="false" formControlName="password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                        <span class="showhide">
                                            <a (click)="hide = !hide"> <i class="fas fa-eye-slash" *ngIf="hide"></i>
                                                <i class="fas fa-eye" *ngIf="!hide"></i></a>
                                        </span>
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.pattern">Password Minimum eight characters, at
                                                least one uppercase letter, one lowercase letter, one number and one
                                                special character</div>

                                            <div *ngIf="f.password.errors.required">Password is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="c_password">Confirm Password</label>
                                        <input [type]="hides ? 'password' : 'text'" placeholder="Confirm Password"
                                            autocomplete="false" formControlName="c_password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.c_password.errors }" />
                                        <span class="showhide">
                                            <a (click)="hides = !hides"> <i class="fas fa-eye-slash" *ngIf="hides"></i>
                                                <i class="fas fa-eye" *ngIf="!hides"></i></a>
                                        </span>
                                        <div *ngIf="submitted && f.c_password.errors" class="invalid-feedback">
                                            <div *ngIf="f.c_password.errors.required">Confirm password is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="email">At least 18 year old ?</label>
                                        <select formControlName="dob" placeholder="At least 18 year old ?"
                                            class="form-control" (change)="checkAgeStatus()"
                                            [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">
                                            <option value="1" selected>Yes</option>
                                            <option value="0">No</option>
                                        </select>
                         
                                        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                            <div *ngIf="f.dob.errors.required">Age status is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="genders">Account type</label>
                                        <select formControlName="gender" placeholder="Account type" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                            <option *ngFor="let gender of genders" value="{{gender.value}}">
                                                {{gender.title}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                            <div *ngIf="f.gender.errors.required">Account type is required</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="terms"><input type="checkbox" formControlName="terms"
                                        (change)="termsOnChange();"
                                        [ngClass]="{ 'is-invalid': submitted && f.terms.errors }" required="required" />
                                    I agree with <a href="javascript:void();" (click)="showTerms();">terms &
                                        conditions.</a></label>

                                <div *ngIf="checkTermsCondition">
                                    <div class="text-danger">Terms & conditions is required</div>
                                </div>
                            </div>


                            <button [disabled]="loading" class="btn btn-danger border-radius-0">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                               <span *ngIf="!loading"> Submit and Confirm »</span>
                               <span *ngIf="loading">Please Wait</span>
                            </button>
                            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mt-5">
                <h4 class="text-light">Membership Level</h4>
                <div class="card _checkout_package_info set_outline bg-dark text-light">
                    <div class="card-body">
                        <h4 class="text-light">{{selectedPlanDetails?.plan_name}}</h4>
                        <h4>Price: <span class="text-warning">${{selectedPlanDetails?.price}}</span></h4>
                        <h4>Duration: <span class="text-warning">{{selectedPlanDetails?.duration}}</span></h4>
                        <div class="free-plan" *ngIf="freePlan">
                            <p>You have selected the Free Profile membership level.</p>
                            <p>Due to new laws enacted by The US Government, we are making some changes to our business
                                model and policies which limits access to our free members. (Members with a provide
                                picture get 10 times more exposure.) The price for
                                membership is <b>$0.00</b> now.</p>
                            <p><b>Do you have a discount code?</b> <a href="javascript:void(0);"> Click here to enter
                                    your discount code.</a></p>
                        </div>

                        <div class="list-wrapper">
                            <h5 *ngIf="planFeatures" class="_feature_list">Feature List</h5>
                            <ul>
                                <li *ngFor="let item of planFeatures">{{item.feature_title}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- show terms popup -->
<ng-template #termsCondition let-close="close">
    <div class="modal-header bg-dark">
        <h5 class="modal-title text-light">Term & conditions</h5>
        <button type="button" class="close" (click)="closeTerms(0)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body bg-dark modal_body_show" id="terms_conditions">
        <div class="row">
            <div class="col-md-12">
                <ol>
                    <li><strong>Introduction</strong></li>
                </ol>
                <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of this
                    website. These Terms will be applied fully and affect to your use of this Website. By using this
                    Website, you agreed to accept all terms
                    and conditions written in here. You must not use this Website if you disagree with any of these
                    Website Standard Terms and Conditions.<br /> Minors or people below 18 years old are not allowed to
                    use this Website.</p>
                <ol start="2">
                    <li><strong>Intellectual Property Rights</strong></li>
                </ol>
                <p>Other than the content you own, under these Terms, swingerflings and/or its licensors own all the
                    intellectual property rights and materials contained in this Website.<br /> You are granted limited
                    license only for purposes of viewing
                    the material contained on this Website.</p>
                <ol start="3">
                    <li><strong>Restrictions</strong></li>
                </ol>
                <p>You are specifically restricted from all of the following</p>
                <ul>
                    <li>publishing any Website material in any other media;</li>
                    <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                    <li>publicly performing and/or showing any Website material;</li>
                    <li>using this Website in any way that is or may be damaging to this Website;</li>
                    <li>using this Website in any way that impacts user access to this Website;</li>
                    <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to
                        the Website, or to any person or business entity;</li>
                    <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in
                        relation to this Website;</li>
                    <li>using this Website to engage in any advertising or marketing.</li>
                </ul>
                <p>Certain areas of this Website are restricted from being access by you and Swingerflings may further
                    restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user
                    ID and password you may have for this
                    Website are confidential and you must maintain confidentiality as well.</p>
                <ol start="4">
                    <li><strong>Your Content</strong></li>
                </ol>
                <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text,
                    images or other material you choose to display on this Website. By displaying Your Content, you
                    grant Swingerflings a non-exclusive, worldwide
                    irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it
                    in any and all media.<br /> Your Content must be your own and must not be invading any third-party’s
                    rights. Swingerflings reserves
                    the right to remove any of Your Content from this Website at any time without notice.</p>
                <ol start="5">
                    <li><strong>No warranties</strong></li>
                </ol>
                <p>This Website is provided “as is,” with all faults, and Swingerflings express no representations or
                    warranties, of any kind related to this Website or the materials contained on this Website. Also,
                    nothing contained on this Website shall
                    be interpreted as advising you.</p>
                <ol start="6">
                    <li><strong>Limitation of liability</strong></li>
                </ol>
                <p>In no event shall Swingerflings, nor any of its officers, directors and employees, shall be held
                    liable for anything arising out of or in any way connected with your use of this Website whether
                    such liability is under contract. Swingerflings,
                    including its officers, directors and employees shall not be held liable for any indirect,
                    consequential or special liability arising out of or in any way related to your use of this Website.
                </p>
                <ol start="7">
                    <li><strong>Indemnification</strong></li>
                </ol>
                <p>You hereby indemnify to the fullest extent Swingerflings from and against any and/or all liabilities,
                    costs, demands, causes of action, damages and expenses arising in any way related to your breach of
                    any of the provisions of these Terms.</p>
                <ol start="8">
                    <li><strong>Severability</strong></li>
                </ol>
                <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions
                    shall be deleted without affecting the remaining provisions herein.</p>
                <ol start="9">
                    <li><strong>Variation of Terms</strong></li>
                </ol>
                <p>Swingerflings is permitted to revise these Terms at any time as it sees fit, and by using this
                    Website you are expected to review these Terms on a regular basis.</p>
                <ol start="10">
                    <li><strong>Assignment</strong></li>
                </ol>
                <p>Swingerflings is allowed to assign, transfer, and subcontract its rights and/or obligations under
                    these Terms without any notification. However, you are not allowed to assign, transfer, or
                    subcontract any of your rights and/or obligations
                    under these Terms.</p>
                <ol start="11">
                    <li><strong>Entire Agreement</strong></li>
                </ol>
                <p>These Terms constitute the entire agreement between Swingerflings and you in relation to your use of
                    this Website, and supersede all prior agreements and understandings.</p>
                <ol start="12">
                    <li><strong>Governing Law &amp; Jurisdiction</strong></li>
                </ol>
                <p>These Terms will be governed by and interpreted in accordance with the laws of the State of New York,
                    and you submit to the non-exclusive jurisdiction of the state and federal courts located in New York
                    for the resolution of any disputes.</p>
                <p>13<strong>. Refund policy</strong></p>
                <p>If for any reason you are not satisfied with the functionality or performance of our site, you may
                    request a refund within 5 days of becoming a member and creating your profile. please use the
                    contact us page to submit your request.</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success btn-sm" (click)="termsOnChange(1);closeTerms();">I agree with term
            & conditions</button>
    </div>
</ng-template>

