<app-swinger-navigation></app-swinger-navigation>
<div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp">
  <div class="card-header text-left">
    <h6><span style="color: yellow;" >Total Swingers Found - </span><span style="color:rgb(116, 230, 116);"> {{ datasource.data.total}} </span>
    </h6>
  </div>
  <div class="card-body over_flow_wrapper_big">
    <div>
      <app-search [swinger]='swinger' class="card bg-dark mb-4 col-sm-12" *ngFor="let swinger of swingers; let i=index" ></app-search>
    </div>



    <div class="card-text text-center" *ngIf="loading">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf=" pagelarg">
    <div class="col-md-12 text-center">
      <nav aria-label="..." class="text-center">
        <ul class="pagination justify-content-center">
          <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
            [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url )"
              [innerText]="link.label"></a></li>
        </ul>
      </nav>
    </div>
  </div> 

<div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
  </div>
</div>




