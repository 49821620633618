import { DataService } from '@app/_services/dataShare';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  constructor(private http: HttpClient , public datashare:DataService) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};


  getPagination(url:string,) {

    console.log(url)
    return this.http.get<any>(`${url}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getPaginationin(url:any,dats:any ) {
    console.log(url,dats)
    var data=JSON.stringify(dats)
    return this.http.post<any>(`${url}`,`${data}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }



}
