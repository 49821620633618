<div class="row g-0" *ngIf="!freeuser">
  <div class="col-12 col-md-4 pr-1">
    <div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp mobilev">
      <div class="card-header text-left">
        <h6>Message
          <span style="float: right; color: #ffc107;font-size: 14px;" (click)="openAddNewHotdateModal()"><i
              class="fa fa-plus" aria-hidden="true"></i> New Message</span>
        </h6>
      </div>
      <div class="card-body over_flow_wrapper_big mesgContainer force-overflow">
        <div class="card mb-2 bg-dark">
          <div class="text-right">
            <span><input class="text" (keyup)="searchInboxdata($event)" placeholder="search swinger message..." #input>
              <i class="fa fa-search icon" style="left: -5px;"></i></span>
          </div>
        </div>
        <div *ngIf="isUserdata">
          <div class="card mb-2 bg-dark" *ngFor="let data of messagesData">
            <div class="row g-0">
              <div class="col-3 pr-1">

                <div class="user_profile_ls active">
                  <img [src]="data?.profile" (click)="gotoprofile(data.user_status, data.receiver_id1)" 
                    onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid">
                </div>
                <i class="fas fa-circle cutom" *ngIf="data?.user_is_online != null"></i>
              </div>
              <div class="col-9 pl-1">
                <div class="card-body" (click)="showmessageusers(data?.receiver_id1)">
                  <h5 class="card-title mb-0"><a>{{data?.name}}</a> <span *ngIf="data?.totalMessage != 0"
                      style="float: right;position: relative;top: 20px;bottom:20px;left: 5px; font-size: 14px;"
                      class="badge-infos"><span style="margin:5px; ">{{data?.totalMessage}}</span></span></h5>
                  <span *ngIf="data?.gender != null"
                    [ngClass]="data?.gender=='f'?'badge-success':''||data?.gender=='m'?'badge-info':''||data?.gender=='c'?'badge-warning':''"
                    class="badge badge-pill  mb-2"
                    style="text-transform: initial;">{{data?.gender|genderLable:data?.gender}}</span>
                </div>
              </div>

            </div>
          </div>



          <div class="card-text text-center" *ngIf="loadingssss">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
          <!-- </div> -->
          <div *ngIf="!isUserdata">
            <p class="text-center">Any user not available here for chat</p>
          </div>

          <div *ngIf="nodatas">
            <p class="text-center"> Any user not available in search list. </p>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- mobile view header -->
  <div class="col-12 col-md-8 pl-1 butonmobile">
    <div class="card-body bg-dark butonmobile mb-1">
      <h6 style="padding: 18px;"><span style="color: #ffc107;font-size: 16px;" (click)="openAddNewHotdateModal()"><i
            class="fa fa-plus" aria-hidden="true"></i> New Message</span>

        <span style="color: #ffc107;float: right;font-size: 14px;" (click)='mobileNav($event);'>
          <i class="fa fa-bars" aria-hidden="true"></i></span>
      </h6>
    </div>
    <!-- start mobile navigation -->
    <div class="justify-end navbarSmallDivecess nav-hide" #mobilenavWr>
      <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation-close">
        <i class="far fa-times-circle text-light" style="font-size:32px"></i>
      </button>

      <ul class="nav" style="margin-top: 1em;">
        <li class="nav-item">
          <span><input class="text" (keyup)="searchInboxdata($event)" placeholder="search swinger message..." #input>
            <i class="fa fa-search icon"></i></span>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto me-0 mt-2 over_flow_wrapper_big mesgContainer force-overflow"
        style="text-align: left;">

        <li class="nav-item mt-2" *ngFor="let data of messagesData">
          <a class="nav-link active" (click)="showmessageusers(data?.receiver_id1)" (click)='mobileNav($event);'>
            <div class="user_profile_ls inline"><img (click)="gotoprofile(data.user_status, data.receiver_id1)"
                [src]="data.profile" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                class="img-fluid">
            </div>
            <i class="fas fa-circle mobicusom" *ngIf="data?.user_is_online != null"></i>
            <div class="inline post">
              <h5 class="card-title mb-0"><a>{{data.name}}</a> <span
                  style="position: relative;top: -5px;left: 5px; font-size: 14px;" class="badge-infos"><span
                    style="margin:5px;" *ngIf="data?.totalMessage != 0">{{data?.totalMessage}}</span></span></h5>
              <span *ngIf="data?.gender != null"
                [ngClass]="data?.gender=='f'?'badge-success':''||data?.gender=='m'?'badge-info':''||data?.gender=='c'?'badge-warning':''"
                class="badge badge-pill  mb-2">{{data?.gender|genderLable:data?.gender}}</span>
            </div>
          </a>
        </li>

        <li *ngIf="!isUserdata">
          <p class="nav-link active">No user is available for chat.</p>
        </li>

        <li *ngIf="nodatas">
          <p class="text-center"> Any user not available in search list. </p>
        </li>
      </ul>
    </div>
  </div>

  <!-- Message Container -->
  <div class="col-12 col-md-8 pl-1">
    <div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp" *ngIf="!notselectusers">
      <div>
        <div class="user-navbars" #userNavds *ngIf="!notselectusers">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">

                <ul class="nav">
                  <li class="nav-item">
                    <a class="nav-link active">
                      <div class="user_profile_ls inline"><img (click)="gotoprofile(userdata.user_status, userdata.id)"
                          [src]="userdata?.profile" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                          alt="user-profile" class="img-fluid"></div>
                      <div class="inline post">
                        <p>{{userdata?.name}}</p>
                        <span *ngIf="userdata?.gender != null"
                          [ngClass]="userdata?.gender=='f'?'badge-success':''||userdata?.gender=='m'?'badge-info':''||userdata?.gender=='c'?'badge-warning':''"
                          class="badge badge-pill  mb-2"
                          style="text-transform: initial;">{{userdata?.gender|genderLable:userdata?.gender}}</span>
                      </div>
                    </a>
                  </li>

                  <span class="example-spacer"></span>

                  <span class="btnspan" (click)="deletechates()"> Clear Chat</span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="card-body scrollbar scrollbar-primary" *ngIf="!notselectusers">
        <!-- <div class="container" #scrollMe [scrollTop]="scrollMe.scrollHeight" (scroll)="onScroll($event)" style="overflow-y: scroll; height: 400px;">
          <div class="message" *ngFor="let message of messages">{{ message }}</div>
        </div> -->
        <div class="mesgContainer force-overflow">
          <div class="padin" id="parentDiv">
            <app-chat [message]='message' *ngFor="let message of allmessages" id="parentDiv"></app-chat>
          </div>
          <!-- <div class="card-text text-center" *ngIf="loadingmsg">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div> -->
          <div *ngIf="nodata" class="text-center">
            <p>no message available here</p>
          </div>
        </div>
      </div>

     <div *ngIf="userblock">
      <div class="card-footer" *ngIf="!notselectusers" >
        <form [formGroup]="message" (ngSubmit)="onSubmit()" >
          <div class="input-group mb-3">
            <!-- <div class="input-group-prepend">
              <span class="input-group-text" (dblclick)="toggled = !toggled">
                <i  [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                  (emojiPickerSelect)="handleSelection($event)" class="fa fa-smile-o" aria-hidden="true"
                  style="color: #ffc107;"></i>
              </span>
            </div> -->
            <input [(ngModel)]="messagess" class="form-control hei" formControlName="messagess"
              placeholder="message...." required>
            <!-- </textarea> -->
            <div class="input-group-append" >
              <span class="input-group-text">
                <button style="border: none;" [disabled]="!message.valid" > <i class="fas fa-paper-plane"></i> </button>
              </span>
            </div>
          </div>
        </form>
      </div>
     </div>

    </div>

    <div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp" *ngIf="notselectusers">
      <div class="text-center">
        <h5 style="padding: 50px;">Please select user and start chat</h5>
      </div>
    </div>
  </div>
</div>

<div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp mobilev" *ngIf="freeuser">
  <div class="mt-4">
    <app-subscribe-plan></app-subscribe-plan>
  </div>
</div>

<!-- models  starts-->
<section>
  <ng-template #SentMessageModalContent let-close="close">
    <div class="modal-header bg-light">
      <h5 class="modal-title">Sent Message</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="messagepopup">
            <div class="form-group">
              <input class="form-control" value="{{UserName}}" (keyup)="applyFilter($event)" value="{{name}}"
                placeholder="Enter Swinger Name" #input>
              <div>
                <a class="dropdown-item" *ngFor="let data of swingersData" (click)="selectUser(data.name, data.id)">
                  <div class="row g-0">
                    <div class="col-4 col-md-2 pr-1">
                      <div class="user_profile_ls"><img [src]="data.profile"
                          onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                          class="img-fluid"></div>
                    </div>
                    <div class="col-8 col-md-8 pl-1">
                      <div class="card-body">
                        <h5 class="card-title mb-0"><a>{{data.name}}</a></h5>
                        <span *ngIf="data?.gender != null"
                          [ngClass]="data?.gender=='f'?'badge-success':''||data?.gender=='m'?'badge-info':''||data?.gender=='c'?'badge-warning':''"
                          class="badge badge-pill  mb-2">{{data?.gender|genderLable:data?.gender}}</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div class="form-group">
              <textarea class="form-control" class="form-control" formControlName="messagesss" placeholder="message...."
                id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            <div class="text-left">
              <!-- <buton class="btn btn-warning" >
                <i (click)="toggled = !toggled"  [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                  (emojiPickerSelect)="handleSelection($event)" class="fa fa-smile-o" aria-hidden="true"
                  ></i>
            </buton> -->
              <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary"
                (click)="onSubmitpopup()">
                <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
                <i class="fas fa-paper-plane"></i>Submit
              </button>
              <span style="float: right;color: green;">{{MessagesRes}}</span>
            </div>
          </form>
          <!-- <form [formGroup]="message" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <input class="form-control" value="{{UserName}}" (keyup)="applyFilter($event)" value="{{name}}"
                placeholder="Enter Swinger Name" #input>
              <div>
                <a class="dropdown-item" *ngFor="let data of swingersData" (click)="selectUser(data.name, data.id)">
                  <div class="row g-0">
                    <div class="col-4 col-md-2 pr-1">
                      <div class="user_profile_ls"><img [src]="data.profile" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid"></div>
                    </div>
                    <div class="col-8 col-md-8 pl-1">
                      <div class="card-body">
                        <h5 class="card-title mb-0"><a>{{data.name}}</a></h5>
                        <span *ngIf="data?.gender != null"
                          [ngClass]="data?.gender=='f'?'badge-success':''||data?.gender=='m'?'badge-info':''||data?.gender=='c'?'badge-warning':''"
                          class="badge badge-pill  mb-2">{{data?.gender|genderLable:data?.gender}}</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="form-group">
              <textarea class="form-control" formControlName="messagess" placeholder="Type Your Message"
                id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            <div class="text-right">
              <button [disabled]="loadings" type="submit" class="btn btn-primary">
                <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
                <i class="fas fa-paper-plane"></i> Submit
              </button>
            </div>
          </form> -->
        </div>
      </div>
    </div>
  </ng-template>
</section>
<!-- models  ends-->