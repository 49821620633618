import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-swinger-navigation',
  templateUrl: './swinger-navigation.component.html',
  styleUrls: ['./swinger-navigation.component.css']
})
export class SwingerNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
