import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private http: HttpClient ) { }

  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

addEventDates(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/add-event`, data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

updateEventDates(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/update-event`,data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getUserEventDates() {
  return this.http.get<any>(`${environment.apiUrl}/get-all-user-event`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getEventDateById(id: number) {
  return this.http.get<any>(`${environment.apiUrl}/get-event/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

deleteEventDate(id: any) {
  return this.http.post<any>(`${environment.apiUrl}/delete-event/${id}`,'', this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

searchEventDate(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/search-hot-dates`, data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getSwingerEventDates() {
  return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-events`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getSwingerEventDateById(id: number) {
  return this.http.get<any>(`${environment.apiUrl}/get-swinger-event/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

searchSwingerEventDate(data) {
  return this.http.post<any>(`${environment.apiUrl}/search-events`, data , this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getTotalActiveEventDate() {
  return this.http.get<any>(`${environment.apiUrl}/total-events`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


}
