

<app-header></app-header>
<section>
  <div class="container-fluid">
    <div class="text-center mt_2 mb_2">
      <h2 class="text_color1"> Swingers</h2>
    </div>
    <div class="container">
      <div class="row">
        <div >
          <div class="col-md-3" *ngFor="let data of searchSwingers; let i=index">
            <div class="card bg_colors cards card-with">
              <div class="imgcontainer">
                <img class="card-img-top" [src]="data?.Profile"
                  onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="Card image cap">
              </div>
              <div class="card-body">
                <div>
                  <p class="textName text-center"> {{data?.name}}</p>
                </div>
                <div class="inline">
                  <p *ngIf="data?.gender=='f'" class="textGender"><i class="fas fa-female"></i> Female</p>
                  <p *ngIf="data?.gender=='m'" class="textGender"><i class="fas fa-male"></i> Male</p>
                  <p *ngIf="data?.gender=='c'" class="textGender"><i class="fas fa-user-friends"></i> Couple</p>
                </div>
                <div class="inline" style="float :right">
                  <p class="textGender"><i class="fas fa-map-marker-alt"></i> {{data?.distance}} KM</p>
                </div>

              </div>
              <button class="btn btn-warning" (click)="gotouserinfo(data?.id)"><i class="fas fa-eye"></i> Profile
              </button>
            </div>

          </div>
        </div>

        <!-- <div class="col-md-12" *ngIf="!isData">
          <div class="noDataImg">
            <img src="../../../assets//images/hottestSwingers/no-data-found.png">
          </div> -->
        <!-- </div> -->



      </div>
    </div>

  </div>
</section>