
  <div class="table-responsive">
    <table class="table table-dark table-striped">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Title</th>
          <th>Short Description</th>
          <th>Long Description</th>
          <th>Address</th>
          <th>Starts at</th>
          <th>Ends at</th>
          <th>Remove</th>
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let eventDetail of eventDateList;let i=index">
          <td>{{i+1}}.</td>
          <td style="max-width:250px;" class="text-light ">
            {{eventDetail?.event_name}}
          </td>
          <td style="max-width:300px;" class="text-light">
            {{eventDetail?.sort_description}}
          </td>
          <td style="max-width:300px;" class="text-light">
            {{eventDetail?.long_description}}
          </td>
          <td style="max-width:300px;" class="text-light">
            {{eventDetail?.address}}
          </td>

          <td class="text-light">
            {{eventDetail?.start_date}}
          </td>
          <td class="text-light">
            {{eventDetail?.end_date}}
          </td>
          <td class="text-light">
            <button class="btn btn-sm btn-success " (click)="openUpdateEventDateModal(i, eventDetail?.id)">
                <i class="fas fa-edit"></i>
            </button>

            <button class="btn btn-sm btn-danger ml-1" (click)="deleteEventDate(i,eventDetail?.id)">
             <i class="fas fa-trash-alt"></i>
            </button>
            
          </td>
        </tr>
      </tbody>
    </table>
  </div>


    <!-- update hotdates -->
    <ng-template #updateHotDate  let-close="close">
      <div class="modal-header bg-light" >
        <h5 class="modal-title">Update Event</h5>
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body bg-light" >
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="addEventDateForm" (ngSubmit)="updateEventDates(eventDateUpdateIndex, eventAddress)">
              <div class="mb-3">
                <input type="text" class="form-control" formControlName="event_name" id="event_name" placeholder="Event Name">
              </div>
              <div *ngIf="addressStatus">
                <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
              </div>
                <div *ngIf="!addressStatus">
                 <label>Current Address : </label><span class="text-success ml-2" style="font-size:20px" (click)="updateAddress();"><i class="fas fa-pen-square"></i></span>
                 <h6 class="text-dark">{{eventAddress}}</h6>
              </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <!-- <input type="text" class="form-control" formControlName="start_date" id="start_date" placeholder="Start Date" /> 
                   [(ngModel)]="event.start"
                  -->
                  <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  formControlName="start_date"
                  [enableTime]="false"
                  dateFormat="Y-m-d"
                  placeholder="Start Date"
                  [options]="options"
                />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <input
                  class="form-control"
                  type="text"
                  formControlName="end_date"
                  mwlFlatpickr
                  [enableTime]="false"
                  dateFormat="Y-m-d"
                  placeholder="End Date"
                  [options]="options"
                />
                  <!-- <input type="text" class="form-control"  id="end_date" placeholder="End Date"> -->
                </div>
              </div>
            </div>

            <div class="mb-3">
              <input type="text" class="form-control" formControlName="sort_description" id="sort_discription" placeholder="Sort Description">
            </div>
              
            <div class="mb-3">
              <textarea type="text" class="form-control" formControlName="long_description" id="log_discription" placeholder="Description"> </textarea>
            </div>
            
          <div class="mb-3">
          <p class="text-right"><button type="submit" class="btn btn-primary" [disabled]="!addEventDateForm.valid">
            <span *ngIf="addEventLoader" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!addEventLoader">Event Update</span>
            <span *ngIf="addEventLoader">Please Wait</span></button></p>
            </div>
          </form>
      </div>
    </div>
      </div>
    </ng-template>