import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

getallunreadinbox():Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/get-total-unseen-message`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getallusermessage():Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/get-user-message`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


getallmessage(id):Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/get-all-message/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


sendmessage(body) :Observable<any>{
  return this.http.post<any>(`${environment.apiUrl}/send-message`,body, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

clearAllmessage(id):Observable<any> {
  return this.http.get<any>(`${environment.apiUrl}/clear-chat/${id}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

searchInboxList(body):Observable<any>{
 return this.http.post(`${environment.apiUrl}/search-user-inbox`, body, this.httpOptions).pipe(
   retry(1),
   catchError(this.handleError)
 );
}
}