import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { SwingersRoutingModule } from './swingers-routing.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { SharingModuleModule } from '../Sharing/sharing-module.module';
import { SwingerFriendsComponent } from './swinger-friends/swinger-friends.component';
import { SwingerVideosComponent } from './swinger-videos/swinger-videos.component';
import { SwingerGroupsComponent } from './swinger-groups/swinger-groups.component';
import { SwingerEventsComponent } from './swinger-events/swinger-events.component';
import { SwingersWrapperComponent } from './swingers-wrapper/swingers-wrapper.component';
import { SwingerInfoComponent } from './swinger-info/swinger-info.component';
import { SwingerSideBarComponent } from './swinger-side-bar/swinger-side-bar.component';
import { SwingerProfileNavigationComponent } from './swinger-profile-navigation/swinger-profile-navigation.component';
import { FriendListComponent } from './swinger-friends/friend-list/friend-list.component';
import { SwingerHotDateViewComponent } from './swinger-hot-date-view/swinger-hot-date-view.component';
import { SwingerTimelineComponent } from './swinger-timeline/swinger-timeline.component';
import { SwingerAlbumsComponent } from './swinger-albums/swinger-albums.component';
import { VgCoreModule, } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { SwingerAudioComponent } from './swinger-audio/swinger-audio.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    SwingerFriendsComponent,
    SwingerVideosComponent,
    SwingerGroupsComponent,
    SwingerEventsComponent,
    SwingersWrapperComponent,
    SwingerInfoComponent,
    SwingerSideBarComponent,
    SwingerProfileNavigationComponent,
    FriendListComponent,
    SwingerHotDateViewComponent,
    SwingerTimelineComponent,
    SwingerAlbumsComponent,
    SwingerAudioComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharingModuleModule,
    SwingersRoutingModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    FlatpickrModule.forRoot(),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ]
})
export class SwingersModule { }
