import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { GroupServiceService } from "@app/_services";

@Component({
  selector: 'app-group-right-panel',
  templateUrl: './group-right-panel.component.html',
  styleUrls: ['./group-right-panel.component.css']
})
export class GroupRightPanelComponent implements OnInit {


  defaultProfile = environment.defaultProfile;
  loading = true;
  constructor(private groupService: GroupServiceService) { }
  members: any;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;

  @Input() groupId: number;

  ngOnInit(): void {
    console.log(this.groupId);
    this.getGroupMember(this.groupId);
  }

  getGroupMember(id: any){
    this.groupService.getGroupMembersById(id).subscribe(data =>{
      console.log(data);
      this.members = data.data;
     for(let friend in this.members){
      console.log(this.members[friend]);
      this.members[friend].is_profile_pic = `${environment.imgUrl}/${this.members[friend].id}/images/user/profile/${this.members[friend].is_profile_pic}`;
    }
     // `${environment.imgUrl}/${this.friend.id}/images/user/profile/${this.friend.profile}`
      // data.data.forEach(onlineUsers => {
      // this.defaultProfile = (onlineUsers.profile) ? `${environment.imgUrl}/${this.userId}/images/user/profile/${onlineUsers.profile}`: this.defaultProfile;
      // });
    
      this.loading = false;
    },error=>{
      console.log(error);
    });
  }
}

