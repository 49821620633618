
   <div class="right_navigation_panel"> 
      <div class="card bg-dark text-light mb-3">
        <div class="card-header"><h4 class="fw-bold">Group Members</h4></div>
        <div class="card-body over_flow_wrapper">
          <div class="card mb-2 bg-dark" *ngFor="let friend of members">
            <div class="row g-0">
              <div class="col-3 pr-1">
                  <div class="user_profile_ls active"><img src="{{friend?.is_profile_pic?friend?.is_profile_pic:defaultProfile}}" alt="user-profile" class="img-fluid"></div>
              </div>
              <div class="col-9 pl-1">
                <div class="card-body">
                  <h5 class="card-title"><a routerLink="/{{friend.id}}/swinger/profile">{{friend.name}} {{friend.last_name}}</a></h5>
                  <p class="card-text text-muted">{{friend.short_tag_line}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-text text-center" *ngIf="loading">
            <div class="spinner-border text-light" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
        </div>
      </div>

   

    </div>


