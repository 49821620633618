<app-dashboard-header></app-dashboard-header>
<div *ngIf="defaultNavigation" >
<app-user-navigation></app-user-navigation>
</div>

<div class="defualt_bg"  *ngIf="emailStatus && planStatus">
    <div *ngIf="defaultNavigation; else fullView" class="container">
       <div class="row">
           <div [class]="cols">
            <router-outlet></router-outlet>
           </div>
           
           <div *ngIf="defaultNavigation" class="col-md-4 m-screen">
            <app-right-navigation-panel *ngIf="planInvoce"></app-right-navigation-panel>
           </div>

       </div>
    </div>


    <ng-template #fullView>
        <div class="container-fluid">
            <div class="row">
           <div class="col-md-12">
           <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </ng-template>
</div>
