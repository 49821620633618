<div *ngIf="!uiHide">
    <div class="row g-0">
      <div class="col-4 col-md-2 pr-1">
        <div class="user_profile_ls"><img (click)="userValidnav(swinger?.id)"  [src]="swinger?.profile"
            onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid"></div>
      </div>
  
      <div class="col-8 col-md-8 pl-1">
        <div class="row"> 
          
          <div class="col-md-8 col-12">
            <div class="card-body">
              <h5 class="card-title mb-0"><a (click)="userValidnav(swinger?.id)"
                  (click)="setProfileViews(swinger?.id)">{{swinger?.name}}</a></h5>
              <span *ngIf="swinger?.gender != null"
                [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''"
                class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
  
              <!-- <p class="card-text text-muted">{{swinger?.short_tag_line}}</p> -->
            </div>
          </div>
          <div class="col-md-4 col-12 m-flex-v">
            <p *ngIf="successMsg" class="text-small text-success">{{swingerStatus}} </p>
            <div class="btn-group mt-3 cm-m-btn" *ngIf="swinger.friendStatus == 'not firends'">
              <button type="button" data-toggle="tooltip" data-placement="top" title="Friend Request Sent"
                class="btn btn-info" (click)="checkUserPlan(swinger.id)" (click)='sendFriendRequest(swinger.id)'>
                <div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden"></span>
                </div> <i *ngIf='!requestLoding' class="fas fa-user-plus"> </i>
              </button>
              <a class="btn btn-warning" data-toggle="tooltip" data-placement="top" title="Send Message"
                (click)="openAddNewHotdateModal(swinger.id,swinger.name)"><i class="fa fa-comments"></i></a>
              <a class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="View profile"
                 (click)="setProfileViews(swinger?.id)"><i
                  class="fas fa-eye"></i></a>
            </div>
            <div class="btn-group mt-2 mb-2" *ngIf="swinger.friendStatus != 'not firends'">
              
              <button disabled *ngIf="swinger?.friendStatus == 'send firends request'" class="btn btn-success mt-1">
                <p style="margin-bottom: 0px;color: white;"><i class="fas fa-user-check"></i> Sent</p>
              </button>
              <a *ngIf="swinger?.friendStatus == 'send firends request'" class="btn btn-danger mt-1" data-toggle="tooltip" data-placement="top" title="Cancel Friend Request"
                (click)="cancelRequest(swinger.id,swinger.name)">
                <div *ngIf='btnloading' class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden"></span></div>
                <i *ngIf='!btnloading' class="fas fa-user-times" style="font-size: 14px;"></i>
              </a>
                
              <a readonly *ngIf="swinger?.friendStatus == 'Block'" class="btn btn-danger mt-3"> Blocked</a>
              <a readonly *ngIf="swinger?.friendStatus == 'firends'" class="btn btn-primary mt-3"> Friends</a>
            </div>
          </div>
        </div>
  
      </div>
  
  
    </div>
  </div>
  
  
  
  
  <ng-template #SentMessageModalContent let-close="close">
    <div class="modal-header bg-light">
      <h5 class="modal-title">Sent Message</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="row">
        <div class="col-md-12">
  
          <form [formGroup]="message">
            <div class="form-group">
              <input class="form-control" [value]="UseName" placeholder="name">
            </div>
  
            <div class="form-group">
              <textarea class="form-control" [(ngModel)]="messagess" class="form-control" formControlName="messagess"
                placeholder="message...." id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            <div class="text-left">
              <!-- <buton class="btn btn-warning" >
                <span class="input-group-text">
                <i (click)="toggled = !toggled" [(emojiPickerIf)]="toggled" [emojiPickerDirection]="'top'"
                  (emojiPickerSelect)="handleSelection($event)" class="fa fa-smile-o" aria-hidden="true"
                  ></i>
              </span>
            </buton> -->
              <button style="margin-left: 2px;" [disabled]="loadings" type="submit" class="btn btn-primary"
                (click)="onSubmit()">
                <span *ngIf="loadings" class="spinner-border spinner-border-sm mr-1"></span>
                <i class="fas fa-paper-plane"></i>Submit
              </button>
              <span style="float: right;color: green;">{{MessagesRes}}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
