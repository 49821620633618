import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service';
import { DataService } from "../../_services/dataShare";
@Component({
  selector: 'app-message-header',
  templateUrl: './message-header.component.html',
  styleUrls: ['./message-header.component.css']
})
export class MessageHeaderComponent implements OnInit {
  emailStatus = true;
  Datas: any;
@ViewChild('mobilenavWr') elRef: ElementRef;
  @ViewChild('userNavds') userNavds : ElementRef;
  constructor(
   private authenticationService: AuthenticationService,
   private renderer: Renderer2,
   private formBuilder: FormBuilder,
   private route: ActivatedRoute,
   private router: Router,
   private DataService: DataService,
   ) { 
   
   this.authenticationService.currentUser.subscribe(x => {
     
     if(x.email_status!=1){
       this.emailStatus = false;
     }
   })
   this.Datas = this.DataService.getOption();
   console.warn(this.Datas)
  }

ngOnInit(): void {
}

scrollTopUserNav(event:any){
console.log(event);
}



// @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
//   if(window.pageYOffset > 250){
//    this.renderer.addClass(this.userNavds.nativeElement,'fiexd-user-navs');
//   }else{
//    this.renderer.removeClass(this.userNavds.nativeElement,'fiexd-user-navs');
//   }
// }

checkNav = 0;
checkDiviceWidth = window.innerWidth;
mobileNav(event: any) {
  if (this.checkNav == 0) {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
      this.renderer.addClass(this.elRef.nativeElement, "nav-show");
      this.checkNav = 1;
  } else {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
      this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
      this.checkNav = 0;
  }
}


}
