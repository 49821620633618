<div class="post-panel infinite-loader-sec section-t-space">
    <div class="post-wrapper col-grid-box section-t-space">
        <div class="post-title mb-1">
            <div class="profile">
                <div class="media nav-itemss dropdown">
                    <a class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="true">
                        <div class="user_profile_ls">
                            <img [src]="swinger?.profilepic"
                                onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                                class="img-fluid">
                        </div>
                    </a>

                    <a class="dropdown-menu" aria-labelledby="user_profile">
                        <li><a class="dropdown-item"><i class="fa fa-user" aria-hidden="true"></i> Profile</a></li>
                        <li><a class="dropdown-item"><i class="fa fa-envelope-o" aria-hidden="true"></i> Message</a>
                        </li>
                    </a>
                    <div class="media-body">
                        <div class="media-cont">
                            <h5>{{swinger?.username}}</h5>
                            <h6>{{swinger?.created_at}}</h6>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div class="post-details">
            <div class="img-wrapper">
                <div style="width:100%;height:0;padding-bottom:45%;position:relative;">
                    <video width="100%" height="300px" controls autoplay>
                        <source src="{{swinger.src}}" type="video/mp4">
                        <source src="{{swinger.src}}" type="video/ogg">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
            <div class="detail-box">
                <div class="like-panel mt-2">
                    <div class="right-stats">
                        <p class="text-left">
                            <span>
                                <i class="fa fa-thumbs-up"
                                    aria-hidden="true"></i><span>&nbsp;{{swinger?.totallikes}}</span> like
                            </span>&nbsp;
                            <span>
                                <i class="fa fa-comment" aria-hidden="true"></i><span>&nbsp;4565</span> comment
                            </span>

                        </p>
                    </div>
                </div>

                <div class="comment-section">
                    <div class="comments d-none">
                        <div class="ldr-comments">
                            <ul>
                                <li>
                                    <div class="media">
                                        <div class="ldr-img"></div>
                                        <div class="media-body">
                                            <div class="contents">
                                                <div class="ldr-content"></div>
                                                <div class="ldr-content"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="sub-comment">
                                        <li>
                                            <div class="media">
                                                <div class="ldr-img"></div>
                                                <div class="media-body">
                                                    <div class="contents">
                                                        <div class="ldr-content"></div>
                                                        <div class="ldr-content"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div class="media">
                                        <div class="ldr-img"></div>
                                        <div class="media-body">
                                            <div class="contents">
                                                <div class="ldr-content"></div>
                                                <div class="ldr-content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="media">
                                        <div class="ldr-img"></div>
                                        <div class="media-body">
                                            <div class="contents">
                                                <div class="ldr-content"></div>
                                                <div class="ldr-content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="main-comment">
                            <div class="media">
                                <a href="#" class="user-img popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="Pabelo mukrani" data-img="../assets/images/user-sm/2.jpg">
                                    <img src="../assets/images/user-sm/2.jpg" class="img-fluid blur-up lazyload bg-img"
                                        alt="user">
                                </a>
                                <div class="media-body">
                                    <a href="#">
                                        <h5>Pabelo Mukrani</h5>
                                    </a>
                                    <p>Oooo Very Cute and Sweet Dog, happy birthday Cuty....
                                        &#128578;
                                    </p>
                                    <ul class="comment-option">
                                        <li><a href="#">like (5)</a></li>
                                        <li><a href="#">reply</a></li>
                                        <li><a href="#">translate</a></li>
                                    </ul>
                                </div>
                                <div class="comment-time">
                                    <h6>50 mins ago</h6>
                                </div>
                            </div>
                            <div class="sub-comment">
                                <div class="media">
                                    <a href="#" class="user-img popover-cls" data-bs-toggle="popover"
                                        data-placement="right" data-name="sufiya elija"
                                        data-img="../assets/images/user-sm/3.jpg">
                                        <img src="../assets/images/user-sm/3.jpg"
                                            class="img-fluid blur-up lazyload bg-img" alt="user">
                                    </a>
                                    <div class="media-body">
                                        <a href="#">
                                            <h5>sufiya elija</h5>
                                        </a>
                                        <p>Thank You So Much ❤❤</p>
                                        <ul class="comment-option">
                                            <li><a href="#">like</a></li>
                                            <li><a href="#">reply</a></li>
                                            <li><a href="#">translate</a></li>
                                        </ul>
                                    </div>
                                    <div class="comment-time">
                                        <h6>50 mins ago</h6>
                                    </div>
                                </div>
                                <div class="media">
                                    <a href="#" class="user-img popover-cls" data-bs-toggle="popover"
                                        data-placement="right" data-name="sufiya eliza"
                                        data-img="../assets/images/user-sm/4.jpg">
                                        <img src="../assets/images/user-sm/4.jpg"
                                            class="img-fluid blur-up lazyload bg-img" alt="user">
                                    </a>
                                    <div class="media-body">
                                        <a href="#">
                                            <h5>sufiya elija</h5>
                                        </a>
                                        <p>Thank You So Much ❤❤</p>
                                        <ul class="comment-option">
                                            <li><a href="#">like</a></li>
                                            <li><a href="#">reply</a></li>
                                            <li><a href="#">translate</a></li>
                                        </ul>
                                    </div>
                                    <div class="comment-time">
                                        <h6>50 mins ago</h6>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" class="loader">
                                    <i class="iw-15 ih-15" data-feather="rotate-cw"></i> load more
                                    replies
                                </a>
                            </div>
                        </div>
                        <div class="main-comment">
                            <div class="media">
                                <a href="#" class="user-img popover-cls" data-bs-toggle="popover" data-placement="right"
                                    data-name="pabelo mukrani" data-img="../assets/images/user-sm/2.jpg">
                                    <img src="../assets/images/user-sm/2.jpg" class="img-fluid blur-up lazyload bg-img"
                                        alt="user">
                                </a>
                                <div class="media-body">
                                    <a href="#">
                                        <h5>Pabelo Mukrani</h5>
                                    </a>
                                    <p>It’s party time, Sufiya..... and happy birthday cuty 🎉🎊</p>
                                    <ul class="comment-option">
                                        <li><a href="#">like</a></li>
                                        <li><a href="#">reply</a></li>
                                        <li><a href="#">translate</a></li>
                                    </ul>
                                </div>
                                <div class="comment-time">
                                    <h6>50 mins ago</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="reply">
                        <div class="search-input input-style input-lg icon-right">
                            <input type="text" class="form-control emojiPicker" placeholder="write a comment..">
                            <a href="javascript:void(0)">
                                <i data-feather="smile" class="icon icon-2 iw-14 ih-14"></i>
                            </a>
                            <a href="javascript:void(0)">
                                <i class="iw-14 ih-14 icon" data-feather="camera"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>