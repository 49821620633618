<section>
    <div class="container-fluid">
        <div class="text-center mt_2 mb_2">
            <h2 class="text_color1">Create and jion some of the</h2>
            <h2 class="text_color2">Hottest Group Around</h2>
            <p>Swinger Flings turning fantasies into reality!</p>
        </div>
        <div class="container-fluid">
            <div class="row main_banner_row ">
                <div class="col-md-6">
                    <div class="imgcontgroup">
                        <img src="../../../assets/images/hottestSwingers/Hottest/6" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="Card image cap">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 p-0">
                                <ul class="nav">
                                    <li class="nav-item">
                                      <a class="btn btn-light " routerLink="/"  routerLinkActive="active"> POPULAR</a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="btn btn-light me-4" routerLink="/" routerLinkActive="active"> NEWEST</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="btn btn-light me-4" routerLink="/" routerLinkActive="active"> ACTIVE</a>
                                      </li>
                                      <li class="nav-item">
                                        <a class="btn btn-light me-4" routerLink="/" routerLinkActive="active"> NEWEST</a>
                                      </li>
                                   
                                   
                                  </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row  mt_2">
                        <div class="col-sm-6">
                            <div class="card text-center bg_colors cards card-with">
                                <div class="imgcontainer">
                                    <img class="card-img-top" src="../../../assets/images/hottestSwingers/Hottest/2"
                                        onerror="this.src='../../../assets/images/avtar-image.jpg';"
                                        alt="Card image cap">
                                </div>
                                <div class="card-body">
                                    <p class="textName">LIAM</p>
                                    <p class="textGender">14 Members</p>
                                </div>

                            </div>

                        </div>

                        <div class="col-sm-6 ">
                            <div class="card text-center bg_colors card-with">
                                <div class="imgcontainer">
                                    <img class="card-img-top" src="../../../assets/images/hottestSwingers/Hottest/4"
                                        onerror="this.src='../../../assets/images/avtar-image.jpg';"
                                        alt="Card image cap">
                                </div>
                                <div class="card-body">
                                    <p class="textName">JAMES</p>
                                    <p class="textGender">20 Members</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card text-center bg_colors card-with">
                                <div class="imgcontainer">
                                    <img class="card-img-top" src="../../../assets/images/hottestSwingers/Hottest/3"
                                        onerror="this.src='../../../assets/images/avtar-image.jpg';"
                                        alt="Card image cap">
                                </div>
                                <div class="card-body">
                                    <p class="textName">JAMES</p>
                                    <p class="textGender">20 Members</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card text-center bg_colors card-with">
                                <div class="imgcontainer">
                                    <img class="card-img-top" src="../../../assets/images/hottestSwingers/Hottest/1"
                                        onerror="this.src='../../../assets/images/avtar-image.jpg';"
                                        alt="Card image cap">
                                </div>
                                <div class="card-body">
                                    <p class="textName">JAMES</p>
                                    <p class="textGender">20 Members</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row ">
          <div class="col-md-12">
            <div class="text-center mtb_15">
              <button class="btn btn-warning" style="border-radius: 5px;"> Discover ALL Groups </button>
            </div>
          </div>
  
        </div>
      </div>
</section>


