import { Component, OnInit, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, CommonService, GalleryService} from '@app/_services';
import Swal from 'sweetalert2/dist/sweetalert2.js';  
import { environment, Popup } from '@environments/environment';
import { Lightbox } from 'ngx-lightbox';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsServices } from '../../../_services/notifications.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-user-videos',
  templateUrl: './user-videos.component.html',
  styleUrls: ['./user-videos.component.css']
})

export class UserVideosComponent implements OnInit {
videoUrls:any;
newVideoUrls:any
IDs:any;
freeuser = false;
currentUser:any;
progress: number = 0;
videoPlayIcon = '../../../../assets/images/play-button.png';

  constructor(
    private fb: FormBuilder, 
    private galleryService: GalleryService, 
    private _lightbox: Lightbox,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private ActivatedRoute:ActivatedRoute,
    private NotificationsServices:NotificationsServices,
    private authenticationService: AuthenticationService,
    private modal: NgbModal,
    private titleService:Title 
    ) { 
      this.titleService.setTitle('Videos | Swingerflings')
      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
        if(this.currentUser.plan_id == 1){
          this.freeuser = true;
        }
    });
  }
  

  submitted = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  defaultProfile = environment.defaultProfile;
  loading = false;
  ifSuccess = false;
  message: string;
  error = '';
  videosList: FormGroup;
  openModel = false;
  gallaryVideos = [];
  galleryFiles = [];
  videosGallery: any;
  privacyMode = true;
  loadingPrivacyStatus = false;
  currentIndex: number;
  api;
  currentItem;
  videoStatus = false;
  pagination:any;
	checkpages = false;
  singleVideo:any;



ngOnInit(): void {
  this.videosList = this.fb.group({
    userID: [this.userId],
    privacy: ['', Validators.required],
    videos: [null, [Validators.required]],
  });
  this.getVideos(this.userId);
}

@ViewChild('showVideo', { static: false }) showVideo: TemplateRef<any>;

get f() { return this.videosList.controls; }

loadVideo(files: File){
    if(files){
    for(let file in files){
      let reader = new FileReader();
      const checkFileSize = this.checkImageSize(files[file]);
      if(checkFileSize){
        this.galleryFiles.push(files[file]); 
        reader.readAsDataURL(files[file]);
        reader.onload = (_event) => {
          this.gallaryVideos.push(reader.result);
          }
      }
      }
      }
}

deleteone(i){
  delete this.gallaryVideos[i];
  this.gallaryVideos.splice(i, 1)
  delete this.galleryFiles[i];
  this.galleryFiles.splice(i, 1)
  console.log(this.galleryFiles)

  if(this.galleryFiles.length == 1 || this.gallaryVideos.length == 1 ){
    this.videosList.reset();
    this.gallaryVideos = [];
    this.galleryFiles = []
  }
}

checkImageSize(file){
  if(file.size > 50097152){
    Swal.fire(
      `File size error "${file.name}" file greater then 50 MB`,
      `make sure less then 50 MB then upload file.`,
      "error"
    );
   return false;
  }else{
    return true;
  }
}


// getvideoByID(){
//   this.NotificationsServices.getVideoID(this.IDs).subscribe((res)=>{
//     // console.log(res)
//   })
// }


closeEventDateModal(){
  this.modal.dismissAll(this.showVideo);
}


getVideoById(id: number,index: number){
  let ngbModalOptions: NgbModalOptions = {
    backdrop : 'static',
    keyboard : false,
    size: 'lg'
};
  this.modal.open(this.showVideo, ngbModalOptions);
  this.galleryService.getVideoByVideoId(id).subscribe(res=>{
    this.singleVideo = res.data;
    this.singleVideo.index = index;
    this.storeVideoView(id, index);
  },error=>{
    console.log(error);
  })
 
}


storeVideos(){
      this.submitted = true;
      if (this.videosList.invalid) {
        return;
      }
// console.warn(this.videosList.value.privacy)
      this.loading = true;
      const formData = new FormData();
      formData.append('videos', this.galleryFiles[0]);
      formData.append('user_id', this.userId);
      formData.append('privacy', this.videosList.value.privacy);
      console.log(this.galleryFiles[0],this.userId,this.videosList.value.privacy )
      this.galleryService.storeVideos(formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
          Swal.fire(
            'Success',
            `${Popup.VideoUploadSuccess}`,
            'success'
          );
          this.closeCreateAlbumModal();
          this.loading = false;
            setTimeout(() => {
              this.progress = 0;
            }, 1500);
  
        }
        
        this.getVideos(this.userId);
       
       
      }, error => {
        const errorData = JSON.parse(sessionStorage.getItem('error'));
        Swal.fire(
          'Invalid Video',
          `${errorData.errors[0]}`,
          'error'
        );
        this.videosList.reset();
        this.gallaryVideos = [];
        this.loading = false;
        this.submitted = false;
      });

    }


  getVideos(id: any){
      this.galleryService.getVideos(id).pipe(map(res=>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
       return res.data.data;
      })).subscribe(videoResponce => {
        this.videosGallery = videoResponce;
        // console.log(videoResponce)
      });
    }

    activePage(url:string){
      if(url){
        this.commonService.getPagination(url).pipe(map(res=>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
       return res.data.data;
      })).subscribe(videoResponce => {
        this.videosGallery = videoResponce;
      });
    }
    }

    open(index: number): void {
      this._lightbox.open( this.videosGallery, index);
    }

    close(): void {
      this._lightbox.close();
    }

  openCreateAlbumModal(){
    this.openModel = true;
    this.gallaryVideos = [];
  }

  closeCreateAlbumModal(){
    this.gallaryVideos = [];
    this.galleryFiles = [];
    this.openModel = false;
    this.videosList.reset();
  }


  privacyEditMode(data: any){
    this.videosGallery[data].privacyMode = !this.videosGallery[data].privacyMode;
  }


  changeVideoPrivacy(id: any, privacy: any, index: number){
         this.videosGallery[index].privacyMode = false;
         this.videosGallery[index].loadingPrivacyStatus = true;
         const data = {
             'id':id,
             'privacyId':privacy
           };
      this.galleryService.changeVideoPrivacy(data).subscribe(res => {
        this.videosGallery[index].privacy = privacy;
        this.videosGallery[index].loadingPrivacyStatus = false;
      });

  }

  deleteVideo(id: any, index: number){
      Swal.fire({  
        title: `${Popup.Videotitle}`,  
        text: `${Popup.Videotext}`,  
        icon: 'warning',  
        showCancelButton: true,  
        confirmButtonText: 'Yes, delete it!',  
        cancelButtonText: 'No, keep it'  
      }).then((result) => {  
        if (result.value) {
           this.videosGallery[index].deleteLoadingStatus = true;
           this.galleryService.deleteVideo(id).subscribe(res => {
           this.videosGallery[index].deleteLoadingStatus = false;
           Swal.fire(  
            'Deleted!',  
            `${Popup.VideoDeleted}`,  
            'success'  
          )
          this.getVideos(this.userId);
          },error=>{
            Swal.fire(  
              'Server Error',  
              `${Popup.error}`,  
              'error'  
            )
          });
            
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
          Swal.fire(  
            'Cancelled',  
            `${Popup.VideoSafe}`,  
            'error'
          )
        }
      })
  }

  videoLike(id: any, index: number){
    const data = {
     'video_id':id
    };
    this.galleryService.videoLike(data).subscribe(res => {
      this.videosGallery[index].totallikes = res.totalLikes;
    });
  }


  onPlayerReady(api){
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }

  nextVideo(){
    this.currentIndex++;
    if (this.currentIndex === this.videosGallery.length) {
      this.currentIndex = 0;
    }

    this.currentItem = this.videosGallery[this.currentIndex];
  }

  playVideo() {
    this.api.play();
  }

  onClickPlaylistItem(itemId, index: number) {
    this.currentIndex = index;
    this.currentItem = itemId;
    this.storeVideoView(itemId, index);
  }

  storeVideoView(id: number,index: number){
    if(!this.videoStatus){
      const data = {
        'video_id':id
        };
    this.galleryService.storeVideoViews(data).subscribe(res => {
      this.videosGallery[index].totalviews = res.views;
    });
        this.videoStatus = true;
    }else{
      this.videoStatus = false;
    }
    
  }

  ngOnDestroy(): void{
    this.closeCreateAlbumModal();
    this. close();
    this.closeEventDateModal()
  }
}





