import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment, Popup } from '@environments/environment';
import { AuthenticationService, GlobalLocationsService, PlanService, UserMediaService } from '@app/_services';
import { User } from '@app/_models';
import { NotificationsServices } from "../../_services/notifications.service";
import { SharedService } from '../../_services/shared.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NotificationsService } from "../../../app/notifications.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MessagesService } from "../../_services/messages.service";
import { io } from 'socket.io-client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@app/_services/dataShare';
import { SearchService } from "./search";
const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit {
  pagination: any;
  clickEventsubscription: Subscription;
  notificationdata: any = [];
  countnotifinum: any;
  defaultProfile: any;
  siteLogoUrl = environment.siteLogolight;
  currentUser: User;
  username: any;
  isLogin: boolean;
  checkNav = 0;
  checkDiviceWidth = window.innerWidth;
  notifis: boolean = false;
  emailAddress;
  showUnverified = false;
  loading: boolean = true;
  @ViewChild('mobilenavWr') elRef: ElementRef;
  userId: any;
  iconISshow = true;

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
  socket:any;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private NotificationsServices: NotificationsServices,
    private sharedService: SharedService,
    private userMedia: UserMediaService,
    private windownotifiserveice: NotificationsService,
    private MessagesService: MessagesService,
    private planService: PlanService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private SearchService: SearchService,
    private datashare :DataService,
    private globalLocation: GlobalLocationsService
  ) {
   
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser != null) {
        this.username = this.currentUser.name;
        this.isLogin = true;
        this.openNotifi();
        this.userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
      } else {
        this.isLogin = false;
      }
      if (x.email_status != 1) {
        this.iconISshow = false;
      } else {
        this.iconISshow = true;
      }

      if (x != null || x != undefined) {
        this.check();
        this.initListener();
        this.initInterval();
        sessionStorage.setItem(STORE_KEY, Date.now().toString());
      }
    });
    this.userMedia.getProfile().subscribe(res => {
      this.defaultProfile = (res.data.profile) ? res.data.profile : this.defaultProfile;
    });

    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.logout();
    })

    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('my broadcast', (data:any) => {
      if (data) {
         setTimeout(() => {
           this.getallmsg(this.userId);
           this.openNotifi();
          }, 1000);
       }
     });
  }



  validUser = false;
  ngOnInit(): void {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser')).email_status;
    if (currentUser == 1) {
      this.validUser = true
    } else {
      this.validUser = false
    }
    this.getallmsg(this.userId)
    this.getPlan(this.currentUser.plan_id);
    this.planId = this.currentUser.plan_id;

    this.lookingForm = this.formBuilder.group({
      first_gender: ['', Validators.required],
      second_gender:['', Validators.required],
      distanceFrom: ['', ],
      distanceTo: ['', ],
      address:['', ],
      distanceMiles:['',],
      latitude:[''],
      longitude:[''],
  });
  }
  subscribtion:any;
  PlaneName:any;
  planId:any;
  getPlan(id: number){
    this.planService.getPlanById(id).subscribe( res => {
    this.subscribtion = res.data;
    this.PlaneName = this.subscribtion.plan_name
    })
  }



  getUserProfile() { }
  seemoreshow: boolean = false;
  notifiData: any[] = [];
  notification: any[] = [];
  openNotifi() {
    this.NotificationsServices.getnotification().subscribe((res: any) => {
      let countnotifi = res;
      this.countnotifinum = countnotifi.count;
      this.notificationdata = res.data.data;
      this.notifiData.length = 0;
      for (let index = 0; index < this.notificationdata.length; index++) {
        const subject = JSON.parse(this.notificationdata[index].data);
        const sentby = JSON.parse(this.notificationdata[index].data);
        const FriendID = JSON.parse(this.notificationdata[index].data);
        const FriendImage = JSON.parse(this.notificationdata[index].data);
        const requestType = JSON.parse(this.notificationdata[index].data);
        const SubData = JSON.parse(this.notificationdata[index].data);
        const OrderId = JSON.parse(this.notificationdata[index].data);
        let data = {
          id: this.notificationdata[index].id,
          img: this.notificationdata[index].profilepic,
          read: this.notificationdata[index].read_at,
          create: this.notificationdata[index].created_at,
          subject: subject.data,
          sent_by: sentby.sent_by,
          friend_user_id: FriendID.id,
          requestType: requestType.requestType,
          OrderId: OrderId.other_id,
          status: this.notificationdata[index].status,
          userStatus: this.notificationdata[index].user_status
        }  
        this.seemoreshow = true;
        this.notifiData.push(data);
        if (this.notificationdata[index].read_at == null || this.notificationdata[index].read_at == "") {
          let datas: Array<any> = [];
          datas.push({
            'title': sentby.sent_by,
            'alertContent': subject.data
          });
          this.windownotifiserveice.generateNotification(datas);
        }
      }
      console.log(this.notifiData)
    })
  }

  acceptrequest(friend_id, requestType, group_id, id) {
    if (requestType == "Friend Request sent") {
      let body = {
        friend_user_id: friend_id,
      }
      this.NotificationsServices.requestaccept(body).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 1) {
          let body = {
            noti_id: id,
            status: 1
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      });

    } else if (requestType == "Sent Group Invitation") {
      let body = {
        group_id: group_id,
        to_user_id: friend_id,
      }
      this.NotificationsServices.groupaccept(body).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 1) {
          let body = {
            noti_id: id,
            status: 1
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      })
    } else {
      return;
    }

  }

  rejectRequest(friend_id, requestType, group_id, id) {
    if (requestType == "Friend Request sent") {
      const data = {
        "to_user_id": friend_id,
      };
      this.NotificationsServices.requestreject(data).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 2) {
          let body = {
            noti_id: id,
            status: 2
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      });

    } else if (requestType == "Sent Group Invitation") {
      let body = {
        group_id: group_id,
        to_user_id: friend_id,
      }
      this.NotificationsServices.groupreject(body).subscribe(res => {
        let resp: any = [];
        resp = res;
        if (resp.status == 2) {
          let body = {
            noti_id: id,
            status: 2
          }
          this.NotificationsServices.updatenotifi(body).subscribe(res => {
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", `${Popup.error}`, "success");
      })
    } else {
      return;
    }
  }
  seeAllNotifi() {
    this.router.navigate(['/dashboard/notifications'], { queryParams: { see: "see_more" } })
  }

  gotoNotification(id, requestType, friend_user_id, OrderId) {
    // console.log(requestType)
    if (requestType == "Comment on profile") {
      this.router.navigate(['/dashboard/profile-comments'])
    } else if (requestType == "Friend Request sent") {
      this.router.navigate([`/${friend_user_id}/swinger/profile`], { queryParams: { t: "friend" } })

    }
    else if (requestType == "Create a hot date ") {
      this.router.navigate([`/${friend_user_id}/swinger/hot-dates`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Create new Group") {
      this.router.navigate([`/${friend_user_id}/swinger/groups`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Create a new album" || requestType == "insert more album images") {
      this.router.navigate([`/${friend_user_id}/swinger/albums`], { queryParams: { d: OrderId } })
    }

    else if (requestType == "upload new video ") {
      this.router.navigate([`/${friend_user_id}/swinger/videos`], { queryParams: { d: OrderId } })
    }

    else if (requestType == "Create new event ") {
      this.router.navigate([`/${friend_user_id}/swinger/events`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "upload time line photos") {
      this.router.navigate([`/${friend_user_id}/swinger/timeline`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Accept friend request") {
      this.router.navigate([`/${friend_user_id}/swinger/profile`], { queryParams: { t: "friend" } })
    }
    else if (requestType == "like album images ") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
    }
    else if (requestType == "like your timeline photo") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
    }
    else if (requestType == "like your Video") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
    }
    else if (requestType == "upload new audios ") {
      this.router.navigate([`/${friend_user_id}/swinger/audio`], { queryParams: { d: OrderId } })

    }

    else if (requestType == "like your Audio") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })

    }
    this.readnotifi(id);


  }
  readnotifi(id: any) {
    this.NotificationsServices.updatenotificationbyID(id).subscribe((res => {
      this.openNotifi();
    }))
  }


  logout() {
    this.authenticationService.logout().subscribe(res => {
      this.router.navigate(['/login']);
      setTimeout(function () {
        this.router.onSameUrlNavigation = 'reload';
      }, 50);
    });
  }

  playToy() {
    this.router.navigate(["/toys"]).then(result => { window.location.href = 'https://www.playfulobsessions.com/'; });
  }


  mobileNav(event: any) {
    if (this.checkNav == 0) {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
      this.renderer.addClass(this.elRef.nativeElement, "nav-show");
      this.checkNav = 1;
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
      this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
      this.checkNav = 0;
    }
  }

  seeAllmessage() {
    this.router.navigate(['/dashboard/message'])
  }

  countmessages: any;
  getallmsg(id: any) {
    this.MessagesService.getallunreadinbox().subscribe(res => {
      this.countmessages = res.data;
      // console.log(res)
    })
  }

  gotoMessage(user_id) {
    console.log(user_id)
    this.router.navigate(['/dashboard/message'], { queryParams: { uid: user_id } })
  }



  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      Swal.fire("Warning", `${Popup.session}`, "warning").then(ok => {
        if (ok) {
          this.logout()
          sessionStorage.clear();
        }
      });
    }
  }

  mapShow = false;
  sitelogo = environment.siteLogoDark;
  lookingForm: FormGroup;
  returnUrl: string;
  error = '';
  data: any;
  genders: any = ['male','female','couple','couple (2 female)', 'couple (2 men)'];
  distances: any = ['5','10','25','50', '100','200','500', '800', '1000'];
  age: any = new Array("18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83", "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99", "100", "101", "102", "103", "104", "105", "106", "107", "108", "109", "110");
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;
  message: FormGroup;
  loadings = false;
  submitted = false;
  UseName: any;
  // convenience getter for easy access to form fields
  // get f() { return this.message.controls; }
  senderIDs: any;
  MessagesRes: any;
  applyFilter(event) {
    this.loadings = false;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
   
    this.modal.open(this.SentMessageModalContent, ngbModalOptions);
  }
  get f() { return this.lookingForm.controls; }
  getlatitudeLongitude(mapData: any) {
    this.lookingForm.get('latitude').setValue(mapData.lat);
    this.lookingForm.get('longitude').setValue(mapData.lng);
    this.lookingForm.get('address').setValue(mapData.address);
    this.lookingForm.get('zipcode').setValue(mapData.postalCode);
  }
  
  checkvalidate:any = []
  lookingMembers() {
    this.submitted = true;

    this.data = {
    gender: this.f.first_gender.value.charAt(0),
    lookingfor: this.f.second_gender.value.charAt(0),
    from_age: this.f.distanceFrom.value,
    to_age: this.f.distanceTo.value,
    distance: this.f.distanceMiles.value,
    longitude:this.f.longitude.value,
    lattitude:this.f.latitude.value,
  
    }
    if (this.lookingForm.invalid) {
      this.loadings = false
        return;
    }
    this.SearchService.innersearchswing(this.data).subscribe(res => {
     
     this.checkvalidate = res.data.data.length
      if(this.checkvalidate == 0){
        this.loadings = false;
        Swal.fire(`${Popup.lookinforNoUser}`, `${Popup.lookingForfindOther}`, "error")
        
      }else{
      this.loadings = false;
      
      this.datashare.setOption('data', res.data);
      this.datashare.setopt('dat', this.data);

   
      this.router.navigate(['/dashboard/search-swinger']);
      this.closeHotdateModal();
      }
    });
  
  }

  closeHotdateModal() {
    this.lookingForm.reset();
    this.submitted = false;
    this.modal.dismissAll(this.SentMessageModalContent);
    
  }

  

}