import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharingModuleModule } from '../Sharing/sharing-module.module';
import { GroupsRoutingModule } from './groups-routing.module';
import { GroupPanelModule } from './group-panel/group-panel.module';
import { NgSelect2Module } from 'ng-select2';
import { GroupMembershipsComponent } from './group-memberships/group-memberships.component';
import { GroupInvitationsComponent } from './group-invitations/group-invitations.component';
import { GroupEventsComponent } from './group-events/group-events.component';
import { GroupNavigationComponent } from './group-navigation/group-navigation.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupListComponent } from './group-list/group-list.component';
import { CreateGroupComponent } from './create-group/create-group.component';


@NgModule({
  declarations: [
    GroupNavigationComponent,
    GroupsComponent,
    GroupMembershipsComponent,
    GroupInvitationsComponent,
    GroupEventsComponent,
    GroupListComponent,
    CreateGroupComponent,
  ],
  imports: [
    CommonModule,
    SharingModuleModule,
    ReactiveFormsModule,
    GroupPanelModule,
    GroupsRoutingModule,
    NgSelect2Module
  ]
})


export class GroupsModule { }
