import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UserMediaService } from '@app/_services/user-media.service';
import { environment } from '@environments/environment';
import { FriendsService } from '@app/_services';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private userMedia: UserMediaService, private route: ActivatedRoute, private friendService: FriendsService) { }

  hidenav: boolean;
  id: number;
  defaultProfile: any = environment.defaultProfile;
  coverImgURL: any = '../assets/images/user_dashboard.jpg';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  coverImagePath: any;
  dataId: any = '';
  profileId: any = '';
  profileloading = false;
  coverLoader = false;
  defaultNavigation = true;
  getSwingerProfile = false;
  swinger: any;
  male = false;
  female = false;
  c_t_class: string;


  ngOnInit(): void {
    this.hidenav = false;
    this.getSwingerProfile = true;
    this.route.params.subscribe( params => {
      this.getdetails(params.id);
    });

  }


  getdetails(id: any) {
    this.userMedia.getSwingerDetails(id).subscribe(res => {

      if(res.data.m_drink == '0' || res.data.m_smoke == 0 || res.data.f_drink == 0 || res.data.f_smoke == '0' ){
        res.data.m_drink = 'No';
        res.data.m_smoke = 'No';
        res.data.f_drink = 'No';
        res.data.f_smoke = 'No';
      }else{
        res.data.m_smoke = 'Yes';
        res.data.m_drink = 'Yes';
        res.data.f_smoke = 'Yes';
        res.data.f_drink = 'Yes';
      }

      if(res.data.like_smoke == '0' || res.data.like_drink == '0'){
        res.data.like_smoke = 'No';
        res.data.like_drink = 'No';
        this.c_t_class = 'badge badge-pill badge-danger';
      }else{
        res.data.like_smoke = 'Yes';
        res.data.like_drink = 'Yes';
        this.c_t_class = 'badge badge-pill badge-success';
      }

      this.swinger = res.data;
      switch(this.swinger.gender){
        case 'm':
          this.male = true;
          this.female = false;
          break;
        case 'f':
          this.male = false;
          this.female = true;
          break;
        default :
        this.male = true;
        this.female = true;
      }
    }, error => {
      console.log(error);
    });
  }

}
