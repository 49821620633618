import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { GroupServiceService } from "@app/_services";
import Swal from 'sweetalert2/dist/sweetalert2.js';  

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  defaultProfile: string = environment.defaultProfile;
  loading = true;
  constructor(private fgs: GroupServiceService) { }
  firendGroups: any;
  defaultNavigation = true;
  requestStatus = false;
  requestSent = false;

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups(){
    this.fgs.getAllGroups().subscribe(res => {
      this.firendGroups = res.data;
      console.warn(this.firendGroups );
      for(let group in this.firendGroups){
        this.firendGroups[group].requestStatus = false;
        this.firendGroups[group].requestSent = false;
      }
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  joinGroup(id:number, index:number){
    this.firendGroups[index].requestStatus = true;
    const data = {
      'group_id':id
    };

    this.fgs.joinGroup(data).subscribe(res => {
      this.firendGroups[index].requestStatus = false;
      this.firendGroups[index].requestSent = true;
      Swal.fire(  
        'Success',  
        'Request sent successfully.',  
        'success'  
      )
    },error=>{
      Swal.fire(  
        'Error',  
        'Something worong please try again.',  
        'error'  
      )
    })
  }

}
