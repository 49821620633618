<div *ngIf="emailStatus" class="user-navbars" #userNavds>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <ul class="nav">
                  <li class="nav-item">
                      <a class="nav-link active">
                        <div class="user_profile_ls inline"><img src="../../../assets/images/avtar-image.jpg" alt="user-profile" class="img-fluid"></div>
                        <div class="inline post">
                          <p>User Name</p>
                          <p>Gender</p>
                         </div>
                      </a>
                     
                  </li>
                  <span class="example-spacer"></span>
                  <button class="navbar-toggler butonmobile moble-nav" type="button" (click)='mobileNav($event);'  id="mobile-navigation">
                    <i class="fas fa-user-friends" style=" margin-top: 1em;color: white;"></i>
                  </button>
                  </ul>   
            </div>
        </div>
    </div>
</div>

 <!-- start mobile navigation -->

  <div class="justify-end navbarSmallDivecess nav-hide" #mobilenavWr>
    <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation-close">
      <i class="far fa-times-circle text-light" style="font-size:32px"></i>
    </button>
    <ul class="navbar-nav ms-auto me-0 mt-2 ">
    <li class="nav-item mt-4" >
      <a class="nav-link active">
        <div class="user_profile_ls inline"><img src="../../../assets/images/avtar-image.jpg" alt="user-profile" class="img-fluid"></div>
        <div class="inline post">
          <p>User Name</p>
          <p>Gender</p>
         </div>
      </a>
    </li>

    </ul>

  </div>