import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { environment, Popup } from "@environments/environment";
import { AuthenticationService,CommonService } from "@app/_services";
import { AudioService } from "../../_services/audio.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Title } from "@angular/platform-browser";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-swinger-audio',
  templateUrl: './swinger-audio.component.html',
  styleUrls: ['./swinger-audio.component.css']
})
export class SwingerAudioComponent implements OnInit {
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  AudioStoreMessage: string;
  audiofiles:any[];
  IDs: any;
  freeuser = false;
  currentUser: any;
  progress: number = 0;
  disabed = true;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private Router: Router,
    private modal: NgbModal,
    private titleService: Title,
    private AudioServices: AudioService,
    private CommonService:CommonService,
    private route: ActivatedRoute,
  ) {
    this.titleService.setTitle("Swingers Audio | Swingerflings");
    this.route.queryParams.subscribe(params => {
      this.IDs = params['d'];
      if (this.IDs != null && this.IDs != undefined && this.IDs != "") {
        return this. getvideoByID()
      } else {
        this.route.parent.params.subscribe(params => {
          this.getaudiofiles(params.swingerId);
        });
      }
    })

  }

  defaultProfile = environment.defaultProfile;
  loading = false;
  ifSuccess = false;
  message: string;
  error = "";
  audio: FormGroup;
  openModel = false;
  pagination:any;
	checkpages = false;
  albumStatus = false

  ngOnInit(): void {}

  getvideoByID(){
    this.AudioServices.getSingelaudio(this.IDs).subscribe((res)=>{
      let array :any[] = [];
      array.push(res.data)
      this.audiofiles = array;
      if(this.audiofiles.length == 0 ){
        this.loading = false;
        // this.dataNotFound = true;
      }
    })
  }

  getaudiofiles(id:any){
    this.albumStatus = true
    this.AudioServices.getaudio(id).pipe(map(res=>{
      this.pagination = res.data;
      this.albumStatus = false
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
       return res.data.data;
      })).subscribe(audiores => {
        this.audiofiles = audiores;
        // console.log(this.audiofiles)
        if(this.audiofiles.length == 0){
          this.nodatas = true
        }
      })
  }

  nodatas = false;
  activePage(url:string){
    if(url){
      this.CommonService.getPagination(url).pipe(map(res=>{
    this.pagination = res.data;
    this.checkpages = this.pagination.links.length >= 4?true: false;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
     return res.data.data;
    })).subscribe(audiores => {
      this.audiofiles = audiores;
      if(this.audiofiles.length == 0){
        this.nodatas = true
      }
    })
  }
  }


  audioLike(id: any, index: number){
    const data = {
     'audio_id':id
    };
    this.AudioServices.likeAudio(data).subscribe(res => {
      let datalike = res;
      this.audiofiles[index].totallikes = datalike.totalLikes;
    });
  }

  private currentPlayedElem: HTMLAudioElement = null;
  onPaly(elm: HTMLAudioElement, id: any, index: number) {
    const data = {
      'audio_id':id
     };
    if (this.currentPlayedElem && this.currentPlayedElem !== elm ) {
      this.currentPlayedElem.pause();
    }
    this.currentPlayedElem = elm;
    this.AudioServices.ViewAudioCount(data).subscribe(res => {
      console.log(res)
      this.audiofiles[index].totalviews = res.views;
    });
  }

}
