import { DataService } from '@app/_services/dataShare';
import { Component, OnInit} from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, SwingersService} from '@app/_services';
import { Swinger } from '@app/_models/swinger';
import { Title } from '@angular/platform-browser';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  selector: 'app-searchswinger',
  templateUrl: './searchswinger.component.html',
  styleUrls: ['./searchswinger.component.css']
})
export class SearchswingerComponent implements OnInit {
   datasource:any= [];
   swingers:any;
   datasourc:any=[];
   dats:any;
//   constructor(  private datashare :DataService,) {

//     this.datasource = this.datashare.getOption();

//     console.log(this.datasource.data)
//    }

//   ngOnInit(): void {

   
//   }


  

// }
defaultProfile = environment.defaultProfile;
userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
loading = true;
requestLoding = false;
data: any;
pagination: any;
currentUser:any;
freeuser = false;

constructor(private swingersService: SwingersService,
  private commonService: CommonService,
  private authenticationService: AuthenticationService,
  private titleService:Title,
  private datashare :DataService
  ){ 
    this.titleService.setTitle("Swingers | Swingerflings");
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if(this.currentUser.plan_id === 1){
        this.freeuser = true;
        // console.log(this.currentUser.plan_id);
      }
  });

  this.datasource = this.datashare.getOption();
  this.datasourc=this.datashare.getOpt();
  this.swingers = this.datasource.data.data;
  this.pagination = this.datasource.data;
  

  }
  



ngOnInit(): void {
  if(this.swingers != null){
    this.loading = false;

  
      }
this.pagination.links[0].label = 'Previous';
this.pagination.links[this.pagination.links.length - 1].label = 'Next';
}
pagelarg = true;
getPageData(url:any, dats:any){
   dats =this.datasourc.dat;

  this.loading = true;
  if(url){
  this.commonService.getPaginationin(url, dats).subscribe(data => {
   console.log('bhaskar', data)
    this.swingers = null;
    console.log(data)
    this.loading = false;
    this.swingers = data.data.data;
    for (let index = 0; index < this.swingers.length; index++) {
      if(this.swingers[index].user_status == 0){
       this.swingers.splice(index,1)
      }
    }
    this.pagination = data.data;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
    for(const swinger in this.swingers){
    this.swingers[swinger].data.data.data.is_pic = (this.swingers[swinger].data.data.data.is_pic) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].data.data.data.is_pic}`: this.defaultProfile;
    }
    if (this.pagination.links.length == 3) {
      this.pagelarg = false;  
   }else{
     this.pagelarg = true;  
   }
  }, error => {
    this.loading = false;
  });
       
}
 
}
nodatainsearch = false;

}
