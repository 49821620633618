import { AfterViewInit, Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'user-not-exist',
  templateUrl: './usernotexist.component.html',
  styleUrls: ['./usernotexist.component.css']
})
export class UsernotexistComponent implements OnInit {

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private Title:Title
    ) {
      this.Title.setTitle('User Not Exist | Swingerflings')
    const userId = JSON.parse(sessionStorage.getItem('currentUser'));
    if (userId == null || userId == undefined || userId == '') {
      this.router.navigate(['/login']);
    } else { return; }
  }

  ngOnInit(): void { }

}
