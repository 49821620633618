export const commonData = {
   gender: [{
        id: 1,
        title: 'Male',
        value: 'm'
    },
    {
        id: 2,
        title: 'Female',
        value: 'f'
    },
    {
        id: 3,
        title: 'Couple',
        value: 'c'
    }],

    pleasures: [{
        id: 1,
        title: 'No',
        value: 0
    },
    {
        id: 2,
        title: 'Definitely',
        value: 1
    },
    {
        id: 3,
        title: 'Maybe',
        value: 2
    },
    {
       id: 4,
       title: 'A Little',
       value: 3
   },
   {
       id: 5,
       title: 'Yes',
       value: 4
   },

   ],

 smokeDrink: [
    {
        id: 1,
        title: 'I do not care',
        value: 2
    },
    {
        id: 2,
        title: 'I only want people that drink',
        value: 1
    },
    {
        id: 3,
        title: 'I only want people that do not drink',
        value: 0
    },

]
};



