<div *ngIf="emailStatus" class="user-navbar" #userNavds>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="nav">
                  <li class="nav-item">
                    <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">Home</a>
                  </li>

                    <li class="nav-item">
                      <a class="nav-link"  routerLink="/dashboard/friends" routerLinkActive="active">Friends</a>
                    </li>

                    <!-- <li class="nav-item">
                      <a class="nav-link" routerLink="/group/groups" routerLinkActive="active">Groups</a>
                    </li> -->

                    <li class="nav-item">
                    <a class="nav-link" routerLink="/dashboard/create-album" routerLinkActive="active">Gallery</a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link" routerLink="/hot-dates/hot-date-list" routerLinkActive="active">Hot dates</a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link" routerLink="/event-dates/event-date-list" routerLinkActive="active">Events</a>
                        </li>

                        <li class="nav-item">
                          <a class="nav-link" routerLink="/dashboard/message" routerLinkActive="active">Message</a>
                          </li>
                          
                  </ul>
            </div>
        </div>
    </div>
</div>