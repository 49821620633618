<app-dashboard-header></app-dashboard-header>
<app-swinger-profile-navigation [swinger]='swinger'></app-swinger-profile-navigation>
<div class="defualt_bg p-1" *ngIf="emailStatus">
    <div class="container">
       <div class="row">
           <div class="col-md-12">
            <router-outlet></router-outlet>
           </div>
           <div class="col-md-4">
           <!-- <app-swinger-side-bar [swinger]='swinger' *ngIf="showUserMInfo"></app-swinger-side-bar> -->
           </div>
        </div>
        </div>
      </div>
