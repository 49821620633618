﻿import { Component, OnInit, EventEmitter, Output, ElementRef, Renderer2, HostListener } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { NotificationsService } from "./notifications.service";
import { SwPush } from '@angular/service-worker';
import { Router } from '@angular/router'
import { SharedService } from "./_services/shared.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
// const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
// const CHECK_INTERVAL = 15000 // in ms
// const STORE_KEY = 'lastAction';
// declare var device;

@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  private socket: any;
  public data: any;
  defaultProfile: string = environment.defaultProfile;
  currentUser: any;
  isLogin = false;

  // public getLastAction() {
  //   return parseInt(localStorage.getItem(STORE_KEY));
  // }
  // public setLastAction(lastAction: number) {
  //   localStorage.setItem(STORE_KEY, lastAction.toString());
  // }

  constructor(
    swPush: SwPush,
    private _notificationService: NotificationsService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private elemref: ElementRef,
    public Router: Router,
    private SharedService: SharedService) {
    // this._notificationService.requestPermission();
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      // console.log(x)
      if (this.currentUser != null) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }

      // if (x != null || x != undefined) {
      //   this.check();
      //   this.initListener();
      //   this.initInterval();
      //   sessionStorage.setItem(STORE_KEY, Date.now().toString());
      // }
    });

    // 
    //     document.addEventListener('deviceready', function () {
    //       alert(device.platform);
    //     }, false);
    // 
    let profile = sessionStorage.getItem('user-profile');
    if (profile) {
      this.defaultProfile = profile;
    }
  }

  ngOnInit() { }

  //   initListener() {
  //     document.body.addEventListener('click', () => this.reset());
  //     document.body.addEventListener('mouseover', () => this.reset());
  //     document.body.addEventListener('mouseout', () => this.reset());
  //     document.body.addEventListener('keydown', () => this.reset());
  //     document.body.addEventListener('keyup', () => this.reset());
  //     document.body.addEventListener('keypress', () => this.reset());
  //   }

  //   reset() {
  //     this.setLastAction(Date.now());
  //   }

  //   initInterval() {
  //     setInterval(() => {
  //       this.check();
  //     }, CHECK_INTERVAL);
  //   }

  //   check() {
  //     const now = Date.now();
  //     const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
  //     const diff = timeleft - now;
  //     const isTimeout = diff < 0;

  //     if (isTimeout) {
  //       Swal.fire("Warning", `Your session expired. Please login again`, "warning").then(ok=>{
  //         if(ok){
  //           this.SharedService.sendClickEvent();
  //           sessionStorage.clear();
  //           this.Router.navigateByUrl('/login')
  //         }
  //       });
  //     }
  //   }
}  
