import { ElementRef, ViewChild } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UserMediaService } from '@app/_services';
import { environment } from '@environments/environment';
import { jsPDF } from 'jspdf';


@Component({
  selector: 'app-invoce',
  templateUrl: './invoce.component.html',
  styleUrls: ['./invoce.component.css']
})
export class InvoceComponent implements OnInit {
  @ViewChild('invoicedownload',{static: true}) invoicedownload: ElementRef<any>;
  siteLogoUrl = environment.siteLogoDark;
  @Input() invoceData:any;

  planDetails = {
    id:1,
    price: 2000,
    tax: 0,
    discount: 20,
    planName:"Fist plan"
  }

  constructor() { 
  }

  ngOnInit(): void {
    console.log(this.invoceData);
  }


  dwnloadinvoce(){
    const doc: jsPDF = new jsPDF("p", "pt", "a3");
    const DATA = this.invoicedownload.nativeElement;
    doc.html(DATA, {
      callback: (doc) => {
        doc.save('invoice.pdf');
      }
   });
 
  }


}
