import { Component, OnInit, Input, ElementRef, Renderer2} from '@angular/core';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.css']
})

export class TopBannerComponent implements OnInit {

  constructor(private el: ElementRef, private render: Renderer2) { }

  @Input() heading: string;
  @Input() backgroundUrl: any;

  ngOnInit(): void {
  }




}
