<div class="padin" id="parentDiv">
  <div class="mbmsg" *ngIf="message.user_id == senderIDs">
      <div class="text-left recbgstext">
        <span >
          <p>
            {{message?.message}}
          </p>
          <p style="font-size: 12px;margin-right: 10px;text-align: right;"> {{message?.date_time | date:'medium'}}</p>
        </span>
      </div>
      <!-- <div class="bgstextTimeres" >
        
      </div> -->
    </div><br>
    <!-- Current User Send message -->
    <div class=" postss" *ngIf="message.user_id === userId">
      <div class="text-right sendbgstext">
        <span >
          <p style="text-align: left;">
            {{message.message}}
          </p>
          <p style="font-size: 12px;margin-right: 10px;color: #636363;"><span style="float: left;"
            ><i [ngClass]="{'checkcolore' : message?.seen_status == 1}" style="font-size:10px" class="fas fa-check-double"></i></span>{{message?.date_time | date:'medium' }}</p>
        </span>
      </div>
      <!-- <div class="bgstextTime" >
        <p style="font-size: 12px;margin-right: 10px;color: #636363;"><span style="float: left;"
         ><i [ngClass]="{'checkcolore' : message?.seen_status == 1}" style="font-size:10px" class="fas fa-check-double"></i></span>{{message?.date_time | date:'medium' }}</p>
      </div> -->
    </div>
    <br>
</div>