<section id="user-banner" class="p-4" style="background-image: url({{swinger?.cover}});">
  <div class="container">
    <div class="row">
      <div class="col-md-6  ">
        <div class="profile_wrapper">
          <div class="user_profile_view">
            <img src="{{swinger?.profile}}" class="img-fluid profile-image_view">
          </div>
          <div class="user_info_data">
            <h5 class="mb-0">{{swinger?.name}}
              <span *ngIf="swinger?.gender != null" style="font-size: 12px;text-transform: capitalize;"
                [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''"
                class="badge badge-pill  mb-2">
                <span *ngIf="swinger?.gender == 'm'">Male</span>
                <span *ngIf="swinger?.gender == 'f'">female</span>
                <span *ngIf="swinger?.gender == 'c'">Couple</span>
              </span>
            </h5>
            <div class="mb-1">
              <button *ngIf="swinger?.friendstatus == 'Not Requested'" type="button"
                class="btn btn-warning edit-user-profile" (click)="sendFriendRequest(swinger?.id)">
                <div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden"></span>
                </div> <i class="fas fa-user-plus"></i> Send Friend Request
              </button>
              <button *ngIf="swinger?.friendstatus == 'Friends'" type="button" class="btn btn-info edit-user-profile"><i
                  class="fas fa-user-friends"></i> Friend</button>
              <button *ngIf="swinger?.friendstatus == 'Requested'" type="button"
                class="btn btn-info edit-user-profile"><i class="fas fa-check"></i> Requested </button>

              <button routerLink="/{{swinger?.id}}/swinger/profile" type="button"
                class="btn btn-info edit-user-profile ml-2"> Profile info </button>
            </div>
            <p class="m_text mb-1"><span class="mr-2" #clike (click)="setProfileLike(swinger?.id)"><i
                  class="fas fa-thumbs-up" [ngClass]="status ? 'text-primary' : 'text-like'"></i> {{like}} Likes</span>
              <span class="mr-2"><i class="fas fa-comment"></i> {{comments}} Comments</span> <span class="mr-2"><i
                  class="fas fa-eye"></i> {{profileViews}} Views</span></p>
            <p class="m_text" style="margin-bottom: 0px;">{{swinger?.short_tag_line}}</p>

          </div>
        </div>
      </div>
      <div class="col-md-6 thumb_navigation">
        <div class="row">

          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-primary mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/albums">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-picture-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Albums</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-dark timeline-bg mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/timeline">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-stream"></i></h1>
                </div>
                <div class="card-header text-center">Timeline</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card bg-light bg-success mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/videos" class="text-dark">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-video"></i></h1>
                </div>
                <div class="card-header text-center">Videos</div>
              </a>
            </div>
          </div>
          <!-- <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-secondary mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/audio">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-file-audio-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Audio</div>
              </a>
            </div>
          </div> -->
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-dark bg-warning mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/friends">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-user-friends"></i></h1>
                </div>
                <div class="card-header text-center">Friends</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-danger mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/hot-dates">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fab fa-hotjar"></i></h1>
                </div>
                <div class="card-header text-center">Hot Dates</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-dark mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/events">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="far fa-calendar-alt"></i></h1>
                </div>
                <div class="card-header text-center">Events</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-3">
            <div class="card text-white bg-success mb-3">
              <a (click)="gotomessag(swinger?.id)">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-comments" aria-hidden="true"></i></h1>
                </div>

                <div class="card-header text-center">Message</div>
              </a>
            </div>
          </div>


          <!-- <div class="col-md-3 col-sm-6 col-3">
      <div class="card text-white bg-info mb-3" >
        <a routerLink="/{{swinger?.id}}/swinger/groups" >
        <div class="card-body">
          <h1 class="text-center"><i class="fas fa-users"></i></h1>
        </div>
        <div class="card-header text-center">Groups</div>
        </a>
      </div>
  </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="swinger moboview-sow">
    <a (click)="showMoboMenu();"><i *ngIf="!moboNavigation" class="fas fa-chevron-circle-up"></i><i
        *ngIf="moboNavigation" class="fas fa-chevron-circle-down"></i></a>
  </div>
  <div class="swinger-mobile-navigation" #mobonav>
    <div class="row">
      <div class="col-md-12 ">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-4">
            <a routerLink="/{{swinger?.id}}/swinger/profile">
              <div class="card text-white bg-success mb-3">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-user-circle" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Profile</div>
              </div>
            </a>
          </div>

          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-dark bg-warning mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/friends">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-user-friends"></i></h1>
                </div>
                <div class="card-header text-center">Friends</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-primary mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/albums">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-picture-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Albums</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-dark mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/timeline">
                <div class="card-body">
                  <h1 class="text-center mtmb text-dark"><i class="fas fa-stream"></i></h1>
                </div>
                <div class="card-header text-center text-dark">Timeline</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card bg-light bg-success mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/videos" class="text-dark">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fas fa-video"></i></h1>
                </div>
                <div class="card-header text-center">Videos</div>
              </a>
            </div>
          </div>
          <!-- <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-secondary mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/audio">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-file-audio-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Audio</div>
              </a>
            </div>
          </div> -->
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-danger mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/hot-dates">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fab fa-hotjar"></i></h1>
                </div>
                <div class="card-header text-center">Hot Dates</div>
              </a>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-4">
            <div class="card text-white bg-dark mb-3">
              <a routerLink="/{{swinger?.id}}/swinger/events">
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="far fa-calendar-alt"></i></h1>
                </div>
                <div class="card-header text-center">Events</div>
              </a>
            </div>
          </div>



          <!-- <div class="col-md-3 col-sm-6 col-3">
    <div class="card text-white bg-info mb-3" >
    <a routerLink="/{{swinger?.id}}/swinger/groups" >
    <div class="card-body">
      <h1 class="text-center"><i class="fas fa-users"></i></h1>
    </div>
    <div class="card-header text-center">Groups</div>
    </a>
    </div>
    </div> -->
        </div>
      </div>
    </div>
  </div>
</section>