import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { Friends } from '@app/_models/friends';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  friendsUrl = 'https://jsonplaceholder.typicode.com/users';
    httpOptions = {
      headers: new HttpHeaders({
          'Accept':'application/json',
          'Content-Type': 'application/json'
      })
  };

  
  storeAlbum(data){
    return this.http.post<any>(`${environment.apiUrl}/store-album`, data,{
      reportProgress: true,
      observe: 'events'
    }).pipe(
      retry(1),
      catchError(this.handleError)
    );
    }

    storeTimeLine(data){
      return this.http.post<any>(`${environment.apiUrl}/image-timeline`, data,{
        reportProgress: true,
        observe: 'events'
      }).pipe(
        retry(1),
        catchError(this.handleError)
      );
      }

      getTimeLine(id){
        return this.http.get<any>(`${environment.apiUrl}/${id}/get-image-timeline`, this.httpOptions).pipe(
          retry(1),
          catchError(this.handleError)
        );
        }

        getAlbums(id){
          return this.http.get<any>(`${environment.apiUrl}/${id}/get-albums`,  this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
          }

        getAlbumsImages(id){
          return this.http.get<any>(`${environment.apiUrl}/${id}/get-images`,  this.httpOptions).pipe(
            retry(1),
            catchError(this.handleError)
          );
          }

          storeVideos(data){
            return this.http.post<any>(`${environment.apiUrl}/store-video`, data, {
              reportProgress: true,
              observe: 'events'
            }).pipe(
              retry(1),
              catchError(this.handleError)
            );
            }

          getVideos(id){
            return this.http.get<any>(`${environment.apiUrl}/${id}/get-video`).pipe(
              retry(1),
              catchError(this.handleError)
            );
            }


            getallVideos(id){
              return this.http.get<any>(`${environment.apiUrl}/get-all-swinger-video/${id}`).pipe(
                retry(1),
                catchError(this.handleError)
              );
              }
            getVideoByVideoId(id:number){
              return this.http.get<any>(`${environment.apiUrl}/get-singalevideo/${id}`).pipe(
                retry(1),
                catchError(this.handleError)
              );
  
            }


            deleteAlbum(id){
              return this.http.post<any>(`${environment.apiUrl}/delete-album/${id}`,'',this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
              }

              changeImagePrivacy(id){
                return this.http.post<any>(`${environment.apiUrl}/delete-album/${id}`,'',this.httpOptions).pipe(
                  retry(1),
                  catchError(this.handleError)
                );
                }

            changeAlbumPrivacy(data){
              return this.http.post<any>(`${environment.apiUrl}/change-album-privacy`, data, this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
              }

            changeVideoPrivacy(data){
                return this.http.post<any>(`${environment.apiUrl}/video-privacy`, data, this.httpOptions).pipe(
                  retry(1),
                  catchError(this.handleError)
                );
              }

              deleteVideo(id){
                return this.http.post<any>(`${environment.apiUrl}/delete-video/${id}`,'',this.httpOptions).pipe(
                  retry(1),
                  catchError(this.handleError)
                );
              }

            imageLike(id){
              return this.http.post<any>(`${environment.apiUrl}/image-like/${id}`,'',this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }

            storeTimeLineView(id){
              return this.http.post<any>(`${environment.apiUrl}/image-views/${id}`,'',this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }


            deleteTimeLineImage(id){
              return this.http.post<any>(`${environment.apiUrl}/deleteTimelineImage/${id}`,'',this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }

            getAlbumImageLike(data){
              return this.http.post<any>(`${environment.apiUrl}/store-album-image-like`, data ,this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }

            getAlbumImageView(data){
              return this.http.post<any>(`${environment.apiUrl}/store-album-image-view`, data ,this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }

            videoLike(data){
              return this.http.post<any>(`${environment.apiUrl}/video-likes`,data,this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }

            storeVideoViews(data){
              return this.http.post<any>(`${environment.apiUrl}/video-views`,data,this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }

            
            deleteAlbumImage(data){
              return this.http.post<any>(`${environment.apiUrl}/delete-album-image`, data ,this.httpOptions).pipe(
                retry(1),
                catchError(this.handleError)
              );
            }
            

  
  getAllVideos(){
    return this.http.get(`${environment.apiUrl}/get-public-video`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllhoto(){
    return this.http.get(`${environment.apiUrl}/get-all-timeline-images`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  commentsvideo(body){
    return this.http.post(`${environment.apiUrl}/video-comment`, body,  this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  commentsImnages(body){
    return this.http.post(`${environment.apiUrl}/image-comment`, body,  this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  vcommentlike(body){
    return this.http.post(`${environment.apiUrl}/media-comment-likes`, body,  this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

}
