<section class="section community_section">
    <div class="container-fluid">
      <div class="row">
          <div class="col-md-12">
              <div class="join_community_wrapper">
                  <h1 class="text-center text-light"> Keep in Touch with the Community</h1>
                  <a href="javascript:void(0);" class="btn btn-warning cu_btn">Register now for free </a>
              </div>
          </div>
      </div>
    </div>
</section>
