
<!-- nav -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark _nav_fixed">
  <div class="container">
    <a class="navbar-brand" routerLink="/"><img src="{{siteLogoUrl}}" class="img-fluid" id="sitelogo"></a>
   

    <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation">
      <span class="nav-bar-1"></span>
      <span class="nav-bar-2"></span>
      <span class="nav-bar-3"></span>
    </button>

    

    <div class="collapse navbar-collapse justify-end">
      <ul class="navbar-nav ms-auto me-0 ">


        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/plan">PRICING/PLANS</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/hot-dates/swinger-hot-dates">HOT DATES</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)='playToy();'>TOY SHOP!</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/contact">CONTACT US</a>
        </li>

      
      </ul>
    </div>

    <div class="collapse navbar-collapse justify-end" id="right_navbar">
      <ul class="navbar-nav ms-auto me-0 ">
        <li class="nav-item" *ngIf="!isLogin">
          <a  class="btn btn-danger rounded-0 ps-2 pe-2 cu_btn" routerLink="/login">Login</a>
        </li>
     
        <li class="nav-item dropdown" *ngIf="isLogin">
            <a class="nav-link dropdown-toggle profile_image" href="javascript:void(0);" id="user_profile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img [src]="defaultProfile" #profileImg class="img-fluid">
            </a>
            <ul class="dropdown-menu" aria-labelledby="user_profile">
              <li><a class="dropdown-item" routerLink="/dashboard/swingers">Dashboard </a></li>
              <li><a class="dropdown-item"  routerLink="/dashboard/edit-profile">My Profile</a></li>
              <li><a class="dropdown-item" routerLink="/dashboard/change-password">Change Password</a></li>
              <li><a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a></li>
            </ul>
        </li>
        <li class="nav-item" *ngIf="!isLogin">
          <a class="btn btn-warning rounded-0 ps-2 pe-2 cu_btn" routerLink="/register">Register</a>
        </li>
      </ul>
    </div>
  </div>
</nav>


 <div class="justify-end navbarSmallDiveces nav-hide" #mobilenavWr>
  <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation-close">
    <i class="far fa-times-circle text-light" style="font-size:25px"></i>
  </button>
  <ul class="navbar-nav ms-auto me-0 mt-2 ">
    <li class="nav-item dropdown" *ngIf="isLogin">
      <a class="nav-link profile_image" href="javascript:void(0);" id="user_profile">
            <img src="{{defaultProfile}}" class="img-fluid">
      </a>
      <span class="active-user-name text-light">{{username}}</span>
  </li>

  

    <li class="nav-item mt-4" *ngIf="!isLogin">
      <a  class="btn btn-danger rounded-0 ps-2 pe-2 cu_btn" routerLink="/login">Login</a>
      <a class="btn btn-warning rounded-0 ps-2 pe-2 cu_btn" routerLink="/register">Register</a>
    </li>

    <li class="nav-item mt-3">
      <a class="nav-link active" routerLink="/">HOME</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a  class="nav-link" routerLink="/dashboard/swingers">Dashboard</a>
    </li>


    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/plan">PRICING/PLANS</a>
    </li>


    

    <li class="nav-item">
      <a class="nav-link" (click)='playToy();'>TOY SHOP!</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/contact">CONTACT US</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a  class="nav-link" href="javascript:void(0);" (click)="logout()">Logout</a>
    </li>

  </ul>
</div>

