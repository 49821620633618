import { Component, OnInit } from "@angular/core";
import { environment } from "@environments/environment";
import { UserMediaService } from "@app/_services/user-media.service";
import { GroupServiceService } from "@app/_services/group-service.service";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Router } from "@angular/router";

@Component({
  selector: "app-group-top-panel",
  templateUrl: "./group-top-panel.component.html",
  styleUrls: ["./group-top-panel.component.css"],
})
export class GroupTopPanelComponent implements OnInit {
  defaultProfile: any = environment.defaultProfile;
  errorProfile = this.defaultProfile;
  coverImgURL: any = "../assets/images/user_dashboard.jpg";
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  coverImagePath: any;
  dataId: any = "";
  profileId: any = "";
  profileloading = false;
  coverLoader = false;
  defaultNavigation = true;
  groupDetails: any;

  constructor(
    private userMedia: UserMediaService,
    private router: Router,
    private groupService: GroupServiceService
  ) {}
  username: string;
  subscribePlan: string = "Membership Level: 6 month membership (reoccurring) ";
  userData: any;
  groupId: any;

  ngOnInit(): void {
    const currentUrl: string = this.router.url;
    this.groupId = currentUrl.split("/")[2];
    console.log(this.groupId);
    this.getGroupDetailById(this.groupId);
    this.userData = JSON.parse(sessionStorage.getItem("currentUser"));
    this.username = this.userData.name;
  }

  changeGroupCoverPage(files: File, id: any) {
    this.coverLoader = true;
    let reader = new FileReader();
    this.coverImagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.coverImgURL = reader.result;
    };

    const formData = new FormData();
    formData.append("coverImage", files[0], files[0].name);
    formData.append("id", this.groupId);
    formData.append("user_id", this.userId);
    this.userMedia.changeGroupCover(formData).subscribe(
      (res) => {
        Swal.fire("Success", `${res.message}`, "success");
        this.getGroupDetailById(this.groupId);
        this.coverLoader = false;
      },
      (error) => {
        let errorData = JSON.parse(sessionStorage.getItem("error")).errors;
        if (errorData) {
          Swal.fire("Error", `${errorData[0]}`, "error");
          sessionStorage.removeItem("error");
          this.groupDetails.cover_image = this.groupDetails.cover_image
            ? this.groupDetails.cover_image
            : this.coverImgURL;
          this.profileloading = false;
        }
      }
    );
  }

  changeProfile(files: File, id: any) {
    this.profileloading = true;
    let reader = new FileReader();
    this.defaultProfile = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.defaultProfile = reader.result;
    };

    const formData = new FormData();
    formData.append("profile_pic", files[0], files[0].name);
    formData.append("id", this.groupId);
    formData.append("user_id", this.userId);
    this.userMedia.changeGroupProfile(formData).subscribe(
      (res) => {
        Swal.fire("Success", `${res.message}`, "success");
        this.getGroupDetailById(this.groupId);
        this.profileloading = false;
      },
      (error) => {
        let errorData = JSON.parse(sessionStorage.getItem("error")).errors;
        if (errorData) {
          Swal.fire("Error", `${errorData[0]}`, "error");
          sessionStorage.removeItem("error");
          this.defaultProfile = this.groupDetails.profile_pic
            ? this.groupDetails.profile_pic
            : this.errorProfile;
          this.profileloading = false;
        }
      }
    );
  }

  getGroupDetailById(id: any) {
    this.groupService.getGroupDetailById(id).subscribe((res) => {
      console.log(res);
      this.groupDetails = res;
      this.groupDetails.cover_image = `${environment.imgUrl}/${this.userId}/group/cover/${this.groupDetails.cover_image}`;
      this.groupDetails.profile_pic = `${environment.imgUrl}/${this.userId}/group/profile/${this.groupDetails.profile_pic}`;

    });
  }
}
