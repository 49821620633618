import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@app/_helpers";
import { GroupMembershipsComponent } from "./group-memberships/group-memberships.component";
import { GroupInvitationsComponent } from "./group-invitations/group-invitations.component";
import { GroupEventsComponent } from "./group-events/group-events.component";
import { GroupsComponent } from "./groups/groups.component";
import { GroupListComponent } from "./group-list/group-list.component";
import { CreateGroupComponent } from './create-group/create-group.component';

const routes: Routes = [
  {
    path: "group",
    component: GroupsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "group-membership", component: GroupMembershipsComponent },
      { path: "group-invitation", component: GroupInvitationsComponent },
      { path: "group-events", component: GroupEventsComponent },
      { path: "groups", component: GroupListComponent },
      { path: "create-group", component: CreateGroupComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupsRoutingModule {}
