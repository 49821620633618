import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_helpers';
import { SwingerComponent } from '../userDashboard/swinger/swinger.component';
import { SwingerFriendsComponent } from './swinger-friends/swinger-friends.component';
import { SwingerVideosComponent } from './swinger-videos/swinger-videos.component';
import { SwingerGroupsComponent } from './swinger-groups/swinger-groups.component';
import { SwingerEventsComponent } from './swinger-events/swinger-events.component';
import { SwingersWrapperComponent } from './swingers-wrapper/swingers-wrapper.component';
import { SwingerInfoComponent } from './swinger-info/swinger-info.component';
import { SwingerHotDateViewComponent } from './swinger-hot-date-view/swinger-hot-date-view.component';
import { SwingerTimelineComponent } from './swinger-timeline/swinger-timeline.component';
import { SwingerAlbumsComponent } from './swinger-albums/swinger-albums.component';
import { SwingerAudioComponent } from './swinger-audio/swinger-audio.component';
const routes: Routes = [{ path: ':swingerId/swinger',
component: SwingersWrapperComponent,
canActivate: [AuthGuard],
children: [
  { path: 'profile', component: SwingerInfoComponent},
  { path: 'friends', component: SwingerFriendsComponent},
  { path: 'videos', component: SwingerVideosComponent},
  { path: 'groups', component: SwingerGroupsComponent},
  { path: 'events', component: SwingerEventsComponent},
  { path: 'hot-dates', component:SwingerHotDateViewComponent},
  { path: 'timeline', component:SwingerTimelineComponent},
  { path: 'albums', component:SwingerAlbumsComponent},
  { path: 'audio', component:SwingerAudioComponent}
]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SwingersRoutingModule { }
