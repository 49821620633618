import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService, ProfileViewService } from '@app/_services';
import { Swinger } from '@app/_models/swinger';
import { Router } from '@angular/router';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.css']
})
export class FriendListComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  requestLoding = false;
  data: any;
  successMsg = false;
  swingerStatus: string;
  uiHide = false;

  @Input() swinger: any;

  constructor(private friendService: FriendsService, 
    private viewService: ProfileViewService,
    private router: Router
    ) { 

  }
  swingers: Swinger;
  profilePic: any;

  ngOnInit(): void {
    this.profilePic = this.swinger.profile;
  }


  sendFriendRequest(firedId: any){
    this.requestLoding = true;
    this.data = {
      from_user_id: this.userId,
      to_user_id: firedId
    };
    this.friendService.sendFriendRequest(this.data).subscribe((res) => {
    this.requestLoding = false;
    this.successMsg = true;
    this.swingerStatus = res.message;
    this.successMsg = false;
    this.uiHide = true;
  });
  }

  setProfileViews(id: any){
    const data = {
      'swinger_id': id
    };
    this.viewService.setProfileViews(data).subscribe(res => {
      console.log(res);
    });
   }

   reloadWindow(id: number){
     this.router.navigate([`/${id}/swinger/friends`],{ queryParams: { f: id } });
   }

}
