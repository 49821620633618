import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileLikeService {

  constructor(private http: HttpClient) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

  getProfileLikes(id: any){
    return this.http.get<any>(`${environment.apiUrl}/get-profile-likes/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

 setProfileLikes(data: any){
  return this.http.post<any>(`${environment.apiUrl}/profile-like`, data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
  }



}
