import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment, Popup } from '@environments/environment';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthenticationService } from '@app/_services';
import { Title } from "@angular/platform-browser";
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    EmailVerifyForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    sitelogo = environment.siteLogolight;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private title:Title
    ) {
        this.title.setTitle('Forgot Password | Swingerflings')
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }
    ngOnInit() {
        this.EmailVerifyForm = this.formBuilder.group({
            email: ['', Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.EmailVerifyForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        // stop here if form is invalid
        if (this.EmailVerifyForm.invalid) {
            return;
        }

        let body = {
            email: this.f.email.value
        }
        this.authenticationService.forgotpassword(body).subscribe(res => {
            if(res.user_status == 0){
                this.loading = false;
                Swal.fire('Warning', `Your account has been blocked. Please contact support team (support@swingerflings.com)`, 'warning');  
            }else{
                if(res){
                    this.EmailVerifyForm.reset();
                    this.router.navigate(['/login'])
                 }
                 Swal.fire("Success", `${Popup.forgotpassword}`, "success");
                 this.loading = false;
            }
           
          }, error=>{
            this.loading = false;
            Swal.fire("error",`${Popup.ForgotPasswordError}`, "error");
        });
    }

}
