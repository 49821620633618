import { Component, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService } from '@app/_services';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html'
})
export class FriendListComponent implements OnInit {

  defaultProfile: string = environment.defaultProfile;
  loading = true;
  constructor(private friendsService: FriendsService, private eleRef: ElementRef, private renderer: Renderer2 ) { }
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  btnloading = false;
  UiBlock = false;
  @Input() friend: any;
  profilePic: any;

  ngOnInit(): void {
    this.profilePic = this.friend.profile;
    console.log(this.friend)
  }

  unblockFriend(id: any){
    this.btnloading = true;
    const data = {"userId": this.userId,
                  "friendId": id
                  };
    this.friendsService.unblockFriend(data).subscribe( res => {
      this.btnloading = false;
      this.UiBlock = true;
      this.renderer.addClass(this.eleRef.nativeElement.parentElement,'d-none');
      this.btnloading = false;
    });
  }


}
