import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsServices {
  BaseUrl: any = environment.apiUrl;
  constructor(private http: HttpClient) { }

  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
  };

  getnotification(): Observable<any> {
    return this.http.get<any>(this.BaseUrl + '/get-usernotification', this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  updatenotificationbyID(id): Observable<any> {
    return this.http.get<any>(this.BaseUrl + '/markas-read/' + id, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getnotificationbyID(id): Observable<any> {
    return this.http.get<any>(this.BaseUrl + '/get-singlenotification/' + id, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  requestaccept(body): Observable<any> {
    return this.http.post<any>(this.BaseUrl + '/make-friend', body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  requestreject(body): Observable<any> {
    return this.http.post<any>(this.BaseUrl + '/delete-sent-request', body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  groupaccept(body): Observable<any> {
    return this.http.post<any>(this.BaseUrl + '/accept-request', body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  groupreject(body): Observable<any> {
    return this.http.post<any>(this.BaseUrl + '/reject-request', body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  likeImageNotifi(body): Observable<any> {
    return this.http.post<any>(this.BaseUrl + '/store-album-image-like', body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // get single album
  getAlbumID(body): Observable<any> {
    return this.http.get<any>(this.BaseUrl + '/gealbum-byalbumid/' + body, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  // get single Video
  getVideoID(body) {
    return this.http.get(this.BaseUrl + '/get-videobyid/' + body, this.httpOptions).pipe(retry(1),
      catchError(this.handleError));
  }
  // get single event

  getEventID(body) {
    return this.http.get(this.BaseUrl + '/getevent-byeventid/' + body, this.httpOptions).pipe(retry(1),
      catchError(this.handleError));
  }

  // get single hot date 
  getHotDateID(body) {
    return this.http.get(this.BaseUrl + '/gethotdate-byid/' + body, this.httpOptions).pipe(retry(1),
      catchError(this.handleError));
  }
  // get single event 
  geteventID(body) {
    return this.http.get(this.BaseUrl + '/gethotdate-byid/' + body, this.httpOptions).pipe(retry(1),
      catchError(this.handleError));
  }
  // get single timeline 
  gettimelineID(body) {
    return this.http.get(this.BaseUrl + '/gettimeline-bytimelineid/' + body, this.httpOptions).pipe(retry(1),
      catchError(this.handleError));
  }

    // get single timeline 
    getSingleImageID(body) {
      return this.http.get(this.BaseUrl + '/get-single-album-image/' + body, this.httpOptions).pipe(retry(1),
        catchError(this.handleError));
    }

  // update notification
  updatenotifi(body):Observable<any>{
   return this.http.post<any>(this.BaseUrl+'/update-notification', body, this.httpOptions).pipe(
     retry(1),
     catchError(this.handleError)
   );
  }




}