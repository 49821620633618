<section class="hotest-member" >
  <div class="container-fluid" >
    <div class="text-center mt_2 mb_2">
      <h2 class=""><span class="text_color1 mr-2">Meet</span> <span class="text_color2">Our Hottest </span> <span class="text_color1 mr-2">Members</span></h2>
        </div>
    <div class="container">
      <div class="row  ">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide  *ngFor="let data of resdata | slice:0:6; let i=index">
              <div class="card text-center bg_colors cards card-with">
                <div class="imgcontainer">
                  <i class="fas fa-circle active"></i>
                  <img class="card-img-top" [src]="data.profile" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                    alt="Card image cap">
                </div>
                <div class="card-body">
                  <p class="textName">{{data?.name}}</p>
                  <p class="textGender" *ngIf="data.gender == 'm'">Male</p>
                  <p class="textGender" *ngIf="data.gender == 'f'">Female</p>
                  <p class="textGender" *ngIf="data.gender == 'c'">Couple</p>
                  
                </div>
              </div>
            </ng-template>
        </owl-carousel-o>

         

        </div>

      </div>
    </div>

    <div class="container">
      <div class="row ">
        <div class="col-md-12">
          <div class="text-center mtb_15">
            <button class="btn btn-warning" (click)="gotodiscoverpages()"> MEET ALL MEMBERS </button>
          </div>
        </div>

      </div>
    </div>
    

  </div>
</section>