import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, PlanService, GlobalLocationsService } from '@app/_services';
import { environment, Popup } from '@environments/environment';
import { first, map } from 'rxjs/operators';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { commonData } from '@app/_helpers/common';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})

export class RegisterUserComponent implements OnInit {
  sitelogo = environment.siteLogolight;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  data: any;
  options: FlatpickrDefaultsInterface;
  plans: any;
  planFeatures: any;
  freePlan = false;
  closeResult = '';
  termData = false;
  countries: any;
  states: any;
  cities: any;
  checkPlanId = true;
  checkTermsCondition = false;
  currentUser: any;
  isLogin = false;
  checkPlanIdSession = sessionStorage.getItem('planId');
  genders = commonData.gender;
  mapShow = false;

  hide = true;
  hides = true;
  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private planService: PlanService,
    private modal: NgbModal,
    private globalLocation: GlobalLocationsService,
    private Title:Title
  ) {
    this.Title.setTitle('Register User | Swingerflings')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser != null) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });

    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      maxDate: '2002-01',
      enable: [
        {
          from: '1860-04-01',
          to: '2002-04-01',
        },
      ],
    };
  }

  selectedPlanDetails: any;

  @ViewChild('selectedPlan') selectedPlan: ElementRef;
  @ViewChild('termsCondition', { static: false }) termsCondition: TemplateRef<any>;

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      address: [''],
      plan_id: ['1', Validators.required],
      password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      c_password: ['', Validators.required],
      zipcode: ['', Validators.required],
      terms: ['1', Validators.required],
    }, {
      validator: MustMatch('password', 'c_password')
    });

    this.getPlans();
    this.checkValidPassword();
  }

  get f() { return this.registerForm.controls; }

  checkValidPassword() {
    this.registerForm.get('password').valueChanges.subscribe(paw => {
    })
  }



  registerUser() {
    this.submitted = true;
    const termCheck = this.registerForm.get('terms').value;
    if (termCheck == '' && this.registerForm.invalid) {
      this.checkTermsCondition = true;

      return;
    }
    this.loading = true;
    if (this.f.password.status == 'VALID') {
      this.authenticationService.register(this.registerForm.value).pipe(first()).subscribe(data => {
        if (Number(this.checkPlanIdSession) != 1) {
          this.router.navigate([`/check-out`], { queryParams: { id: this.checkPlanIdSession, uid: data.user_id } });
        } else {
          Swal.fire(
            'Success',
            `${Popup.register}`,
            'success'
          );
          this.router.navigate([`/login`]);
        }
        this.registerForm.reset();
      }, (error) => {
        // console.log(error);
        const errorData = JSON.parse(sessionStorage.getItem('error')).errors;
        if (errorData) {
          Swal.fire('Error', `${errorData[0]}`, 'error');
          sessionStorage.removeItem('error');
          this.loading = false;
        }
      });
    } else {
      this.loading = false;
      return;
    }

  }


  getlatitudeLongitude(mapData: any) {
    this.registerForm.get('latitude').setValue(mapData.lat);
    this.registerForm.get('longitude').setValue(mapData.lng);
    this.registerForm.get('address').setValue(mapData.address);
    this.registerForm.get('zipcode').setValue(mapData.postalCode);
  }

  getPlans() {
    this.planService.getPlans().subscribe(res => {
      this.plans = res.data;
      this.checkActiveUrl();
      if (this.checkPlanId) {
        this.getFeaturesById();
      }
    }, (error) => {
      console.log(error);
    });
  }

  getDataId(name: string, dataList: any) {
    const obj = dataList.find(resData => {
      if (resData.name == name) {
        return resData;
      }
    });
    return obj?.id;
  }


  checkActiveUrl() {
    this.activeRoute.queryParams.subscribe(activeUrlData => {
      const planId = activeUrlData.id;
      if (planId) {
        this.registerForm.get('plan_id').setValue(planId);
        this.checkPlanId = false;
        this.getFeaturesById();
      } else {
        this.checkPlanId = true;
      }
    });
  }

  getFeaturesById() {
    const planId = (this.registerForm.get('plan_id').value) ? this.registerForm.get('plan_id').value : 1;
    this.checkPlanIdSession = planId;
    sessionStorage.setItem('planId', planId);
    const plan = this.plans.find(obj => obj.id == planId);
    if (plan.price === '0') {
      this.freePlan = true;
    } else {
      this.freePlan = false;
    }

    this.planService.getFeaturesWithPlanId(planId).subscribe(res => {
      this.selectedPlanDetails = plan;
      this.planFeatures = res.data;
    }, (err) => {
      Swal.fire(
        'Error',
        `${Popup.planID}`, 'error'
      );
    });

  }

  showTerms() {
    this.modal.open(this.termsCondition, { size: 'xl', windowClass: 'dark-modal' });
  }

  closeTerms(disAgree?: any) {
    if (disAgree == 0) {
      this.modal.dismissAll(this.termsCondition);
      this.registerForm.get('terms').setValue(0);
    } else {
      this.modal.dismissAll(this.termsCondition);
    }
  }

  termsOnChange(agree?: any) {
    if (agree == 1) {
      this.registerForm.get('terms').setValue(1);
      this.checkTermsCondition = false;
    } else {
      this.termData = this.registerForm.get('terms').value;
      if (this.termData === true) {
        this.registerForm.get('terms').setValue(1);
        this.checkTermsCondition = false;
      } else {
        this.registerForm.get('terms').setValue(0);
        this.checkTermsCondition = true;
      }
    }
  }


  checkAgeStatus() {
    const stateName = this.registerForm.get('dob').value;
    if (stateName == 0) {
      Swal.fire(
        'Age Restriction',
        `${Popup.checkAge}`,
        'error'
      );
      this.registerForm.reset();
    }
  }

}




