<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/dashboard/create-album"  routerLinkActive="active"><i class="fas fa-images"></i> Albums</a>
                  </li>

                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/upload-photos" routerLinkActive="active"><i class="fas fa-image"></i> Timeline</a>
                  </li>

                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/upload-videos" routerLinkActive="active"><i class="fas fa-image"></i> Videos</a>
                  </li>

                  <!-- <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/upload-audio" routerLinkActive="active"><i class="fas fa-file-audio"></i> Audio</a>
                  </li> -->
                </ul>
          </div>
      </div>
  </div>
  </div>
  