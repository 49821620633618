import { Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, NgZone, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService, SwingersService } from '@app/_services';
import { MessagesService } from "../../_services/messages.service";
import Swal from 'sweetalert2';
import { Observable, timer, BehaviorSubject } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Message } from "../../_models/chat";
import { Title } from "@angular/platform-browser";
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
@Component({
  selector: 'message',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})

export class InboxComponent implements OnInit {
  socket: any;
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;
  loading = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  submitted = false;
  @ViewChild('mobilenavWr') elRef: ElementRef;
  @ViewChild('userNavds') userNavds: ElementRef;
  message: FormGroup;
  messagepopup: FormGroup;
  userIds: any;
  UserName: any;
  UsermsgID: any;
  swingersData: any = [];
  senderIDs;
  notselectusers = false;
  loadingform = false;
  timer = timer(5000, 8000);
  allmessages: Message[] = [];
  shareData: any
  receiver = new Array(this.allmessages.length);
  sender = new Array(this.allmessages.length);
  nodata = true;
  userdata: any;
  loadingmsg = false;
  public content = new BehaviorSubject<any>(this.allmessages);
  public share = this.content.asObservable();
  currentUser: any;
  freeuser = false;


  constructor(
    private swingersService: SwingersService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private MessagesService: MessagesService,
    private authenticationService: AuthenticationService,
    private Title:Title
  ) {
    this.Title.setTitle('Message | Swingerflings')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser.plan_id === 1) {
        this.freeuser = true;
      }
    });

    this.route.queryParams.subscribe(params => {
      const ids = params['uid'];
      if (ids == undefined || ids == null || ids == '') {
        this.notselectusers = true;

        return;
      } else {
        this.senderIDs = ids;
        this.getUserMessage(this.senderIDs);
        this.timer.subscribe(() => { });
      }
    })
    // this.socket = io('http://localhost:3000')
    this.socket = io(environment.SOCKET_ENDPOINT);
    this.socket.on('my broadcast', (data: any) => {
      if (data) {
        // console.log(data)
        setTimeout(() => 
        this.getUserMessage(this.senderIDs), 1000);
      }
    });
    
      this.toggled = false;
  

  }

  ngOnInit(): void {
    this.getallmsg();
    this.message = this.formBuilder.group({
      messagess: ['', Validators.required],
    });
    this.messagepopup = this.formBuilder.group({
      messagesss: ['', Validators.required],
    });

    this.loadMessages(this.pagination.currentPage).subscribe(res =>
      this.handleResponse(res)
    );
  }
  messages;
  pagination = {
    currentPage: 0,
    totalPages: 100,
    itemsPerPage: 10
  }
  mockMessage = 'Lorem ipsum dolor sit amet. ';

  @ViewChild('scrollMe', { static: false }) scrollDiv;

   mockMessages(page: number, count: number): Observable<any> {
    return of({
      result: {
        messages: Array.apply(null, Array(count)).map((value, index) => 
          `${ index + (page * count) } - ${this.mockMessage.repeat(10)}`
        )
      }
    });
  }

  handleResponse(res) {
    this.loading = false;
    ++this.pagination.currentPage;
    if (this.messages == null) {
      this.messages = res.result.messages;
    } else {
      this.messages = this.messages.concat(res.result.messages);
    }
    console.log('messages', this.messages.length);
    console.log('page', this.pagination.currentPage);
  }


  loadMessages(page: number): Observable<any> {
    this.loading = true;
    return this.mockMessages(page, this.pagination.itemsPerPage).pipe(
      delay(200)
    );
  }


  onScroll(event) {
    const startingScrollHeight = event.target.scrollHeight;
    if (event.target.scrollTop < 100) {
      if (this.pagination.currentPage >= this.pagination.totalPages) {
        return;
      }

      else if (!this.loading) {
        console.log('load messages');
        this.loadMessages(this.pagination.currentPage).subscribe((res) => {
          this.handleResponse(res);
          setTimeout(() => {
            const newScrollHeight = this.scrollDiv.nativeElement.scrollHeight;
            this.scrollDiv.nativeElement.scrollTo(0, newScrollHeight - startingScrollHeight);
          });
        });
      }
    }
  }



  toggled: boolean;
  messagess: String = "";
  handleSelection(event) {
    this.messagess = this.messagess + "" + event.char;
    // this.toggled = false;
  }

  applyFilters(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
  }

  showmessageusers(receiver_id1) {
    this.senderIDs = receiver_id1
    this.getUserMessage(receiver_id1);
    this.router.navigate(['/dashboard/message'], { queryParams: { uid: receiver_id1 } });
    this.getallmsg();
  }

  scroll() {
    let objDiv = document.getElementById("parentDiv");
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  userblock = true;
  today: number = Date.now();
  getUserMessage(id: any) {
    this.loadingmsg = true;
    this.receiver.length = 0;
    this.sender.length = 0;
    this.MessagesService.getallmessage(id).subscribe(res => {
      this.allmessages.length = 0;
      this.allmessages = res.data;
      this.getallmsg();
      let itemIndex = this.allmessages.findIndex(item => {
        this.allmessages[itemIndex] = item;
      });
      this.notselectusers = false;
      this.nodata = false;
      this.userdata = res.senderDetails;
      // console.log(this.userdata)
      if(this.userdata.user_status == 0){
        this.userblock = false;
      }else{
        this.userblock = true;
      }
      this.loadingmsg = false;
      for (let index = 0; index < this.allmessages.length; index++) {
        if (this.allmessages[index].to_user_id == this.userId) {
          this.receiver[index] = true;
        }
        if (this.allmessages[index].to_user_id == this.senderIDs) {
          this.sender[index] = true;
        }
        this.scroll();
      }
      if (res.data.length == 0) {
        this.nodata = true;
      }
    })
  }

  trackHero(index, data) {
    return data ? data.id : undefined;
  }

  selectUser(name, id) {
    this.UserName = name;
    this.senderIDs = id;
    this.swingersData = null;
  }


  get f() { return this.message.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.message.invalid) {
      return;
    }
    let body = {
      to_user_id: this.senderIDs,
      message: this.message.value.messagess,
      dateTime: this.today
    }
    this.loadingform = true;
    this.MessagesService.sendmessage(body).subscribe(res => {
      this.messagess = null;
      if (res != null) {
        this.loadingform = false;
        this.message.reset();
        this.closeHotdateModal();
      }
    })
    this.socket.emit('my message', body);
  }

  get fs() { return this.messagepopup.controls; }
  onSubmitpopup() {
    this.submitted = true;
    if (this.messagepopup.invalid) {
      return;
    }
    let body = {
      to_user_id: this.senderIDs,
      message: this.messagepopup.value.messagesss,
      dateTime: this.today
    }
    this.loadingform = true;
    this.MessagesService.sendmessage(body).subscribe(res => {
      if (res != null) {
        this.loadingform = false;
        this.message.reset();
        this.closeHotdateModal();
        this.getallmsg();
      }
    })
    this.socket.emit('my message', body);
  }



  checkNav = 0;
  checkDiviceWidth = window.innerWidth;
  mobileNav(event: any) {
    if (this.checkNav == 0) {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
      this.renderer.addClass(this.elRef.nativeElement, "nav-show");
      this.checkNav = 1;
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
      this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
      this.checkNav = 0;
    }
  }



  openAddNewHotdateModal() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    };
    this.modal.open(this.SentMessageModalContent, ngbModalOptions);
  }

  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name: filterValue
    }
    this.swingersService.searchswing(body).subscribe(res => {
      this.swingersData = res.data.data
    })
  }


  messagesData: any;
  countmessages: any;
  isUserdata = true;
  loadingssss = false;
  getallmsg() {
    this.MessagesService.getallusermessage().subscribe(res => {
      this.messagesData = null
      this.messagesData = res.data;
      // console.log(this.messagesData)
      if (this.messagesData.length == 0) {
        this.isUserdata = false;
        this.loadingssss = false;
      } else {
        this.isUserdata = true;
      }
    })
  }
  nodatas = false;
  searchInboxdata(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let body = {
      name : filterValue
    }
    this.MessagesService.searchInboxList(body).subscribe(res=>{
      this.messagesData = null;
      this.messagesData = res.data;
      // console.log(res)
      this.nodatas = false;
      if(res.message == "Data not found ! "){
       this.nodatas = true;
      }
     
    })
  }
  gotoprofile(user_status, receiver_id1){
    console.log(user_status, receiver_id1)
   if(user_status == 0){
     this.router.navigate([`/user-not-exist`])
   }else{
    this.router.navigate([`${receiver_id1}/swinger/profile`]); 
   }
  }


  deletechates() {
    Swal.fire({
      title: 'Are you sure want to delete all message history?',
      text: 'You will not be able to recover this message record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.MessagesService.clearAllmessage(this.senderIDs).subscribe(res => {
          console.log(res)
          Swal.fire(
            'Success',
            `${res.message}`, 'success',

          );
          this.getUserMessage(this.senderIDs);
        }, (error) => {
          Swal.fire(
            'Server Error',
            'Something is wrong please try again.',
            'error'
          );
        });
      }
    });
  }
}