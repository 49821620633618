<app-swinger-navigation></app-swinger-navigation>
<div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp">
  <div class="card-header text-left">
    <h6>Videos
      <!-- <span style="float: right;"><input class="text" (keyup)="applyFilter($event)" placeholder="search video.."
          #input>
          <i class="fa fa-search icon"></i></span> -->
    </h6>
  </div>
  <div class="card-body over_flow_wrapper_big" #scrollMe style="width: 100%; max-height: 1000px; overflow: auto;">
    <div *ngFor="let swinger of swingers; let i=index">
      <div class="post-panel infinite-loader-sec section-t-space">
        <div class="post-wrapper col-grid-box section-t-space">
            <div class="post-title mb-1">
                <div class="profile">
                    <div class="media nav-itemss dropdown">
                        <a class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="true">
                            <div class="user_profile_ls">
                                <img [src]="swinger?.profilepic"
                                    onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                                    class="img-fluid">
                            </div>
                        </a>
    
                        <a class="dropdown-menu" aria-labelledby="user_profile">
                            <li><a class="dropdown-item"><i class="fa fa-user" aria-hidden="true"></i> Profile</a></li>
                            <li><a class="dropdown-item"><i class="fa fa-envelope-o" aria-hidden="true"></i> Message</a>
                            </li>
                        </a>
                        <div class="media-bodys">
                            <div class="media-cont" style="text-align: left;">
                                <h5>{{swinger?.username}}</h5>
                                <h6 class="created_at" style="color: gray;">{{swinger?.created_at| date: 'dd-MMM-yyyy'}}</h6>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
    
    
            <div class="post-details">
                <div class="img-wrapper">
                    <div style="width:100%;height:0;padding-bottom:45%;position:relative;">
                        <video id="video"  #videoplayer controls>
                      <source src="{{swinger.src}}" type="video/mp4">
                      </video>
                      <!-- <button (click)="playVideo(i)">Play/Pause</button> -->
                    </div>
                </div>
                <div class="detail-box">
                    <div class="like-panel mt-2">
                        <div class="right-stats">
                            <p class="text-left">
                                <span (click)="videoLike(swinger?.id, i)">
                                    <i class="fa fa-thumbs-up"
                                        aria-hidden="true"></i><span>&nbsp;{{swinger?.totallikes}}</span> like
                                </span>&nbsp;
                                <span  (click)="togglecomments(swinger?.id, swinger?.user_id, i)" (click)="togglePanelPar[i] = !togglePanelPar[i]">
                                    <i class="fa fa-comment" aria-hidden="true"></i><span>&nbsp;{{swinger?.totalcomment}}</span> comment
                                </span>
    
                            </p>
                        </div>
                    </div>
    
                    <div class="comment-section" *ngIf="togglePanelPar[i]">
                        <div class="comments d-block">
                            <div class="main-comment" *ngFor="let data of swinger.comment; let ind=index">
                                <div class="media" style="text-align: left;">
                                        <div class="user_profile_lscom">
                                            <img [src]="data?.profile"
                                                onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                                                class="img-fluid">
                                        </div>
                                    <div class="media-body">
                                        <h5>{{data?.user_name}}</h5>
                                        <h6>{{data?.comment_date}}</h6>
                                        <p>{{data?.comment_content}} &#128578;
                                            <span style="float: right;" class="media nav-itemss dropdown">
                                                <i class="fa fa-ellipsis-v" aria-hidden="true" id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="true"></i>
                                                
                                            </span></p>
                                            <a class="dropdown-menu" aria-labelledby="user_profile">
                                                <li><a class="dropdown-item"><i class="fa fa-user" aria-hidden="true"></i> Profile</a></li>
                                                <li><a class="dropdown-item"><i class="fa fa-envelope-o" aria-hidden="true"></i> Message</a>
                                                </li>
                                            </a>
                                        <p class="text-left">
                                            <span (click)="commentLike(data?.id, ind)">
                                                <i class="fa fa-thumbs-up"
                                                    aria-hidden="true"></i><span>&nbsp;{{data?.totalLikes}}</span> like
                                            </span>&nbsp;
                                            <span (click)='replyscomm(data.id, ind)' (click)="togglePanel[ind] = !togglePanel[ind]">
                                                <i class="fa fa-reply" aria-hidden="true"></i><span>&nbsp;</span> Reply
                                            </span>
                
                                        </p>

                                        <div class="reply" *ngIf="togglePanel[ind]">
                                            <form [formGroup]='repcommentsForm' >
                                            <div class="input-group mb-3">
                                               
                                                <input type="text" class="form-control" formControlName="comment_content" placeholder="comments..." aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                <div class="input-group-append">
                                                  <button class="btn btn-primary" type="button" (click)="reycommetSent()"> 
                                                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                              </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                                <div class="sub-comment" *ngFor="let data of data.data; let i=index">
                                    <div class="media" style="margin-left: 3rem; text-align: left;">
                                        <div class="user_profile_lscom">
                                            <img [src]="swinger?.profilepic"
                                                onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile"
                                                class="img-fluid">
                                        </div>
                                    <div class="media-body">
                                        <h5>{{data?.user_name}}</h5>
                                        <h6>{{data?.comment_date}}</h6>
                                        <p>{{data?.comment_content}} &#128578;
                                        </p>
                                        <p class="text-left">
                                            <span (click)="commentRepLike(data?.id, i, ind)">
                                                <i class="fa fa-thumbs-up"
                                                    aria-hidden="true"></i><span>&nbsp;{{data?.totalLikes}}</span> like
                                            </span>&nbsp;
                                            <span (click)='replyscomm(data.id)'>
                                                <i class="fa fa-reply" aria-hidden="true"></i><span>&nbsp;</span> Reply
                                            </span>
                
                                        </p>

                                        <div class="reply" *ngIf="togglerep">
                                            <form [formGroup]='repcommentsForm' >
                                            <div class="input-group mb-3">
                                               
                                                <input type="text" class="form-control" formControlName="comment_content" placeholder="comments..." aria-label="Recipient's username" aria-describedby="basic-addon2">
                                                <div class="input-group-append">
                                                  <button class="btn btn-primary" type="button" (click)="reycommetSent()"> 
                                                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                              </div>
                                            </form>
                                        </div>
                                    </div>
                                </div> 
                              </div>
                            </div>
                          
                        </div>
                        <div class="reply">
                            <form [formGroup]='commentsForm' >
                            <div class="input-group mb-3">
                               
                                <input type="text" class="form-control" formControlName="comment_content" placeholder="comments..." aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <div class="input-group-append">
                                  <button class="btn btn-primary" type="button" (click)="commetSent(i)"> 
                                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                    </button>
                                </div>
                              </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-text text-center" *ngIf="nodatainsearch">
      <h6>Sorry, this video isn't available</h6>
    </div>

    <div class="card-text text-center" *ngIf="loading">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf=" pagelarg">
    <div class="col-md-12 text-center">
      <nav aria-label="..." class="text-center">
        <ul class="pagination justify-content-center">
          <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
            [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
              [innerText]="link.label"></a></li>
        </ul>
      </nav>
    </div>
  </div>

</div>




