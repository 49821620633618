import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { GroupPanelRoutingModule } from './group-panel-routing.module';
import { GroupTopPanelComponent } from './group-top-panel/group-top-panel.component';
import { GroupRightPanelComponent } from './group-right-panel/group-right-panel.component';
import { AddGroupPostComponent } from './add-group-post/add-group-post.component';
import { EditGroupInfoComponent } from './edit-group-info/edit-group-info.component';


@NgModule({
  declarations: [GroupTopPanelComponent, 
    GroupRightPanelComponent,
    AddGroupPostComponent,
    EditGroupInfoComponent,
  ],
  imports: [
    CommonModule,
    GroupPanelRoutingModule,
    ReactiveFormsModule
  ]
})

export class GroupPanelModule { }
