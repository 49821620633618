import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators } from '@angular/forms';

@Component({
  selector: 'app-add-group-post',
  templateUrl: './add-group-post.component.html',
  styleUrls: ['./add-group-post.component.css']
})
export class AddGroupPostComponent implements OnInit {

  constructor(private fb: FormBuilder ) { }

  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  openModel = false;
  postForm:FormGroup;


ngOnInit(): void {
 this.postForm = this.fb.group({
      userID: [this.userId],
      post_text: [" "],
      post_images: [" "],
      post_videos: [" "],
      group_id: [" "],
      privacy: ["0", Validators.required],
    });
  }

  
  openPostModal() {
    this.openModel = true;
  }

  closePostModal() {
    this.openModel = false;
  }

  postSubmit(){
    console.log('hi post is sumitted.');
  }

}
