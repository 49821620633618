<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-1">
              <ul class="nav">
               
                <li class="nav-item">
                  <a class="btn btn-light " style="font-size:10px ; font-family:verdana" routerLink="/dashboard"  routerLinkActive="active"><i class="fa fa-user-o" aria-hidden="true"></i> Swingers</a>
                 </li>
                <li class="nav-item ">
                  <a class="btn btn-light" style="font-size:10px ; font-family:verdana"  routerLink="/dashboard/videos"  routerLinkActive="active"><i class="fa fa-video-camera" aria-hidden="true"></i> Public Videos</a>
                </li>
                <li class="nav-item ">
                  <a class="btn btn-light " style="font-size:10px ; font-family:verdana"  routerLink="/dashboard/photos"  routerLinkActive="active"><i class="fa fa-picture-o" aria-hidden="true"></i> Public Photos</a>
                </li>
                
                <!-- <li class="nav-item m-view">
                  <a class="btn btn-light " routerLink="/dashboard/swingers"  routerLinkActive="active"><i class="fa fa-user-o" aria-hidden="true"></i> Swingers</a>
                </li> -->

                  <li class="nav-item">
                    <a class="btn btn-light " style="font-size:10px ; font-family:verdana" routerLink="/dashboard/who-viewed"  routerLinkActive="active"><i class="fa fa-user-o" aria-hidden="true"></i> Who Viewed Me</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" style="font-size:10px ; font-family:verdana" routerLink="/dashboard/matches" routerLinkActive="active"><i class="fa fa-id-badge" aria-hidden="true"></i> Matches</a>
                  </li>
                 
                 
                </ul>
          </div>
      </div>
  </div>
  </div>
  