import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { HotdatesComponent } from '@app/hot-dates/hotdates/hotdates.component';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from "../../_services/messages.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-user-online',
  templateUrl: './user-online.component.html',
  styleUrls: ['./user-online.component.css']
})
export class UserOnlineComponent implements OnInit {
  message: FormGroup;
  loadings = false;
  UseName:any;

  defaultProfile = environment.defaultProfile;
  loading = true;
  currentUser: any;
  constructor(
    private friends: FriendsService, 
    private router:Router,  
    private authenticationService: AuthenticationService,
    private modal: NgbModal,
    private formBuilder: FormBuilder,
    private MessagesService:MessagesService,
    private Title:Title) {
      this.Title.setTitle('Online Swingers | Swingerflings')
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser.plan_id == 1) {
        this.freeuser = true;
      }
    });
   }
  friendsData:any[] = [];
  pagination:any;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  @ViewChild(HotdatesComponent) hotdates: HotdatesComponent;
  @ViewChild('SentMessageModalContent', { static: false }) SentMessageModalContent: TemplateRef<any>;

  ngOnInit(): void {
    this.getOnlineFriends();
    this.message = this.formBuilder.group({
      messagess: ['', Validators.required],
    });
  }
  freeuser = false;
  pagelarg = true;
  dataNotFound = false;
  blockedUser = new Array(this.friendsData.length)
  getOnlineFriends(){
    this.friends.getOnlineFriendslist().subscribe(data =>{
      this.loading = false;
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.friendsData = data.data.data;
  
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
     if(this.friendsData.length == 0 ){
      this.loading = false;
      this.dataNotFound = true;
    }
    }, error => {
      this.loading = false;
    });
  }

  today: number = Date.now();
  toggled: boolean = false;
  messagess: string = '';
  
  handleSelection(event) { 
    this.messagess += event.char;
    // console.log(this.messagess)
  }
   // convenience getter for easy access to form fields
   get f() { return this.message.controls; }
   senderIDs:any;
   MessagesRes:any;
   onSubmit() {
     if (this.message.invalid) {
       return;
     }
     this.loadings = true;
     let body = {
      to_user_id: this.senderIDs,
      message: this.message.value.messagess,
      dateTime:this.today
    }
     this.loadings = true;
     this.MessagesService.sendmessage(body).subscribe(res=>{
       if(res != null){
         this.loadings = false;
         this.MessagesRes = res.message
         this.message.reset();
         this.closeHotdateModal();
         this.sentMessages(this.senderIDs)
       }
     }, error => {
       this.loadings = false;
   });
   }

   openAddNewHotdateModal(id, name) {
    this.UseName = name;
    this.senderIDs = id
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'lg'
};
    this.modal.open(this.SentMessageModalContent,ngbModalOptions);
  }



  closeHotdateModal() {
    this.modal.dismissAll(this.SentMessageModalContent);
  }

  sentMessages(id){
   this.router.navigate(['/dashboard/message'], {queryParams:{uid:id}})
  }
}
