import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "@app/_helpers";
import { GroupTopPanelComponent } from './group-top-panel/group-top-panel.component';
import { EditGroupInfoComponent } from './edit-group-info/edit-group-info.component';
import { AddGroupPostComponent } from './add-group-post/add-group-post.component';

const routes: Routes = [  {
  path: "group-panel/:id",
  component: GroupTopPanelComponent,
  canActivate: [AuthGuard],
  children: [
    { path: "edit-group-info", component: EditGroupInfoComponent },
    { path: "post", component: AddGroupPostComponent },
    
  ],
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupPanelRoutingModule { }
