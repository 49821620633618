import { Component, OnInit} from '@angular/core';
import { environment, Popup } from '@environments/environment';
import { Router } from '@angular/router';
import { PlanService, UserMediaService } from '@app/_services';
import { AuthenticationService } from '@app/_services';
import { User } from '@app/_models';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 


@Component({
  selector: 'app-user-navigation',
  templateUrl: './user-navigation.component.html',
  styleUrls: ['./user-navigation.component.css']
})
export class UserNavigationComponent implements OnInit{

  defaultProfile: any = environment.defaultProfile;
  coverImgURL: any = '../assets/images/user_dashboard.jpg';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  coverImagePath: any;
  dataId: any = '';
  profileId: any = '';
  profileloading = false;
  coverLoader = false;
  defaultNavigation = true;
  plans: any;
  emailAddress:any;
  showUnverified = false;
  showpaymentPopup:boolean = false;
  currentUser: User;
  username: any;
  isLogin: boolean;
  subscribtion:any;
  userData: any;
  emailStatus= true;
  planstatus = true;
  emailVerify = true
  emailStatuss = true
  constructor(
     private userMedia: UserMediaService,
     private router: Router,
     private planService: PlanService,
     private authenticationService: AuthenticationService,
     ) { 
      this.authenticationService.currentUser.subscribe(x => {
        // console.log(x)
        this.currentUser = x;
        
        if(x.email_status!=1){
          this.emailStatus = false;
          this.emailStatuss = false;
        }
        if(x.plan_status !=1){
          this.planstatus = false;
          this.emailStatuss = true
        }

        if ( this.currentUser != null ){
            this.username = this.currentUser.firstName;
            this.emailAddress = this.currentUser.email;
            // console.log(this.currentUser.email.substring(8))
            this.isLogin = true;
        }else{
            this.isLogin = false;
        }
    });

      if(this.currentUser?.email_status == 0){
        this.showUnverified = true;
        this.emailVerify = true;
        this.defaultNavigation = false;
        this.emailStatuss = false;
      }else{
        this.emailVerify = false;
        this.emailStatuss = true;
      }

      if(this.currentUser?.plan_status == 0){
        this.showUnverified = true;
        this.defaultNavigation = false;
        this.showpaymentPopup = true;
        // this.emailStatuss = true
      }else{
        this.showpaymentPopup = false;
      }

     }

 
  planids = true;
  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('currentUser'));
    this.username = this.userData.name;
    this.getUserCover();
    this.getUserProfile();
    this.getPlan(this.currentUser.plan_id);
    if(this.currentUser.plan_id == 0){
      this.planids = false;
    }
  }

  checkImageSize(file){
    if(file.size > 5097152){
      Swal.fire(
        `File size error "${file.name}" file greater then 5 MB`,
        `make sure less then 5 MB then upload file.`,
        "error"
      );
     return false;
    }else{
      return true;
    }
  }


  changeCoverPage(files: File, id: any){
    
    const checkFile = this.checkImageSize(files[0]);
    if(checkFile){
      this.coverLoader = true;
      let reader = new FileReader();
      this.coverImagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.coverImgURL = reader.result;
      };
    const formData = new FormData();
    formData.append('is_cover', files[0], files[0].name);
    formData.append('id', id);
    formData.append('user_id', this.userId);
    this.userMedia.changeProfileCover(formData).subscribe( res => {
      this.coverLoader = false;
    });
    }
    
    
  }


  getUserCover(){
    this.userMedia.getProfileCover().subscribe(res => {
      this.coverImgURL = (res.data.cover)? res.data.cover : this.coverImgURL;
      this.dataId = res.data.id;
      this.profileId = res.data.id;
    });
  }


  changeProfile(files: File, id: any){
    const checkFile = this.checkImageSize(files[0]);
    if(checkFile){
    this.profileloading = true;
    let reader = new FileReader();
    this.defaultProfile = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.defaultProfile = reader.result;
    };  
  }

    const formData = new FormData();
    formData.append('is_profile_pic', files[0], files[0].name);
    formData.append('id', id);
    formData.append('user_id', this.userId);
    this.userMedia.changeProfile(formData).subscribe( res => {
      this.profileloading = false;
    });
  }

  getUserProfile(){
    this.userMedia.getProfile().subscribe(res => {
      this.defaultProfile = (res.data.profile) ? res.data.profile :this.defaultProfile;
      this.profileId = res.data.id;
      this.dataId = res.data.id;
    }, error => {
     console.log(error);
    });
  }
PlaneName:any;
  getPlan(id: number){
    this.planService.getPlanById(id).subscribe( res => {
    this.subscribtion = res.data;
    this.PlaneName = this.subscribtion.plan_name
    // console.log(this.PlaneName);
    },(error) => {
      console.log( error );
    });
  }

  closeVerifyPopup() {
    this.showUnverified = false;

  }
  closepayPopup(){
    this.showpaymentPopup = false;
  }
  gotopayment(){
    if(this.currentUser.plan_id == 0){
      this.router.navigate(['/plan'])
    }else{
      this.router.navigate([`/check-out`], { queryParams: { id: this.currentUser.plan_id, uid:this.currentUser.id}});
    }
    
  }

  sentagainlink(){
    this.userMedia.linksend().subscribe(res=>{
      Swal.fire(`Success`,
        `${res.message}`,
        "success"
      );
      console.log(res)
    }, error => {
      Swal.fire(`Error`,
      `${Popup.error}`,
      "error"
    );
     })
  }
}
