import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { UserService } from '@app/_services';
import { commonData } from '@app/_helpers/common';
@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

  constructor(private fb: FormBuilder, private userService: UserService) { }
  userPreference: FormGroup;
  submitted = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = false;
  ifSuccess = false;
  message: string;
  error = '';
  interests: any;
  inteIds: any;
  intrestData = [];
  pleasures = commonData.pleasures;
  lookingForList = commonData.pleasures;
  smokeDrink = commonData.smokeDrink;
  genderList = commonData.gender;

  ageData = ['18', '19', '20', '21',	'22',	'23',	'24',	'25',	'26', '27', '28', '29', '30','31',	'32',	'33',	'34',	'35',	'36',	'37',	'38',	'39',	'40', '41',	'42',	'43',	'44',	'45',	'46',	'47',	'48',	'49',	'50', '51',	'52',	'53',	'54',	'55',	'56',	'57',	'58',	'59',	'60', '61',	'62',	'63',	'64',	'65',	'66',	'67',	'68',	'69',	'70', '71',	'72',	'73',	'74',	'75',	'76',	'77',	'78',	'79',	'80', '81',	'82',	'83',	'84',	'85',	'86',	'87',	'88',	'89',	'90', '91',	'92',	'93',	'94',	'95',	'96',	'97',	'98', '99'];
  heightInInchs = Array('0','1','2','3','4','5','6','7','8','9','10','11');
  heightFeets = Array('3','4','5','6','7');
  orientations = Array('Straight','BI Curious','BI');

  ngOnInit(): void {
    this.getUseruserPreference();
    this.getInterests();
    this.userPreference = this.fb.group({
      userID: [this.userId],
      interests: this.fb.array([]),
      watch: [''],
      soft: [''],
      full: [''],
      lookingForMale: [''],
      lookingForFemale: [''],
      lookingForCouple: [''],
      f_height_feet: [''],
      f_height_in: [''],
      f_weight: [''],
      f_age: [''],
      f_smoke: [''],
      f_drink: [''],
      f_orientation: [''],
      m_height_feet: [''],
      m_height_in: [''],
      m_weight: [''],
      m_age: [''],
      m_smoke: [''],
      m_drink: [''],
      m_orientation: [''],
      like: ['a'],
      youngest_interested_in: [''],
      oldest_interested_in: [''],
      drink: [''],
      smoke: [''],
      // additional_info: ['',Validators.required],
    });

    this.lookingForList.push({
      id: 6,
      title: 'No Interest (Profile still viewable)',
      value: 5
 },{
   id: 7,
   title: 'Block Them (Profile not viewable)',
   value: 6
   });
    console.log(this.genderList)
  }

  get f() { return this.userPreference.controls; }

  onSubmit(){
   this.loading = true;
   this.submitted = true;
   this.userService.updateUserPreferences(this.userPreference.value).subscribe( res => {
    this.submitted = false;
    this.loading = false;
    this.ifSuccess = true;
    this.message = res.message;
   }, error => {
    this.error = error;
    this.loading = false;
   });
  }
  gender:any
  getUseruserPreference(){
    this.submitted = true;
    this.userService.getuser().subscribe( res => {
      this.gender = res.gender;
      console.log(res.gender);
      this.userPreference.controls.userID.setValue(res.id);
      this.userPreference.controls.f_height_feet.setValue(res.f_height_feet);
      this.userPreference.controls.f_height_in.setValue(res.f_height_in);
      this.userPreference.controls.f_weight.setValue(res.f_weight);
      this.userPreference.controls.f_smoke.setValue(res.f_smoke);
      this.userPreference.controls.f_drink.setValue(res.f_drink);
      this.userPreference.get('watch').setValue(res.watch);
      this.userPreference.get('soft').setValue(res.soft);
      this.userPreference.get('full').setValue(res.full);
      this.userPreference.get('lookingForMale').setValue(res.looking_for_male);
      this.userPreference.get('lookingForFemale').setValue(res.looking_for_female);
      this.userPreference.get('lookingForCouple').setValue(res.looking_for_couple);
      this.userPreference.controls.f_age.setValue(res.f_age);
      this.userPreference.controls.f_orientation.setValue(res.f_orientation);
      this.userPreference.controls.m_height_feet.setValue(res.m_height_feet);
      this.userPreference.controls.m_height_in.setValue(res.m_height_in);
      this.userPreference.controls.m_weight.setValue(res.m_weight);
      this.userPreference.controls.m_age.setValue(res.m_age);
      this.userPreference.controls.m_smoke.setValue(res.m_smoke);
      this.userPreference.controls.m_drink.setValue(res.m_drink);
      this.userPreference.controls.m_orientation.setValue(res.m_orientation);
      this.userPreference.controls.like.setValue(res.like);
      this.userPreference.controls.youngest_interested_in.setValue(res.youngest);
      this.userPreference.controls.oldest_interested_in.setValue(res.oldest);
      this.userPreference.controls.drink.setValue(res.like_drink);
      this.userPreference.controls.smoke.setValue(res.like_smoke);
    }, error => {
      console.log(error);
    });
   }

   getInterests(){
    this.submitted = true;
    this.userService.getInterests().subscribe( res => {
      this.interests = res.data;
      this.getUserInterests();
    }, error => {
      console.log(error);
    });
   }

   getUserInterests(){
    this.submitted = true;
    this.userService.getUserInterests().subscribe( res => {
      console.log(res);
      this.inteIds = JSON.parse(res.data.interest_id);
      this.interests.forEach(data => {
       let chid = this.inteIds.find(x => x == data.id );
       if(chid){
        data.checked = true;
        const intresteIDs = (this.userPreference.controls.interests as FormArray);
        intresteIDs.push(new FormControl(data.id));
       }else{
        data.checked = false;
       }
       this.intrestData.push(data);
      });
    }, error => {
      this.interests.forEach(data => {
         data.checked = false;
         this.intrestData.push(data);
       });
    });
   }

   onChange(interests: any, isChecked: boolean) {
    const intresteIDs = (this.userPreference.controls.interests as FormArray);
    if (isChecked) {
      intresteIDs.push(new FormControl(interests));
    } else {
      const index = intresteIDs.controls.findIndex(x => x.value === interests);
      intresteIDs.removeAt(index);
    }
  }




}
