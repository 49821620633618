<div *ngIf='!uiHide'>
    <div class="row g-0">
        <div class="col-3 col-md-2 pr-1">
            <div class="user_profile_ls"><img routerLink="/{{friend?.id}}/swinger/profile" [src]="(friend.profile == null)?defaultProfile:profilePic" alt="user-profile" class="img-fluid"></div>
        </div>
        <div class="col-9 col-md-10 pl-1">
          <div class="row">
            <div class="col-md-7 col-12">
              <div class="card-body">
                <h5 class="card-title"><a routerLink="/{{friend?.id}}/swinger/profile">{{friend.name}}</a></h5>
                <span *ngIf="friend?.gender != null" [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
            <!-- <p class="card-text text-muted">{{friend?.short_tag_line}}</p> -->
              </div>
            </div>
            <div class="col-md-5 col-12 m-flex-v">
              <div class="btn-group mt-4">
                <button type="button" class="btn btn-danger" (click)="removeFavourite(friend.id)"><div 
                  *ngIf="blockloading" class="spinner-border text-light spinner-border-sm" role="status">
                   <span class="visually-hidden"></span>
                 </div> Remove</button>
                </div>
            </div>
          </div>
        </div>
    </div>
      </div>
    