// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// export const environment = {
//     production: false,
//     apiUrl: 'https://demoapi.swingerflings.com/api',
//     imgUrl: 'https://demoapi.swingerflings.com/public/storage/uploads',
//     hostUrl: 'https://demoapi.swingerflings.com',
//     siteLogolight: '../assets/images/Swingerflings-png-logo.png',
//     siteLogoDark: '../assets/images/Swingerflings-logo.jpg',
//     defaultProfile: '../assets/images/avtar-image.jpg',
//     SOCKET_ENDPOINT: 'https://chat.swingerflings.com'
// };


// export const environment = {
//     production: false,
//     apiUrl: 'https://stagingapi.swingerflings.com/api',
//     imgUrl: 'https://stagingapi.swingerflings.com/public/storage/uploads',
//     hostUrl: 'https://stagingapi.swingerflings.com',
//     siteLogolight: '../assets/images/Swingerflings-png-logo.png',
//     siteLogoDark: '../assets/images/Swingerflings-logo.jpg',
//     defaultProfile: '../assets/images/avtar-image.jpg',
//     SOCKET_ENDPOINT: 'https://chat.swingerflings.com'
// };


// liveserver url
export const environment = {
    production: false,
    apiUrl: 'https://api.swingerflings.com/api',
    imgUrl: 'https://api.swingerflings.com/public/storage/uploads',
    hostUrl: 'https://api.swingerflings.com',
    siteLogolight: '../assets/images/Swingerflings-png-logo.png',
    siteLogoDark: '../assets/images/Swingerflings-logo.jpg',
    defaultProfile: '../assets/images/avtar-image.jpg',
    SOCKET_ENDPOINT: 'https://chat.swingerflings.com'
};


//https://api.swingerflings.com/
// export const environment = {
//     production: false,
//     apiUrl: 'http://127.0.0.1:8000/api',
//     hostUrl: 'http://127.0.0.1:8000',
//     imgUrl: 'http://127.0.0.1:8000/storage/uploads',
//     siteLogolight: '../assets/images/Swingerflings-png-logo.png',
//     siteLogoDark: '../assets/images/Swingerflings-logo.jpg',
//     defaultProfile: '../assets/images/avtar-image.jpg',
//     SOCKET_ENDPOINT: 'https://chat.swingerflings.com'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



export const Popup = {
    login : "The email & password did not match",
    register: "You have successfully registered as free account.",
    planID:"Something wrong please try again.",
    checkAge:"You are not 18+ old.",
    OldPasssUpdate:"Something wrong please try again.",
    freePlanInFO: "Due to new laws enacted by The US Government, we are making some changes to our business model and policies which limits access to our free members.",
    freePlanTitle:'(Members with a provide picture get 10 times more exposure.)',
    forgotpassword:'Password reset link send to your registered email. Please check your email.',
    ForgotPasswordError:'There is no account matching with this email id',
    error:"Something wrong please try again.",
    eventDateSate : 'Your event date record is safe :)',
    eventDeleteTitle :'Are you sure want to remove this event date record?',
    eventDatetext:'You will not be able to recover this event date record again!',
    eventCheckDate:'Please check start date and select valid end date.',
    lookingForfindOther:'',
    lookinforNoUser:'No Swingers Found!',
    HotDatecheckStartDate :'Please check start date and select valid end date.',
    HotDatetitle: 'Are you sure want to remove this hot date record?',
    HotDatetext: 'You will not be able to recover this hot date record!',
    HotDateSafe:'Your Hot Date record is safe :)',
    session:'Your session expired. Please login again',

    FriendBlocktitle: "Are you sure want to block this friend?",
    FriendBlocktext: "Block friend show in to friend block section.",
    FriendBlock:"Your friend successfully blocked.",
    FriendnotBlock:"Your friend not blocked. :)",

    CancelMemberShiptitle: 'Are you sure want to cancel membership plan?',
    CancelMemberShipSafe: 'Your membership plan is safe :)',

    ImageSafe:"Your image file is successfully safe :)",
    ImageDelete:"Your image file has been deleted.",
    Imagetitle: "Are you sure want to remove this image?",
    Imagetext: "You will not be able to recover this image!",
    ImageGrouptitle: "Are you sure want to remove this group?",
    ImageGrouptext: "You will not be able to recover this group!",
    ImageoneTime:"Only 5 photo's upload one time.",

    VideoUploadSuccess:'Video successfully uploaded.',
    VideoSafe:'Your video file is safe :)',
    VideoDeleted:'Your video file has been deleted.',
    Videotitle: 'Are you sure want to remove this video?',  
    Videotext: 'You will not be able to recover this video!',
    
    checkplanstatus:"If you wish to continue. Then, you need to upgrade your membership plan.",
    checkplanstatusTitle:'Upgrade Your Membership Plan'
     }

